import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { Pagination } from '@material-ui/lab'
import { Group, drawDOM, exportPDF } from '@progress/kendo-drawing'
import ShareDialog from 'components/Dialog/ShareDialog'
import saveAs from 'file-saver'
import {
  useGetCompanyQuery,
  useGetCostCentreQuery,
  useGetShareAttachmentMutation,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import './OR.scss'
import './PDFStyle.scss'
import GLBodyTemplate from './PDFTemplate/GLBodyTemplate'
import { useGLQueries } from './Query/useGLQueries'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const PDFPreviewGLV2 = (props: any) => {
  const location: any = useLocation()
  const journalDetail: any = location?.state
  const { accountType, docType } = props
  let history = useHistory()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const { styles } = useMobilePDFZoom()

  const {
    CompanyID,
    JournalProcessingID,
    RecurringJournalID,
    AuditAdjustmentID,
    JournalID,
  }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreError,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  let routeSegments
  let title
  let smallTitle
  let name
  let DocumentID
  let itemName
  smallTitle = smTitle.GENERAL_LEDGER
  name = 'DocNo'

  switch (docType) {
    case 'journal-processing':
      DocumentID = JournalProcessingID
      routeSegments = 'Journal Entries'
      title = 'JOURNAL VOUCHER'
      itemName = 'JournalProcessingItem'
      break
    case 'recurring-journal':
      DocumentID = RecurringJournalID
      routeSegments = 'Recurring Journal Entries'
      title = 'RECURRING JOURNAL'
      itemName = 'RecurringJournalItem'
      break
    case 'audit-adjustment':
      DocumentID = AuditAdjustmentID
      routeSegments = 'Audit Adjustment Entries'
      title = 'AUDIT ADJUSTMENT JOURNAL'
      itemName = 'AuditAdjustmentItem'
      break
  }

  const {
    loading,
    listData,
    primaryKey,
    errMessage: errMsg,
    errDialog: errDia,
    setErrDialog: setErrDia,
  } = useGLQueries({
    accountType,
    docType,
    CompanyID,
    TransactionID: JournalProcessingID,
  })

  const previewDetail: any = listData?.filter(
    v => v?.[primaryKey] === DocumentID
  )[0]

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  let fontStyle
  fontStyle = {
    fontSize: fontSize,
    textAlign: 'center',
  }

  // ========== Page definition ==========

  const [page, setCurrentPage] = useState<number>(0)

  // ========== Threshold definition ==========
  let totalItems = previewDetail?.[itemName]?.length

  let threshold
  if (totalItems > 15) {
    threshold = 15
  } else {
    threshold = 0
  }

  useEffect(() => {
    if (!!previewDetail) separateThreshold(previewDetail)
  }, [previewDetail])

  // ========== Remap Data for item list ==========
  const [remapData, setRemapData] = useState(null)

  const separateThreshold = input => {
    let temp = []
    let noChange = []
    let tempItem = []

    previewDetail?.[itemName]?.map((v, i) => {
      tempItem.push({ ...v, index: i + 1 })
      if ((i + 1) % threshold === 0) {
        temp.push({ ...input, [itemName]: tempItem })
        tempItem = []
      } else if (previewDetail?.[itemName]?.length === i + 1) {
        temp.push({ ...input, [itemName]: tempItem })
      }
    })
    noChange.push(temp)
    temp = []
    return setRemapData(noChange.flat())
  }

  /// ========== kendo-drawing PDF Download ===========
  const dataURItoFile = (dataurl, filename) => {
    dataurl.replace('data:application/pdf;base64,', '')
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const onPrint = () => {
    let element = document.getElementById('pdf-data-print')

    drawDOM(element, {
      paperSize: 'A4',
      margin: {
        bottom: width < 769 ? '0.5cm' : '1cm',
        top: width < 769 ? '0.5cm' : '1cm',
        left: width < 769 ? '0.25cm' : '0.5cm',
        right: width < 769 ? '0.25cm' : '0.5cm',
      },
      scale: width < 769 ? 1.25 : 0.85,
      forcePageBreak: '.page-break',
    })
      .then((group: Group) => exportPDF(group))
      .then(dataURI => {
        let fileObject = dataURItoFile(
          dataURI,
          `${title} (${previewDetail?.[name] ?? journalDetail?.JournalNo}).pdf`
        )
        saveAs(
          fileObject,
          `${title} (${previewDetail?.[name] ?? journalDetail?.JournalNo}).pdf`
        )
      })
  }

  const pdfToPrint = () => {
    return (
      <>
        {/* ========================================= */}
        {/* DOCS THAT IS SAVED AS PDF WHICH IS HIDDEN */}
        {/* ========================================= */}

        <div style={{ position: 'fixed', left: `-1000px` }}>
          <div id={'pdf-data-print'}>
            {remapData &&
              remapData?.map((v, index) => {
                return (
                  <GLBodyTemplate
                    title={title}
                    previewDetail={previewDetail}
                    journalDetail={journalDetail}
                    docType={docType}
                    getCostCentre={getCostCentre}
                    threshold={threshold}
                    getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                    fontSize={fontSize}
                    fontStyle={fontStyle}
                    listData={listData}
                    remapData={remapData}
                    page={index}
                    pageLength={remapData?.length}
                    width={width}
                    currencyCode={getCompany[0]?.Currency?.code}
                  />
                )
              })}
          </div>
        </div>
      </>
    )
  }

  const [labelState, setLabelState] = useState<string>()

  useEffect(() => {
    setLabelState(getCompany[0]?.Name)
  }, [getCompany])

  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openSendEmail, setOpenSendEmail] = useState(false)

  const [
    getShareAttachments,
    {
      data: { getShareAttachment } = { getShareAttachment: {} as any },
      called: getShareAttachmentCalled,
      loading: ShareAttachmentLoading,
    },
  ] = useGetShareAttachmentMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setOpenShareDialog(true)
    },
  })

  const onSubmit = blob => {
    getShareAttachments({
      variables: {
        file: blob,
        CompanyID: CompanyID,
      },
    })
  }

  return (
    <>
      {loading && <Loading />}
      {UserLoading && <Loading />}
      {companyLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {ShareAttachmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (!!JournalID) {
            history.push({
              pathname: `/${accountType}/${CompanyID}/${docType}/${DocumentID}/${JournalID}/journal-Detail`,
              state: previewDetail,
            })
          } else if (previewDetail?.ApprovalStatus === 'COMPLETED') {
            history.push({
              pathname: `/${accountType}/${CompanyID}/${docType}/${DocumentID}/detail`,
              state: previewDetail,
            })
          } else {
            history.push({
              pathname: `/${accountType}/${CompanyID}/${docType}`,
              state: previewDetail,
            })
          }
        }}
        smTitle={smallTitle}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[{ name: 'Print', current: true }]}
      />

      <ContentWrapper footer>
        {/* ============================== */}
        {/* DISPLAYED IN HTML TO BE VIEWED */}
        {/* ============================== */}

        <div style={{ height: window.screen.height }}>
          <div style={styles.supercontainer}>
            <div style={styles.container}>
              <GLBodyTemplate
                title={title}
                previewDetail={previewDetail}
                journalDetail={journalDetail}
                docType={docType}
                getCostCentre={getCostCentre}
                threshold={threshold}
                getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
                fontSize={fontSize}
                fontStyle={fontStyle}
                listData={listData}
                remapData={remapData}
                page={page}
                pageLength={remapData?.length}
                width={width}
                currencyCode={getCompany[0]?.Currency?.code}
              />
            </div>
          </div>
        </div>

        <div
          className={
            width < 769 ? 'pagination-dis-mobile' : 'pagination-dis-desktop'
          }
        >
          <Pagination
            count={remapData?.length}
            shape="rounded"
            page={page + 1}
            onChange={(e, page) => {
              setCurrentPage(page - 1)
            }}
          />
        </div>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
      <ErrorDialog
        errorDia={errDia}
        setErrorDia={setErrDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
      <div style={{ width: 1980 }}>{remapData && pdfToPrint()}</div>

      {ShareDialog({
        shareTitle: `General Ledger - ${labelState}`,
        title: `Share`,
        URL: getShareAttachment?.fileURL,
        setSimple: setOpenShareDialog,
        simple: openShareDialog,
        emailShare: openSendEmail,
        setSimpleEmail: setOpenSendEmail,
      })}
      <Footer
        options={[
          // {
          //   name: 'Share',
          //   onClick: async () => {
          //     let temp = await document.getElementById('pdf-data-print')

          //     htmlToImage
          //       .toBlob(temp, { style: { background: '#fff' } })
          //       .then(value => {
          //         onSubmit(value)
          //       })
          //       .catch(error => console.error(error, 'Something went wrong'))
          //   },
          //   // disabled:
          //   //   graphData.every(emptyData) == undefined ||
          //   //   graphData.every(emptyData) ||
          //   //   handlePermDisabled({
          //   //     permEnum: ContPermission.ProjectCostingProjectCostingShare,
          //   //   }),
          //   color: 'primary',
          //   props: {
          //     type: 'submit',
          //   },

          //   startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
          // },
          {
            name: 'Download',
            color: 'primary',
            onClick: () => {
              onPrint()
            },
          },
        ]}
      />
    </>
  )
}

import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import AppContext from 'containers/App/Store/AppContext'
import { AcctPermission, useGetCompanyNameQuery } from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const GLDigitalReportSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { handlePermHidden } = usePermissionChecker()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const submenuList = [
    {
      path: `/general-ledger/${CompanyID}/digital-report/gl-journal-entity-report/parameters`,
      title: `GL Journal By Account`,
      permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/general-ledger/${CompanyID}/digital-report/gl-journal-costcentre-report/parameters`,
      title: `GL Journal by Department`,
      permission:
        AcctPermission.GeneralLedgerDigitalReportsGlJournalCostCentreView,
    },
    {
      path: `/general-ledger/${CompanyID}/digital-report/trial-balance-entity-report/parameters`,
      title: `Trial Balance by Entity`,
      permission: AcctPermission.GeneralLedgerDigitalReportsTrialBalanceView,
    },
    {
      path: `/general-ledger/${CompanyID}/digital-report/trial-balance-department-report/parameters`,
      title: `Trial Balance by Department`,
      permission:
        AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
    },
    {
      path: `/general-ledger/${CompanyID}/digital-report/mastercoa-listing-report/parameters`,
      title: `MasterCOA Listing`,
      // permission:
      //   AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
    },
    // {
    //   path: `/general-ledger/${CompanyID}/digital-report/cash-ledger-listing-report`,
    //   title: `Cash Ledger Listing`,
    // },
  ]

  // let userAccDetail = {
  //   ...user,
  //   companyID: getCompany[0]?.CompanyID,
  //   companyName: getCompany[0]?.Name,
  // }

  // // USER INFO
  // localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail))

  return (
    <>
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/general-ledger/${CompanyID}`)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              handlePermHidden({
                // companyID: CompanyID,
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={<span className="xsTitle">{el?.title}</span>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
      {/* FOOTER */}
    </>
  )
}

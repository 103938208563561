import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { List, ListItem, ListItemText } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  AcctPermission,
  useGetCreditorAccountQuery,
  useGetCreditorTypeLazyQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const CreditorProfileDetail = (props: any) => {
  let history = useHistory()
  const { creditorType, CreditorAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const eInvoiceSubscriber = JSON.parse(
    localStorage.getItem('eInvoiceSubscriber')
  )

  let creditorName = type => {
    let temp
    switch (type) {
      case 'supplier':
        temp = 'Supplier'
        break
      case 'contractor':
        temp = 'Contractor'
        break
      case 'consultant':
        temp = 'Consultant'
        break
      case 'agent':
        temp = 'Agent'
        break
    }
    return temp
  }

  const {
    loading: CreditorAccountLoading,
    error: CreditorAccountError,
    data: { getCreditorAccount } = { getCreditorAccount: [] },
  } = useGetCreditorAccountQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    variables: { CreditorAccountID: CreditorAccountID },
    onCompleted: ({ getCreditorAccount }) => {
      if (getCreditorAccount?.length > 0) {
        fetchCreditorType({
          variables: {
            CreditorTypeID: getCreditorAccount[0]?.CreditorTypeID,
          },
        })
      }
    },
  })

  const [
    fetchCreditorType,
    {
      loading: CreditorTypeLoading,
      error: CreditorTypeError,
      data: { getCreditorType } = { getCreditorType: [] },
    },
  ] = useGetCreditorTypeLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  return (
    <>
      {CreditorTypeLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/account-payable/general-setting/creditor-profile/${creditorType}`
          )
        }
        smTitle={'Accounts Payable'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: 'Main Menu' },
          { name: 'Creditor Profile', current: true },
        ]}
      />
      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: `${creditorName(creditorType)} Details`,
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum:
                        AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum:
                  AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
              }),
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc c-grey">Company Registration No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CompanyRegNo}</div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: '#afafaf' }}>
              Tax Identification No.
            </div>
            <div className="" style={{ fontSize: '12px', fontWeight: 500 }}>
              {getCreditorAccount[0]?.TaxIdentificationNo}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Company Name</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CompanyName}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Creditor Type</div>
            <div className="mdDesc">
              {`${creditorName(creditorType)} - ${
                getCreditorAccount[0]?.CreditorType?.Description
              }`}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Phone No</div>
            <div className="mdDesc">+{getCreditorAccount[0]?.ContactNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Email Address</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Email}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Credit Terms</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CreditTerm}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Region</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Region}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">GST Registration No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.GSTRegNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Remark</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Remark}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Bank Account No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.BankAccountNo}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">Bank Name</div>
            <div className="mdDesc">{getCreditorAccount[0]?.BankName}</div>
          </div>
          <div className="content-wrap left">
            <div className="desc c-grey">SWIFT Code</div>
            <div className="mdDesc">{getCreditorAccount[0]?.SWIFTCode}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">PayeeName</div>
            <div className="mdDesc">{getCreditorAccount[0]?.PayeeName}</div>
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Address Info',
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum:
                        AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum:
                  AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
              }),
            },
          }}
        >
          <div className="content-wrap full">
            <div className="desc c-grey">Address</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.Address?.address},{' '}
              {getCreditorAccount[0]?.Address?.postcode}{' '}
              {getCreditorAccount[0]?.Address?.city},{' '}
              {getCreditorAccount[0]?.Address?.state},{' '}
              {getCreditorAccount[0]?.Address?.country}
            </div>
          </div>
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Contact Details',
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: <CreateIcon />,
            },
          }}
        >
          {getCreditorAccount[0]?.CreditorContact?.length === 0 ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyList
                title="No Contact Record(s)"
                subtitle="Click on edit button to add records"
              />
            </div>
          ) : (
            getCreditorAccount[0]?.CreditorContact?.map((el, index) => {
              return (
                <List style={{ width: '100%', paddingBottom: '0px' }}>
                  <ListItem
                    key={index}
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={
                        <span className="desc xsTitle">
                          {el.Name} ({el.Designation})
                        </span>
                      }
                      secondary={
                        <span className="desc">
                          <span>
                            <PhoneIcon
                              style={{
                                fontSize: '10px',
                                color: 'black',
                                marginBottom: '-1px',
                                marginRight: '3px',
                              }}
                            />

                            <span
                              className={` primary-click-text`}
                              onClick={e => {
                                window.open(`tel:${el.ContactNo}`)
                              }}
                            >
                              +{el.ContactNo}
                            </span>
                          </span>
                          {` | `}
                          <span>
                            <EmailIcon
                              style={{
                                fontSize: '12px',
                                color: 'black',
                                marginBottom: '-3px',
                                marginRight: '3px',
                              }}
                            />
                            <span
                              className={` primary-click-text`}
                              onClick={e => {
                                window.open(`mailto:${el.Email}`)
                              }}
                            >
                              {el.Email}
                            </span>
                          </span>
                        </span>
                      }
                    />
                  </ListItem>
                </List>
              )
            })
          )}
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Director Details',
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: <CreateIcon />,
            },
          }}
        >
          {getCreditorAccount[0]?.CreditorDirector?.length === 0 ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyList
                title="No Director Record(s)"
                subtitle="Click on edit button to add records"
              />
            </div>
          ) : (
            getCreditorAccount[0]?.CreditorDirector?.map((el, index) => {
              return (
                <List style={{ width: '100%', paddingBottom: '0px' }}>
                  <ListItem
                    key={index}
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span className="desc">
                            <span className="xsTitle">{el.Name}</span>
                          </span>
                        </>
                      }
                      secondary={
                        <span className="desc">
                          <span className="desc">
                            <span>
                              <PhoneIcon
                                style={{
                                  fontSize: '10px',
                                  color: 'black',
                                  marginBottom: '-1px',
                                  marginRight: '3px',
                                }}
                              />

                              <span
                                className={` primary-click-text`}
                                onClick={e => {
                                  window.open(`tel:${el.ContactNo}`)
                                }}
                              >
                                +{el.ContactNo}
                              </span>
                            </span>
                            {` | `}
                            <span>
                              <EmailIcon
                                style={{
                                  fontSize: '12px',
                                  color: 'black',
                                  marginBottom: '-3px',
                                  marginRight: '3px',
                                }}
                              />
                              <span
                                className={` primary-click-text`}
                                onClick={e => {
                                  window.open(`mailto:${el.Email}`)
                                }}
                              >
                                {el.Email}
                              </span>
                            </span>
                          </span>
                        </span>
                      }
                    />
                  </ListItem>
                </List>
              )
            })
          )}
        </CardContents>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

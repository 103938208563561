import AppContext from '@account-root/account-react/src/containers/App/Store/AppContext';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import React, { useContext } from 'react';
import { amtStr4Dec } from '../../../helpers/StringNumberFunction/numFormatter';

export const TableDOItem = (props: any) => {
  const { data, docType, allData, page, pageLength, companyCountry } = props;

  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) {
    fontSize = 6;
  } else {
    fontSize = 9;
  }

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingTop: '4px',
    paddingBottom: '4px',
  };

  const thdocnum = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'left' as 'left',
    paddingLeft: '8px',
    backgroundColor: '#e7e6e6',
  };

  const thleftnarrow = {
    width: '5%',
    borderBottom: '0.5px solid',
    textAlign: 'left' as 'left',
    backgroundColor: '#e7e6e6',
  };

  const thcenternarrow = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'right' as 'right',
    paddingRight: '8px',
    backgroundColor: '#e7e6e6',
  };

  const tdcenter = {
    width: '10%',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
    paddingRight: '8px',
  };

  const tdleftstretch = {
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
    paddingLeft: '8px',
  };

  //===================== Reducer for DO / GRN =====================

  const orderedQty = allData?.DOItem?.reduce(
    (total, amountValue) => (total = total + amountValue?.POItem?.OrderedQty),
    0,
  );

  const acceptedQty = allData?.DOItem?.reduce(
    (total, amountValue) => (total = total + amountValue?.AcceptedQty),
    0,
  );

  const acceptedAmt = allData?.DOItem?.reduce(
    (total, amountValue) => (total = total + amountValue?.AcceptedAmt),
    0,
  );

  //================== Reducer for GRTN =====================

  const receivedQty = allData?.GRTNHeaderItem?.reduce(
    (total, amountValue) => (total = total + amountValue?.DOItem?.AcceptedQty),
    0,
  );

  const returnedQty = allData?.GRTNHeaderItem?.reduce(
    (total, amountValue) => (total = total + amountValue?.ReturnedQty),
    0,
  );

  const returnedAmt = allData?.GRTNHeaderItem?.reduce(
    (total, amountValue) => (total = total + amountValue?.ReturnedAmt),
    0,
  );

  return (
    <>
      {/* ====== GRN Table ======== */}
      {docType === 'delivery-order' && (
        <div style={{ marginTop: '5px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thdocnum}>PO No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thleft}>Warehouse</th>
              <th style={thleftnarrow}>UOM</th>
              <th style={thcenternarrow}>
                Order
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Received
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Received
                <br />
                Amt
              </th>
            </tr>

            {data?.DOItem?.map((v, i) => (
              <>
                <tr>
                  <td
                    style={{
                      textAlign: 'left',
                      paddingLeft: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {v?.POItem?.POHeader?.DocNo ??
                      v?.ReplacedGRTNItem?.DOItem?.POItem?.POHeader?.DocNo}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.POItem?.Item?.Name ??
                      v?.ReplacedGRTNItem?.DOItem?.POItem?.Item?.Name}
                    <br />
                    {v?.POItem?.Remarks ??
                      v?.ReplacedGRTNItem?.DOItem?.POItem?.Remarks}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {!!v?.POItem?.Item?.IsInventory
                      ? v?.POItem?.Warehouse?.Name
                      : !!v?.ReplacedGRTNItem?.DOItem?.POItem?.Item?.IsInventory
                      ? v?.ReplacedGRTNItem?.DOItem?.POItem?.Warehouse?.Name
                      : '-'}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.POItem?.UOM?.Code ??
                      v?.ReplacedGRTNItem?.DOItem?.POItem?.UOM?.Code}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.POItem?.OrderedQty)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.AcceptedQty)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr(v?.AcceptedAmt)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          {page === pageLength - 1 && (
            <div style={{ marginTop: '50px' }}>
              <table
                style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
              >
                <tr>
                  <td style={tdleftstretch}>{`Total`}</td>

                  <td style={tdcenter}>{amtStr4Dec(orderedQty)}</td>
                  <td style={tdcenter}>{amtStr4Dec(acceptedQty)}</td>
                  <td style={tdcenter}>{amtStr(acceptedAmt)}</td>
                </tr>
              </table>
            </div>
          )}
        </div>
      )}

      {/* ======== GRTN Table =========== */}
      {docType === 'good-return-note' && (
        <div style={{ marginTop: '20px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thdocnum}>GRN No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thleft}>Warehouse</th>
              <th style={thleftnarrow}>UOM</th>
              <th style={thcenternarrow}>
                Reeeived
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Returned
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Returned
                <br />
                Amt
              </th>
            </tr>

            {data?.GRTNHeaderItem?.map((v, i) => (
              <>
                <tr>
                  <td
                    style={{
                      textAlign: 'left',
                      paddingLeft: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {v?.DOItem?.DOHeader?.DocNo}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.DOItem?.POItem?.Item?.Name ??
                      v?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem?.Item?.Name}
                    <br />
                    {v?.DOItem?.POItem?.POHeader?.DocNo ??
                      v?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem?.POHeader
                        ?.DocNo}{' '}
                    |{' '}
                    {v?.DOItem?.POItem?.Remarks ??
                      v?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem?.Remarks}
                    <br />
                    {!!v?.Replacement ? 'Replacement' : null}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {!!v?.DOItem?.POItem?.Item?.IsInventory
                      ? v?.DOItem?.POItem?.Warehouse?.Name
                      : !!v?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem?.Item
                          ?.IsInventory
                      ? v?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem?.Warehouse
                          ?.Name
                      : '-'}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                    {v?.DOItem?.POItem?.Item?.UOM?.Code ??
                      v?.DOItem?.ReplacedGRTNItem?.DOItem?.POItem?.Item?.UOM
                        ?.Code}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.DOItem?.AcceptedQty)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr4Dec(v?.ReturnedQty)}
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      paddingRight: '8px',
                      verticalAlign: 'top',
                    }}
                  >
                    {amtStr(v?.ReturnedAmt)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          {page === pageLength - 1 && (
            <div style={{ marginTop: '50px' }}>
              <table
                style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
              >
                <tr>
                  <td style={tdleftstretch}>{`Total`}</td>

                  <td style={tdcenter}>{amtStr4Dec(receivedQty)}</td>
                  <td style={tdcenter}>{amtStr4Dec(returnedQty)}</td>
                  <td style={tdcenter}>{amtStr(returnedAmt)}</td>
                </tr>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TableDOItem;

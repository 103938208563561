import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Avatar,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import BigNumber from 'bignumber.js'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  DocumentListingDocument,
  GetApBatchPaymentbyStatusDocument,
  useDocumentListingQuery,
  useGetApBatchPaymentbyStatusQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePayApBatchPaymentMutation,
  useRejectBatchPaymentMutation,
} from 'generated/graphql'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'

interface APBatchPaymentProps {
  CompanyID: string
  CreditorAccountID: string
  BatchPaymentID: string
  DocDate: Date
  DocAmt: number
  RefNo: string
  Description: string
  Attachment: null
  debitAmt: number
  approval: string
  user: string
  BankAccountID: string
  BankName: string
  PaymentMethodID: string
  CreditCardID: string
  ChequeNo: string
  ChequeDate: Date
  uploadAttach: string
  Allocation: any[]
}

export const APBatchPaymentPostingList = (props: any) => {
  let history = useHistory()
  const { BatchPaymentID, CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [detailsID, setDetailsID] = useState<Set<string>>(new Set(''))
  const [suppIDs, setSuppIDs] = useState<Set<string>>(new Set(''))
  const [paidData, setPaidData] = useState([])
  const [totalCreditor, setTotalCreditor] = useState([])
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [openExitConf, setOpenExitConf] = useState(null)

  const { handleSubmit, control } = useForm<APBatchPaymentProps>({
    mode: 'onSubmit',
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */

  const {
    loading: userLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: BatchPaymentID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const {
    loading: paymentbyStatusLoading,
    error: paymentbyStatusError,
    data: { getAPBatchPaymentbyStatus } = {
      getAPBatchPaymentbyStatus: [],
    },
  } = useGetApBatchPaymentbyStatusQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      StatusArr: 'SUBMIT',
      BatchPaymentID: BatchPaymentID,
    },
  })

  let array1: any = {}
  getAPBatchPaymentbyStatus[0]?.APPayment?.map(alloc => {
    array1[alloc?.CreditorAccount?.CompanyName] = [alloc]
  })

  /////**********KEEP TOTAL CREDITOR AVAILABLE**********/
  useEffect(() => {
    let creditorDatas = totalCreditor

    if (getAPBatchPaymentbyStatus[0]?.APPayment?.length > 0) {
      Object?.keys(array1)?.map(supplier => {
        array1[supplier]?.map(alloc => {
          alloc?.Allocation?.map(creditor => {
            creditorDatas?.push(creditor?.CreditID)
          })
        })
      })
    }
    setTotalCreditor(creditorDatas)
  }, [getAPBatchPaymentbyStatus[0]?.APPayment])

  /* -------------------------------------------- */
  /*                   CHECKBOX                   */
  /* -------------------------------------------- */

  // handle checkbox for all payments
  const handleSelectAll = (e, Array1) => {
    let suppTemp = suppIDs
    let detailTemp = detailsID
    let inSideDataTemp = paidData

    if (e.target.checked) {
      Object.keys(Array1).map((keyAssoc, index) => {
        suppTemp.add(keyAssoc)
        Array1[keyAssoc]?.map(suppData => {
          const creditorAccID: any = suppData?.CreditorAccountID
          suppData?.Allocation?.map((detailData, index) => {
            detailTemp.add(detailData?.CreditID)
            inSideDataTemp.push({ creditorAccID, detailData })
          })
        })
      })
    } else {
      Object.keys(Array1).map((keyAssoc, index) => {
        suppTemp.delete(keyAssoc)
        Array1[keyAssoc]?.map(suppData => {
          const creditorAccID: any = suppData?.CreditorAccountID
          suppData?.Allocation?.map((detailData, index) => {
            detailTemp.delete(detailData?.CreditID)
          })
        })
      })

      inSideDataTemp?.splice(0, inSideDataTemp?.length)

      setPaidData(inSideDataTemp)
    }
    setSuppIDs(new Set(Array.from(suppTemp)))
    setDetailsID(new Set(Array.from(detailTemp)))
  }

  // handle checkbox for all payments under Supplier
  const handleSelectSuppAlloc = (e, keyAssoc, creditorAccID, detailData) => {
    let suppTemp = suppIDs
    let detailTemp = detailsID
    let inSideDataTemp = paidData

    if (e.target.checked) {
      suppTemp.add(keyAssoc)
      array1[keyAssoc]?.[0]?.Allocation?.map((detailData, index) => {
        detailTemp?.add(detailData?.CreditID)
        inSideDataTemp.push({ creditorAccID, detailData })
      })
    } else {
      suppTemp.delete(keyAssoc)
      array1[keyAssoc]?.[0]?.Allocation?.map(k => {
        detailTemp?.delete(k?.CreditID)
      })
      let removedData = inSideDataTemp?.filter(
        x => x?.creditorAccID !== creditorAccID
      )

      setPaidData(removedData)
    }

    setSuppIDs(new Set(Array.from(suppTemp)))
    setDetailsID(new Set(Array.from(detailTemp)))
  }

  // handle checkbox for specific payment
  const handleCheckBoxChange = (
    e: any,
    paymentID,
    keyAssoc,
    creditorAccID,
    detailData,
    index: number
  ) => {
    let suppTemp = suppIDs
    let paymentTemp = detailsID
    let inSideDataTemp = paidData
    let obj1 = {}
    if (e.target.checked) {
      suppTemp.add(keyAssoc)
      paymentTemp.add(paymentID)
      inSideDataTemp.push({ creditorAccID, detailData })
      if (!paymentTemp.has(paymentID)) {
        suppTemp.add(keyAssoc)
        paymentTemp.add(paymentID)
        inSideDataTemp.push({ creditorAccID, detailData })
      }
    } else {
      paymentTemp.delete(paymentID)
      let removedData = inSideDataTemp?.filter(
        x => x?.detailData?.CreditID !== detailData?.CreditID
      )
      setPaidData(removedData)

      if (
        !array1[keyAssoc]?.[0]?.Allocation?.some(v => {
          return paymentTemp.has(v?.CreditID)
        })
      ) {
        suppTemp.delete(keyAssoc)
      }
    }

    setDetailsID(new Set(Array.from(paymentTemp)))
  }

  const [
    payAPBatchPayment,
    {
      loading: payAPBatchPaymentLoading,
      called: payAPBatchPaymentCalled,
      error: payAPBatchPaymentError,
    },
  ] = usePayApBatchPaymentMutation({
    onError: error => {
      console.log('ERROR', error)
      let errorMessage = error.message.substring(15)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ payAPBatchPayment }) => {
      if (payAPBatchPayment === true) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecord())
        setTimeout(() => {
          history.push({
            pathname: `/account-payable/${CompanyID}/batch-payment`,
            state: { success: true, msgMode: 'create' },
          })
        }, 500)
      } else {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecordFail())
      }
    },
  })

  const [
    rejectBatchPayment,
    {
      loading: rejectBatchPaymentLoading,
      called: rejectBatchPaymentCalled,
      error: rejectBatchpaymentError,
    },
  ] = useRejectBatchPaymentMutation({
    onError: error => {
      console.log('ERROR', error)
      let errorMessage = error.message.substring(15)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: ({ rejectBatchPayment }) => {
      if (rejectBatchPayment === true) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.rejectNewRecord())
        setTimeout(() => {
          history.push({
            pathname: `/account-payable/${CompanyID}/batch-payment`,
            state: { success: true, msgMode: 'reject' },
          })
        }, 500)
      } else {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecordFail())
      }
    },
  })

  /* -------------------------------------------- */
  /*                DATA SUBMISSION               */
  /* -------------------------------------------- */

  const onSubmit = data => {
    payAPBatchPayment({
      variables: {
        BatchPaymentID: BatchPaymentID,
        CompanyID: CompanyID,
        RefTable: 'AP_BatchPayment',
        allocationInput: paidData?.map(alloc => {
          return {
            AllocationID: alloc?.detailData?.AllocationID,
            CompanyID: CompanyID,
            DebitRefTable: alloc?.detailData?.DebitRefTable,
            DebitID: alloc?.detailData?.DebitID,
            CreditRefTable: alloc?.detailData?.CreditRefTable,
            CreditID: alloc?.detailData?.CreditID,
            CreditOrDebit: alloc?.detailData?.CreditOrDebit,
            // for allocated table
            AllocationAmt: Number(alloc?.detailData?.AllocationAmt),
            CreditorAccountID: alloc?.creditorAccID,
          }
        }),
      },
      refetchQueries: [
        {
          query: GetApBatchPaymentbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: ['COMPLETED'],
          },
        },
        {
          query: DocumentListingDocument,
          variables: {
            refTable: 'S_Attachment',
            refID: editData?.BatchPaymentID,
          },
        },
      ],
    })

    /* -------------------------------------------- */
    /*                   EXIT CONF                  */
    /* -------------------------------------------- */
  }

  const rejectSubmit = () => {
    rejectBatchPayment({
      variables: {
        BatchPaymentID: BatchPaymentID,
      },
      refetchQueries: [
        {
          query: GetApBatchPaymentbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: ['COMPLETED'],
          },
        },
        {
          query: DocumentListingDocument,
          variables: {
            refTable: 'S_Attachment',
            refID: editData?.BatchPaymentID,
          },
        },
      ],
    })
  }

  // /* ------------------------------------------- */
  // /*                  TOTAL AMT                  */
  // /* ------------------------------------------- */
  const getTotalAmt = () => {
    let totalAmt = 0
    if (paidData?.length > 0) {
      paidData?.map(x => {
        totalAmt = new BigNumber(totalAmt)
          .plus(x?.detailData?.AllocationAmt)
          .toNumber()
      })

      return totalAmt
    } else {
      return 0
    }
  }

  const hasChanges = () => {
    if (suppIDs?.size === 0) return false
    else return true
  }

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  return (
    <>
      {docLoading && <Loading />}
      {userLoading && <Loading />}
      {paymentbyStatusLoading && <Loading />}
      {payAPBatchPaymentLoading && <Loading />}
      {rejectBatchPaymentLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(`/account-payable/${CompanyID}/batch-payment`)
        }}
        smTitle={'Accounts Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'AP' },
          { name: 'AP' },
          { name: 'Batch Payment', current: true },
        ]}
        rightRouteSegments={[{ name: 'Submitted', current: true }]}
      />

      <DynamicSubHeader
        title={getAPBatchPaymentbyStatus[0]?.RefNo}
        rightText={formatDate(getAPBatchPaymentbyStatus[0]?.DocDate)}
        infoLineStyle={{ width: '100px' }}
        infoLine={
          <>
            <div style={{ display: 'flex' }}>
              <Tooltip
                disableFocusListener
                title={
                  <Fragment>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          <PersonIcon style={{ fontSize: 'small' }} />
                        </Avatar>
                      </Grid>

                      <Grid item xs zeroMinWidth>
                        <div style={{ fontSize: '12px' }}>
                          {
                            getUsersByAccountAndSoftware?.find(
                              x =>
                                x?.ID ===
                                getAPBatchPaymentbyStatus[0]?.submittedBy
                            )?.name
                          }
                        </div>
                        <div style={{ fontSize: '10px' }}>
                          {formatDate(getAPBatchPaymentbyStatus[0]?.modTs) ??
                            formatDate(
                              getAPBatchPaymentbyStatus[0]?.submittedTs
                            )}
                          &nbsp;
                          {formatTime(getAPBatchPaymentbyStatus[0]?.modTs) ??
                            formatTime(
                              getAPBatchPaymentbyStatus[0]?.submittedTs
                            )}
                        </div>
                      </Grid>
                    </Grid>
                  </Fragment>
                }
                arrow
                enterTouchDelay={0}
              >
                <div>
                  <IconText
                    icon={
                      <img
                        src={SubmitterLog}
                        style={{
                          width: '11.5px',
                          marginRight: '5px',
                        }}
                      />
                    }
                    font="desc"
                  ></IconText>
                </div>
              </Tooltip>
              <div className="desc">
                {getAPBatchPaymentbyStatus[0]?.Description}
              </div>
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc">
            {amtStr(getAPBatchPaymentbyStatus[0]?.DocAmt)}
          </span>
        }
      />

      <ContentWrapper float footer multiDynamicInfo>
        <CardContents>
          <div className="content-wrap left ">
            <div
              className="desc"
              style={{
                fontWeight: 300,
              }}
            >
              Document Date
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(getAPBatchPaymentbyStatus[0]?.DocDate)}
            </div>
          </div>

          <div className="content-wrap right ">
            <div
              className="desc"
              style={{
                fontWeight: 300,
              }}
            >
              Reference No.
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {getAPBatchPaymentbyStatus[0]?.RefNo}
            </div>
          </div>

          <div className="content-wrap full ">
            <div
              className="desc"
              style={{
                fontWeight: 300,
              }}
            >
              Bank Acc No
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {`${getAPBatchPaymentbyStatus[0]?.BankAccount?.BankProfile?.Name} - (${getAPBatchPaymentbyStatus[0]?.BankAccount?.AccountNo})`}{' '}
            </div>
          </div>

          <div className="content-wrap full">
            <div
              className="desc"
              style={{
                fontWeight: 300,
              }}
            >
              Description
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {getAPBatchPaymentbyStatus[0]?.Description}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Payment Method
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {getAPBatchPaymentbyStatus[0]?.PaymentMethod?.Name}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Amount
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {amtStr(getAPBatchPaymentbyStatus[0]?.DocAmt)}
            </div>
          </div>

          {getAPBatchPaymentbyStatus[0]?.PaymentMethod?.Name?.includes(
            'Card'
          ) && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Card Type
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {getAPBatchPaymentbyStatus[0]?.CreditCardType?.Name}
              </div>
            </div>
          )}

          {getAPBatchPaymentbyStatus[0]?.PaymentMethod?.Name === 'Cheque' && (
            <>
              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Cheque No.
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {getAPBatchPaymentbyStatus[0]?.ChequeNo}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Cheque Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(getAPBatchPaymentbyStatus[0]?.ChequeDate)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Cheque Expiry Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(getAPBatchPaymentbyStatus[0]?.ChequeExpiryDate)}
                </div>
              </div>
            </>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Attachment
            </div>
            {attachmentDetailView(DocumentListing)}
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Transaction Date
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(getAPBatchPaymentbyStatus[0]?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getAPBatchPaymentbyStatus[0]?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                getUsersByAccountAndSoftware?.filter(
                  user => user.ID == getAPBatchPaymentbyStatus[0]?.submittedBy
                )[0]?.name
              }
            </div>
          </div>
        </CardContents>
        {array1?.length !== 0 || array1 !== undefined ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={e => handleSelectAll(e, array1)}
              color="primary"
              value={''}
              checked={
                Array.from(suppIDs)?.length === Object?.keys(array1)?.length &&
                Array.from(detailsID)?.length === totalCreditor?.length
              }
              style={{ marginLeft: '3px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum: AcctPermission.AccPayableBatchPaymentPaid,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}
        {array1?.length === 0 || array1 === undefined ? (
          <EmptyList title="No Record found" subtitle="Add a new record now." />
        ) : (
          Object.keys(array1).map((keyAssoc, index) => {
            return (
              <CardContents
                IsCheckboxHeader={true}
                section={{
                  header: {
                    title: (
                      <span className="xsTitle ">
                        <Checkbox
                          onChange={e =>
                            handleSelectSuppAlloc(
                              e,
                              keyAssoc,
                              array1[keyAssoc]?.[0]?.CreditorAccountID,
                              array1[keyAssoc]?.[0]?.Allocation
                            )
                          }
                          color="primary"
                          value={''}
                          checked={suppIDs.has(keyAssoc)}
                          style={{
                            marginTop: '-5px',
                          }}
                          disabled={handlePermDisabled({
                            companyID: CompanyID,
                            permEnum: AcctPermission.AccPayableBatchPaymentPaid,
                          })}
                        />
                        <span>{keyAssoc}</span>
                      </span>
                    ),

                    rightTitleWithCheckBox: amtStr(
                      array1[keyAssoc]?.[0]?.Allocation?.reduce(
                        (total, item) => total + (item?.AllocationAmt ?? 0),
                        0
                      )
                    ),
                    customFontSizeClass: 'xsTitle',
                  },
                }}
              >
                <div className="rm-padding table-wrap">
                  {array1[keyAssoc]?.[0]?.Allocation?.map(
                    (detail, detIndex) => {
                      return (
                        <>
                          <Grid spacing={1} container className="table-content">
                            <Grid
                              item
                              xs={1}
                              style={{
                                placeSelf: 'start',
                              }}
                            >
                              <span
                                className="Title"
                                style={{
                                  fontWeight: 500,
                                  fontSize: 'small',
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    width: '16px',
                                    paddingLeft: '8px',
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={detail?.CreditID}
                                        onChange={e => {
                                          handleCheckBoxChange(
                                            e,
                                            detail?.CreditID,
                                            keyAssoc,
                                            array1[keyAssoc]?.[0]
                                              .CreditorAccountID,
                                            detail,
                                            index
                                          )
                                        }}
                                        checked={detailsID?.has(
                                          detail?.CreditID
                                        )}
                                        style={{ marginLeft: '1px' }}
                                        color="primary"
                                        disabled={handlePermDisabled({
                                          companyID: CompanyID,
                                          permEnum:
                                            AcctPermission.AccPayableBatchPaymentPaid,
                                        })}
                                      />
                                    }
                                    label=""
                                    name="check"
                                  />
                                </FormControl>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={3}
                              style={{
                                placeSelf: 'start',
                                marginTop: '8px',
                                paddingLeft: '8px',
                              }}
                            >
                              <div className="p-l-5 desc date-width">
                                {formatDate(detail?.CreditDocDate)}
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              style={{
                                placeSelf: 'start',
                                marginTop: '8px',
                              }}
                            >
                              <div className="mdLabel flex-space">
                                {detail?.CreditDocNo}
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              style={{
                                placeSelf: 'start',
                                marginTop: '8px',
                                marginRight: 'auto',
                                textAlign: 'right',
                                flexWrap: 'wrap',
                                display: 'flex',
                                justifyContent: isDesktop ? 'end' : 'end',
                              }}
                            >
                              <span
                                className="c-black xxTitle"
                                style={{ marginRight: '-7px' }}
                              >
                                {amtStr(detail?.AllocationAmt)}
                              </span>
                            </Grid>
                          </Grid>

                          <Grid
                            spacing={1}
                            container
                            className="table-content"
                            style={{
                              paddingTop: '0px',
                              paddingBottom: '0px',
                              marginBottom: '-25px',
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              style={{
                                placeSelf: 'start',
                              }}
                            ></Grid>

                            <Grid
                              item
                              xs={11}
                              style={{
                                placeSelf: 'start',
                                marginTop: '-40px',
                                paddingLeft: '8px',
                              }}
                            >
                              <div>
                                <div className="p-l-5 desc text-noflow">
                                  {detail?.Description}
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          <Divider
                            variant="fullWidth"
                            style={{ background: '#E4E4E4' }}
                          ></Divider>
                        </>
                      )
                    }
                  )}
                </div>
              </CardContents>
            )
          })
        )}
      </ContentWrapper>

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-payable/${CompanyID}/batch-payment`)
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <TotalAmountFooter
        docAmt={getTotalAmt() ?? 0}
        recordSelected={true}
        countRecord={Array.from(detailsID)?.length}
        totalRecord={totalCreditor?.length}
      />
      <AccountFooter
        options={[
          {
            name: 'Reject All',
            onClick: () => {
              rejectSubmit()
            },
            color: 'secondary',
            props: {
              disabled:
                handlePermDisabled({
                  companyID: CompanyID,
                  permEnum: AcctPermission.AccPayableBatchPaymentPaid,
                }) || Array.from(detailsID)?.length !== totalCreditor?.length,
            },
          },
          {
            name: 'Pay',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },
            color: 'primary',
            props: {
              disabled:
                detailsID?.size === 0 ||
                handlePermDisabled({
                  companyID: CompanyID,
                  permEnum: AcctPermission.AccPayableBatchPaymentPaid,
                }),
            },
          },
        ]}
      />
    </>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { useHistory } from 'react-router'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import {
  AcctPermission,
  RecordStatus,
  useGetAssignedCompanyQuery,
  useGetCompanyLazyQuery,
  useGetCurrencyQuery,
} from 'generated/graphql'
import Fuse from 'fuse.js'
import AppContext from 'containers/App/Store/AppContext'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import ExcelIcon from '@account-root/account-react/src/assets/icons/excel.svg'
import DownloadIcon from '@material-ui/icons/GetApp'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { CompanyTemplateForm } from './CompanyTemplateForm'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const CompanyListing = (props: any) => {
  const getSearch = localStorage?.getItem('companyFilter')

  let history = useHistory()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const [parentCompany] = useState<string>('000-000-000')
  const { mode, createAccess }: any = props
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }
  const CompanyID = localStorage.getItem('latestCompany')

  const ICBackPath = () => {
    history.push(`/inventory-control/general-setting`)
  }
  const backPath = () => {
    if (isHomeDefault === 'true') return history.push(`/`)
    else {
      history.push({
        pathname:
          mode === 'general-ledger'
            ? `/general-ledger/${CompanyID}`
            : mode === 'cash-book'
            ? `/cash-book/${CompanyID}`
            : mode === 'account-receivable'
            ? `/account-receivable/${CompanyID}`
            : mode === 'general-purchase'
            ? `/general-purchase/${CompanyID}`
            : mode === 'inventory-control'
            ? `/inventory-control/${CompanyID}`
            : `/account-payable/${CompanyID}`,
      })
    }
  }
  const isHomeDefault = localStorage.getItem('isDefault')

  const setLocalAndPath = data => {
    history.push({
      pathname:
        mode === 'general-ledger'
          ? `/general-ledger/${data}/company-setting`
          : mode === 'cash-book'
          ? `/cash-book/${data}/company-setting`
          : mode === 'account-receivable'
          ? `/account-receivable/${data}/company-setting`
          : mode === 'general-purchase'
          ? `/general-purchase/${data}/company-setting`
          : mode === 'inventory-control'
          ? `/inventory-control/${data}/company-setting/numbering-structure`
          : mode === 'company-setup'
          ? `/company-setup/${data}/profile`
          : `/account-payable/${data}/company-setting`,
    })
    localStorage.setItem('latestCompany', data)
  }

  const [
    fetchChildCompany,
    { loading: ChildCompanyLoading, data: { getCompany } = { getCompany: [] } },
  ] = useGetCompanyLazyQuery({
    fetchPolicy: 'network-only',
    variables: { orderByAsc: 'Name' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: CurrencyLoading,
    data: { getCurrency } = { getCurrency: [] },
  } = useGetCurrencyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: CompanyAssignedLoading,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  useEffect(() => {
    if (!!createAccess) {
      fetchChildCompany({
        variables: { orderByAsc: 'Name' },
      })
    } else {
      fetchChildCompany({
        variables: { orderByAsc: 'Name', RecordStatus: RecordStatus.Active },
      })
    }
  }, [parentCompany, createAccess])

  useEffect(() => {
    if (getCompany && getCompany?.length > 0 && user?.superUser) {
      let activeList = getCompany?.filter(el => el?.RecordStatus === 'ACTIVE')

      let inactiveList = getCompany?.filter(
        el => el?.RecordStatus === 'INACTIVE'
      )
      setOriginalListing([...activeList, ...inactiveList])
    }
  }, [getCompany])

  useEffect(() => {
    if (
      getAssignedCompanyByUser &&
      getAssignedCompanyByUser?.length > 0 &&
      !user.superUser
    ) {
      setOriginalListing(getAssignedCompanyByUser)
    }
  }, [getAssignedCompanyByUser])

  let newFilteredList = filteredList?.filter(
    v => v?.ParentCompanyID === parentCompany || parentCompany === '000-000-000'
  )

  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['Name', 'CompanyRegNo']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])

  const { handlePermDisabled } = usePermissionChecker()
  return (
    <>
      {CurrencyLoading && <Loading />}
      {ChildCompanyLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      <MainHeader
        // IF BURGER OPTION
        mainBtn={'back'}
        onClick={mode === 'inventory-control' ? ICBackPath : backPath}
        smTitle={
          isHomeDefault === 'true'
            ? 'AccountX'
            : mode === 'general-ledger'
            ? 'General Ledger'
            : mode === 'cash-book'
            ? 'Cash Book'
            : mode === 'account-receivable'
            ? 'Accounts Receivable'
            : mode === 'inventory-control'
            ? 'Inventory Control'
            : mode === 'company-setup'
            ? 'Company Setup'
            : mode === 'general-purchase'
            ? 'General Purchase'
            : 'Accounts Payable'
        }
        title={user?.accountName}
        routeSegments={[
          {
            name:
              mode === 'inventory-control'
                ? 'Document Numbering'
                : 'Company Setup',
            current: true,
          },
        ]}
      />
      <div className="search-filter with-advanced-filter">
        <SearchHeader
          title="Search here.."
          search
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Name', 'CompanyRegNo'])
            localStorage.setItem('companyFilter', e.target.value)
          }}
          onCloseAction={() => handleSearch('', [])}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch ? getSearch : ''}
        />
      </div>
      <ContentWrapper float>
        {createAccess && (
          <div className="message-alert text-noflow">
            <div className="flex-space">
              <IconText
                icon={
                  <img
                    src={ExcelIcon}
                    style={{ width: '19px', paddingRight: '5px' }}
                  />
                }
                font="mdDesc"
                children={
                  <>
                    <span style={{ fontWeight: 'bold' }}>Upload </span>{' '}
                    <span>Excel Template</span>
                  </>
                }
              ></IconText>
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  minWidth: '0px',
                  width: '32px',
                  height: '24px',
                  margin: '-2px 0px -2px 0px',
                }}
                onClick={() => {
                  setOpenFileUpload(true)
                }}
              >
                <DownloadIcon
                  style={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              </Button>
            </div>
          </div>
        )}
        <List className="core-list">
          {newFilteredList === undefined ||
          newFilteredList?.length === 0 ||
          newFilteredList.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            newFilteredList.map((el, index) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => setLocalAndPath(el.CompanyID)}
                >
                  <ListItemText
                    primary={
                      <>
                        <span
                          className="xsTitle"
                          style={{
                            color:
                              el?.RecordStatus === 'INACTIVE'
                                ? '#BDBDBD'
                                : null,
                          }}
                        >
                          {el.Name}
                        </span>
                      </>
                    }
                    secondary={
                      <span
                        className="desc"
                        style={{
                          color:
                            el?.RecordStatus === 'INACTIVE' ? '#BDBDBD' : null,
                        }}
                      >
                        {el.CompanyRegNo}
                      </span>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="arrow">
                      {((el?.RecordStatus === 'ACTIVE' && !createAccess) ||
                        createAccess) && (
                        <KeyboardArrowRight
                          onClick={() => setLocalAndPath(el.CompanyID)}
                        />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
        {createAccess && (
          <FloatButton
            copyright
            onClick={() =>
              history.push(
                mode === 'company-setup'
                  ? `/company-setup/add`
                  : `/${mode}/company/add`
              )
            }
            disabled={
              !!user?.maxActiveCompany ||
              handlePermDisabled({
                permEnum: AcctPermission.CompanySetupCreate,
              })
                ? getCompany?.length >= user?.maxActiveCompany
                : false
            }
          />
        )}
      </ContentWrapper>
      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/company-setup/excel-validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  CompanyName:
                    x?.CompanyName === null ? null : `${x?.CompanyName}`,
                  RegistrationNo:
                    x?.RegistrationNo === null ? null : `${x?.RegistrationNo}`,
                  CompanyTax:
                    x?.CompanyTax === null ? null : `${x?.CompanyTax}`,
                  BaseCurrency:
                    x?.BaseCurrency === null ? null : `${x?.BaseCurrency}`,
                  ContactNo: x?.ContactNo === null ? null : `${x?.ContactNo}`,
                  Address: x?.Address === null ? null : `${x?.Address}`,
                  Country: x?.Country === null ? null : `${x?.Country}`,
                  State: x?.State === null ? null : `${x?.State}`,
                  City: x?.City === null ? null : `${x?.City}`,
                  PostCode: x?.PostCode === null ? null : `${x?.PostCode}`,
                  Status: x?.Status === null ? null : `${x?.Status}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        downloadButton={true}
        onDownloadTemplate={() => {
          onDownloadTemplate({
            name: `Company Setup SpreadSheet`,
            workSheetName: `Company List`,
            headerSetup: CompanyTemplateForm,
            isHeaderLocked: true,
            defaultHeaderFillStyle: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            },
            defaultHeaderBorderStyle: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            listType: {
              hasCountry: true,
              hasNationality: true,
            },
            extraListData: [
              {
                data: getCurrency?.map(x => x?.code),
                worksheetName: 'BaseCurrency',
                dataType: 'BaseCurrency',
              },
              {
                data: [
                  'No Tax',
                  'GST Registration No.',
                  'SST Registration No.',
                ],
                worksheetName: 'companytax',
                dataType: 'companytax',
              },
            ],
          })
        }}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

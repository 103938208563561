import theme from '@ifca-root/react-component/src/assets/theme'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { Box, Divider, Grid, List, useMediaQuery } from '@material-ui/core'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import { KeyboardArrowDown } from '@material-ui/icons'
import ShareIcon from '@material-ui/icons/Share'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import ShareDialog from 'components/Dialog/ShareDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { format } from 'date-fns'
import {
  useCreateGlExportMutation,
  useFetchShareAttachmentLazyQuery,
  useGetCbglExportLazyQuery,
  useGetCompanyQuery,
  usePostCbglExportMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import * as htmlToImage from 'html-to-image'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useHistory, useParams } from 'react-router-dom'
import './ARGLExport.scss'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const ARGLExportForm = (props: any) => {
  useEffect(() => {
    console.log('ARGLExportForm')
  }, [])

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const { globalState, dispatch }: any = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const history = useHistory()

  const [GLExportList, setGLExportList] = useState(null)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  ///////////////// DATE SEARCH HEADER ///////////////
  const date = new Date()
  const offsetTimestamp = date => {
    return new Date(date.getTime() + date.getTimezoneOffset() * -1 * 60 * 1000)
  }

  //selectedStartDate
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const handleStartDateChange = (date: Date | null) => {
    setSelectedStartDate(date)
  }

  //selectedEndDate
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )
  const handleEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date)
  }

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      getGlExportData({
        variables: {
          startDate: offsetTimestamp(selectedStartDate),
          endDate: offsetTimestamp(selectedEndDate),
          CompanyID: CompanyID,
          AccountID: user?.accountID,
          prefix: 'AR',
        },
      })
    }
  }, [selectedStartDate, selectedEndDate])

  const [
    getGlExportData,
    { loading: glExportLoading, data: { getGLExport } = { getGLExport: [] } },
  ] = useGetCbglExportLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getGLExport }) => {
      setOriginalListing(getGLExport)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  useEffect(() => {
    let temporaryObject = {}

    filteredList?.map(trx => {
      if (!(trx?.AccountCode in temporaryObject)) {
        temporaryObject[trx.AccountCode] = [trx]
      } else {
        temporaryObject[trx.AccountCode].push(trx)
      }
    })

    let finalObj = Object.keys(temporaryObject).map((key, i) => {
      return { [key]: temporaryObject[key] }
    })

    setGLExportList(finalObj)
  }, [filteredList])

  const {
    called: getCompanyCalled,
    loading: getCompanyLoading,
    error: getCompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [postGL, { loading: postGLLoading }] = usePostCbglExportMutation({
    onCompleted: x => {
      setOpenExportDialog(false)
      history.push({
        pathname: `/account-receivable/${CompanyID}/gl-export`,
      })
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [postARGL, { loading: postCBGLLoading }] = useCreateGlExportMutation({
    onCompleted: x => {
      setOpenExportDialog(false)
      history.push({
        pathname: `/account-receivable/${CompanyID}/gl-export`,
      })
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [openExitDialog, setOpenExitDialog] = useState(false)
  const [openExportDialog, setOpenExportDialog] = useState(false)
  const [glExportDate, setGlExportDate] = useState(new Date())

  const debitAmount = data => {
    return data?.reduce((prev, curr) => {
      return prev + Number(curr.Debit)
    }, 0)
  }

  const creditAmount = data => {
    return data?.reduce((prev, curr) => {
      return prev + curr.Credit
    }, 0)
  }

  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openSendEmail, setOpenSendEmail] = useState(false)

  const [
    getShareAttachment,
    {
      data: { fetchShareAttachment } = { fetchShareAttachment: {} as any },
      called: fetchShareAttachmentCalled,
      loading: fetchShareAttachmentLoading,
    },
  ] = useFetchShareAttachmentLazyQuery({
    onCompleted: () => {
      setOpenShareDialog(true)
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const handleShare = blob => {
    getShareAttachment({
      variables: {
        file: blob,
        CompanyID: CompanyID,
        Prefix: 'AR',
      },
    })
  }

  //Export stuff

  let csvRef = useRef(null)

  const exportedGLData = getGLExport?.map(x => ({
    [`Transaction Date`]: format(new Date(glExportDate), 'dd/MMM/yyyy'),
    [`Doc Date`]: format(new Date(x?.TransactionDate), 'dd/MMM/yyyy'),
    [`Transaction Type`]: x.TransactionType,
    [`Transaction Description`]: x.TrxDesc,
    [`Account Code`]: x.AccountCode,
    [`Debit`]: x.Debit,
    [`Credit`]: x.Credit,
  }))

  const downloadGlExport = async () => {
    csvRef.current.link.click()
    // postGL({
    //   variables: {
    //     GLDate: offsetTimestamp(glExportDate),
    //     startDate: offsetTimestamp(selectedStartDate),
    //     endDate: offsetTimestamp(selectedEndDate),
    //     CompanyID: CompanyID,
    //     AccountID: user.accountID,
    //     prefix: 'AR',
    //   },
    //   refetchQueries: [
    //     {
    //       query: GetCbExportedGlListingDocument,
    //       variables: {
    //         CompanyID: CompanyID,
    //         prefix: 'AR',
    //       },
    //     },
    //   ],
    // }).then(y => {
    //   if (y?.data.postGLExport) {
    //     setOpenSnackBar(true)
    //     setSnackBarMsg('Posted Succesfully')
    //   } else {
    //     setOpenExportDialog(false)
    //     setOpenSnackBar(true)
    //     setSnackBarMsg('Posting Failed')
    //   }
    // })

    postARGL({
      variables: {
        input: getGLExport,
        GLDate: glExportDate,
        CompanyID: CompanyID,
        prefix: 'AR',
      },
    })
    //post query
  }

  const ExportDialog = () => {
    return (
      <CommonDialog
        fullWidth={true}
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        sections={{
          header: {
            dynamic: (
              <div className="session">
                <div className="title flex-space color-primary-orange">
                  GL Export
                </div>
              </div>
            ),
          },
          body: () => (
            <KeyboardDatePicker
              autoOk
              onChange={(date: Date | null) => {
                setGlExportDate(date)
              }}
              label="GL Date"
              inputVariant="outlined"
              format=" dd MMM yyyy"
              value={glExportDate}
              className="custom-date-reservation"
              fullWidth
              style={{
                zIndex: 40,
                width: '100%',
                boxShadow: 'revert',
                paddingTop: '0px !important',
              }}
              KeyboardButtonProps={{
                style: {},
                'aria-label': 'change date',
              }}
              InputProps={{
                style: {
                  fontSize: '15px',
                  backgroundColor: 'white',
                  color: 'black',
                },
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
            />
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpenExportDialog(false),
                  variant: 'contained',
                  color: 'primary',
                  startIcon: <ShareIcon />,
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    downloadGlExport()
                  },

                  variant: 'contained',
                  color: 'primary',
                  startIcon: <ShareIcon />,
                },
              },
            ],
          },
        }}
      />
    )
  }

  const Display = () => {
    return (
      <div
        style={{
          marginTop: '0px',
          zIndex: 40,
          position: 'relative',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <ContentWrapper
            float
            footer
            onlyContentScrollable
            style={{
              marginTop: isDesktop ? '152px' : '76px',
            }}
          >
            <>
              <List className="core-list">
                {GLExportList === undefined || GLExportList?.length === 0 ? (
                  <EmptyList
                    title="No Record found"
                    subtitle="Add a new record now."
                  />
                ) : (
                  GLExportList?.map(code => (
                    <Accordion
                      className="accordion-card"
                      defaultExpanded={false}
                    >
                      <AccordionSummary
                        expandIcon={<KeyboardArrowDown />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container justify="flex-start">
                          <Grid
                            item
                            xs={6}
                            className="xsTitle"
                            style={{
                              whiteSpace: 'normal',
                              padding: '0px 6px',
                            }}
                          >
                            {Object.keys(code)[0]}
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            className="desc"
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                            }}
                          >
                            {amtStr(
                              debitAmount(Object.values(code)?.[0])?.toFixed(2)
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="desc"
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                            }}
                          >
                            {amtStr(
                              creditAmount(Object.values(code)?.[0])?.toFixed(2)
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {(Object.values(code)?.[0] as any)?.map(doc => (
                          <>
                            <Grid
                              container
                              justify="flex-start"
                              className="desc"
                              style={{ width: '100%', padding: '4px' }}
                            >
                              <Grid item xs={6} className="desc">
                                <>
                                  <span className="xsTitle date-width">
                                    {formatDate(doc?.TransactionDate)}
                                  </span>
                                  <span style={{ marginLeft: '8px' }}>
                                    {doc?.TrxDesc}
                                  </span>
                                </>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className="desc "
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                }}
                              >
                                {amtStr(Number(doc?.Debit)?.toFixed(2))}
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className="desc "
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                }}
                              >
                                {amtStr(Number(doc?.Credit)?.toFixed(2))}
                              </Grid>
                            </Grid>
                            <Box
                              width="10%"
                              display="flex"
                              justifyContent="center"
                              margin="auto"
                            />
                            <Divider variant="fullWidth" className="flex" />
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </List>
            </>
          </ContentWrapper>
        </div>
      </div>
    )
  }

  return (
    <>
      {getCompanyLoading && <Loading />}
      {(glExportLoading || postGLLoading) && <Loading />}
      <MainHeader
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}/gl-export`)
        }}
        mainBtn="back"
        smTitle={`Accounts Receivable`}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Account Journals' },
          { name: 'GL Journal', current: true },
        ]}
        // rightRouteSegments={[{ name: mode === 'edit' ? 'Edit' : 'Add' }]}
        rightRouteSegments={[{ name: 'New' }]}
      />

      <div
        className={`dynamic-subheader date-input search`}
        style={
          {
            // paddingBottom: '10px',
            // height: '80px',
            // width:
            //   isDesktop && globalState.drawerOpen
            //     ? 'calc(100% - 48px)'
            //     : isDesktop && !globalState.drawerOpen
            //     ? 'calc(100% - 48px) '
            //     : '100%',
          }
        }
      >
        <Grid
          container
          direction="row"
          // justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={6} justify="center">
            <KeyboardDatePicker
              inputVariant="outlined"
              format="dd MMM yyyy"
              id="date-picker-dialog"
              autoOk
              value={selectedStartDate}
              onChange={handleStartDateChange}
              className="custom-date-reservation"
              KeyboardButtonProps={{
                style: {},
                'aria-label': 'change date',
              }}
              style={{
                zIndex: 40,
                width: '100%',
                boxShadow: 'revert',
              }}
              InputProps={{
                style: {
                  fontSize: '12px',
                  backgroundColor: 'white',
                  color: 'black',
                },
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={6} justify="center">
            <KeyboardDatePicker
              inputVariant="outlined"
              format="dd MMM yyyy"
              id="date-picker-dialog"
              value={selectedEndDate}
              autoOk
              onChange={handleEndDateChange}
              className="custom-date-reservation"
              KeyboardButtonProps={{
                style: {},
                'aria-label': 'change date',
              }}
              style={{
                zIndex: 40,
                width: '100%',
              }}
              InputProps={{
                style: {
                  fontSize: '12px',
                  backgroundColor: 'white',
                  color: 'black',
                },
                readOnly: true,
                disableUnderline: true,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '0 8px 8px 0' }}>
            <div style={{ float: 'right' }}>
              <span className="xsTitle">
                <span className="color-primary-orange">Debit:</span>
                <span style={{ color: 'black' }}>
                  {' '}
                  Σ {amtStr(debitAmount(filteredList)?.toFixed(2))}
                  &nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span className="xsTitle">
                <span className="color-primary-orange">Credit:</span>
                <span style={{ color: 'black' }}>
                  {' '}
                  Σ {amtStr(creditAmount(filteredList)?.toFixed(2))}
                </span>
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="search-filter with-dropdown-filter with-date-subheader">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['TrxDesc', 'AccountCode'])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
        />
      </div>

      {Display()}

      <div className="cover-layer" />

      <ExportDialog />

      {!glExportLoading && getGLExport?.length > 0 && (
        <CSVLink
          filename={`GL_Export_${glExportDate}.csv`}
          data={exportedGLData}
          ref={csvRef}
        />
      )}

      {ShareDialog({
        shareTitle: `GL Export - (${formatDate(
          selectedStartDate.toISOString()
        )} - ${formatDate(selectedEndDate.toISOString())})`,
        title: `Share`,
        URL: fetchShareAttachment?.fileURL,
        setSimple: setOpenShareDialog,
        simple: openShareDialog,
        emailShare: openSendEmail,
        setSimpleEmail: setOpenSendEmail,
      })}

      <AccountFooter
        options={[
          {
            name: 'Share',
            onClick: () => {
              let temp = document.getElementById('hiddenGl')
              htmlToImage
                .toBlob(temp, { style: { background: '#fff' } })
                .then(value => {
                  handleShare(value)
                })
                .catch(error => console.error(error, 'Something went wrong'))
            },
            color: getGLExport === undefined ? 'inherit' : 'primary',
            disabled:
              // GLExport?.length === 0 ||
              // handlePermDisabled({
              // 	hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
              // 	permEnum: HotelPermission.AcctJournalGlJournalDownload,
              // }),
              getGLExport === undefined,
            startIcon: <ShareIcon />,
          },
          {
            name: 'Dowload',
            onClick: () => setOpenExportDialog(true),
            color: getGLExport === undefined ? 'inherit' : 'primary',
            disabled:
              // GLExport?.length === 0 ||
              // handlePermDisabled({
              // 	hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
              // 	permEnum: HotelPermission.AcctJournalGlJournalDownload,
              // }),

              getGLExport === undefined,
            startIcon: <ShareIcon />,
          },
        ]}
      />
      <ExitConfirmationDialog
        openExitDialog={openExitDialog}
        setOpenExitDialog={setOpenExitDialog}
        backPath={`/pos/outlet-app/account-journals/gl-journal`}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import { AdvanceDetail } from 'containers/ARAPModule/ARAPDetail/AdvanceDetail'
import React, { lazy } from 'react'

const ICGeneralForm = lazy(() =>
  import('../AssociateForm/ICGeneralForm').then(module => ({
    default: module.ICGeneralForm,
  }))
)

export const icGeneralFormRoutes = [
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-receipt/:CompanyID/add`,
    },
    component: (
      <ICGeneralForm
        mode="add"
        icSubmenu="stock-receipt"
        routeSegments="Stock Receipt"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-issue/:CompanyID/add`,
    },
    component: (
      <ICGeneralForm
        mode="add"
        icSubmenu="stock-issue"
        routeSegments="Stock Issue"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-transfer/:CompanyID/add`,
    },
    component: (
      <ICGeneralForm
        mode="add"
        icSubmenu="stock-transfer"
        routeSegments="Stock Transfer"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-adjustment/:CompanyID/add`,
    },
    component: (
      <ICGeneralForm
        mode="add"
        icSubmenu="stock-adjustment"
        routeSegments="Stock Qty Adjustment"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-cost-adjustment/:CompanyID/add`,
    },
    component: (
      <ICGeneralForm
        mode="add"
        icSubmenu="stock-cost-adjustment"
        routeSegments="Stock Cost Adjustment"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-requisition/:CompanyID/add`,
    },
    component: (
      <ICGeneralForm
        mode="add"
        icSubmenu="stock-requisition"
        routeSegments="Stock Requisition"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-receipt/:CompanyID/:ID/edit`,
    },
    component: (
      <ICGeneralForm
        mode="edit"
        icSubmenu="stock-receipt"
        routeSegments="Stock Receipt"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-issue/:CompanyID/:ID/edit`,
    },
    component: (
      <ICGeneralForm
        mode="edit"
        icSubmenu="stock-issue"
        routeSegments="Stock Issue"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-transfer/:CompanyID/:ID/edit`,
    },
    component: (
      <ICGeneralForm
        mode="edit"
        icSubmenu="stock-transfer"
        routeSegments="Stock Transfer"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-adjustment/:CompanyID/:ID/edit`,
    },
    component: (
      <ICGeneralForm
        mode="edit"
        icSubmenu="stock-adjustment"
        routeSegments="Stock Qty Adjustment"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-cost-adjustment/:CompanyID/:ID/edit`,
    },
    component: (
      <ICGeneralForm
        mode="edit"
        icSubmenu="stock-cost-adjustment"
        routeSegments="Stock Cost Adjustment"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-requisition/:CompanyID/:ID/edit`,
    },
    component: (
      <ICGeneralForm
        mode="edit"
        icSubmenu="stock-requisition"
        routeSegments="Stock Requisition"
      />
    ),
  },
]

export default icGeneralFormRoutes

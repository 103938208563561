import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import React from 'react'

export const ErrorDialog = (props: any) => {
  const { errorDia, setErrorDia, errorMsg, errorHeaderMsg } = props

  return (
    <CommonDialog
      fullWidth={true}
      open={errorDia}
      onClose={() => setErrorDia(false)}
      sections={{
        header: {
          title: !!errorHeaderMsg
            ? `${errorHeaderMsg}${
                errorHeaderMsg?.includes('Error!') ? '' : '!'
              }`
            : 'Error!',
          style: { color: 'red' },
        },
        body: () => (
          <div className="content-wrap full text-noflow">
            <div className="mdDesc text-noflow">{errorMsg}</div>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => setErrorDia(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}

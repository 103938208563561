import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetCompanyLetterTemplateDocument,
  useCreateCompanyLetterTemplateMutation,
  useDeleteCompanyLetterTemplateMutation,
  useGetCompanyLetterTemplateQuery,
  useGetCompanySummaryQuery,
  useGetLetterTemplateQuery,
  useGetStandardLetterQuery,
  useUpdateCompanyLetterTemplateMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export interface CompanyLetterTemplateProps {
  Name: string
  // JointBuyerSignRequired: boolean
  // IsReportDesignEditor: boolean
  IsDefault: boolean
  LetterTemplateID: string
}

export const CompanyLetterTemplateListing = (props: any) => {
  const { pageMode, StandardLetterID, CompanyID }: any = useParams()
  let history = useHistory()
  let location: any = useLocation()
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isHomeDefault = localStorage.getItem('isDefault')
  const [deleteLT, setDeleteLT] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState<boolean>(false)
  const [formMode, setFormMode] = useState('')

  const CompanyLetterTemplateFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    LetterTemplateID: CommonYupValidation.requireField(
      'Letter Template is required'
    ),
  })

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    setValue,
    setError,
    getValues,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<CompanyLetterTemplateProps>({
    defaultValues: {},
    shouldUnregister: false, // unmount input state will be remained
    resolver: yupResolver(CompanyLetterTemplateFormSchema),
  })

  const {
    loading: getCompanyloading,
    error,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanySummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: standardLetterloading,
    data: { getStandardLetter } = { getStandardLetter: [] },
  } = useGetStandardLetterQuery({
    fetchPolicy: 'network-only',
    variables: { StandardLetterID: StandardLetterID },
  })

  const {
    loading: getLetterTemplateloading,
    data: { getLetterTemplate } = {
      getLetterTemplate: [],
    },
  } = useGetLetterTemplateQuery({
    fetchPolicy: 'network-only',
    variables: { StandardLetterID: StandardLetterID },
  })

  const {
    loading: getCompanyLetterTemplateloading,
    data: { getCompanyLetterTemplate } = {
      getCompanyLetterTemplate: [],
    },
  } = useGetCompanyLetterTemplateQuery({
    fetchPolicy: 'network-only',
    variables: { StandardLetterID: StandardLetterID },
  })

  const [
    createCompanyLetterTemplate,
    {
      loading: createCompanyLetterTemplateLoading,
      error: createCompanyLetterTemplateError,
    },
  ] = useCreateCompanyLetterTemplateMutation({
    onError: error => {
      setSnackBarMsg(error.message)
    },
    onCompleted: ({ createCompanyLetterTemplate }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      if (createCompanyLetterTemplate) {
        setSnackBarMsg('Created Successfully!')
        setOpen(false)
      } else {
        setSnackBarMsg('Create Failed!')
      }
    },
  })

  const [
    //updateCompanyLetterTemplate to updateCompanyLetterTemplate
    updateCompanyLetterTemplate,
    {
      loading: updateCompanyLetterTemplateLoading,
      error: updateCompanyLetterTemplateError,
    },
  ] = useUpdateCompanyLetterTemplateMutation({
    onError: error => {
      setSnackBarMsg(error.message)
    },
    onCompleted: ({ updateCompanyLetterTemplate }) => {
      if (updateCompanyLetterTemplate) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        setOpen(false)
      } else {
        setSnackBarMsg('Update Failed!')
      }
    },
  })

  const [
    //deleteCompanyLetterTemplate to deleteCompanyLetterTemplate
    deleteCompanyLetterTemplate,
    {
      loading: deleteCompanyLetterTemplateLoading,
      error: deleteCompanyLetterTemplateError,
    },
  ] = useDeleteCompanyLetterTemplateMutation({
    // onError: error => {
    //   if (error.message.includes('FK_')) {
    //     setdelError(true)
    //   }
    // },
    onCompleted: ({ deleteCompanyLetterTemplate }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setDeleteLT(false)
      if (deleteCompanyLetterTemplate) {
        setSnackBarMsg('Deleted Successfully!')
        setDeleteLT(false)
      } else {
        setSnackBarMsg('Delete Failed!')
      }
    },
  })

  let headerTitle

  useEffect(() => {
    if (pageMode === 'general-ledger') {
      headerTitle = 'General Ledger'
    } else if (pageMode === 'cash-book') {
      headerTitle = 'Cash Book'
    } else if (pageMode === 'account-receivable') {
      headerTitle = 'Accounts Receivable'
    } else if (pageMode === 'account-payable') {
      headerTitle = 'Accounts Payable'
    } else if (pageMode === 'inventory-control') {
      headerTitle = 'Inventory Control'
    } else if (pageMode === 'general-purchase') {
      headerTitle = 'General Purchase'
    }
  }, [pageMode])

  const onSubmit = (data, menu) => {
    if (formMode === 'edit') {
      updateCompanyLetterTemplate({
        variables: {
          input: {
            CompanyLetterTemplateID: menu?.obj?.CompanyLetterTemplateID,
            Name: data?.Name,
            // JointBuyerSignRequired: data?.JointBuyerSignRequired,
            // IsReportDesignEditor: data?.IsReportDesignEditor,
            StandardLetterID: StandardLetterID,
            CompanyID: CompanyID,
            IsDefault: data?.IsDefault,
            LetterTemplateID: data?.LetterTemplateID,
          },
        },
        refetchQueries: [
          {
            query: GetCompanyLetterTemplateDocument,
            variables: {
              StandardLetterID: StandardLetterID,
            },
          },
        ],
      })
    } else if (formMode === 'add') {
      createCompanyLetterTemplate({
        variables: {
          input: {
            Name: data?.Name,
            // JointBuyerSignRequired: data?.JointBuyerSignRequired,
            // IsReportDesignEditor: data?.IsReportDesignEditor,
            StandardLetterID: StandardLetterID,
            CompanyID: CompanyID,
            IsDefault: data?.IsDefault,
            LetterTemplateID: data?.LetterTemplateID,
          },
        },
        refetchQueries: [
          {
            query: GetCompanyLetterTemplateDocument,
            variables: {
              StandardLetterID: StandardLetterID,
            },
          },
        ],
      })
    }
  }

  const handleDelete = data => {
    deleteCompanyLetterTemplate({
      variables: {
        input: { CompanyLetterTemplateID: data?.CompanyLetterTemplateID },
      },
      refetchQueries: [
        {
          query: GetCompanyLetterTemplateDocument,
          variables: {
            StandardLetterID: StandardLetterID,
          },
        },
      ],
    })
    setDeleteLT(false)
  }

  const checkSameName = () => {
    const getLT = !menu?.obj
      ? getCompanyLetterTemplate
      : getCompanyLetterTemplate?.filter(
          x => x?.CompanyLetterTemplateID !== menu?.obj?.CompanyLetterTemplateID
        )

    const sameName = getLT?.filter(
      el => el?.Name.toLocaleLowerCase() === watch('Name')?.toLocaleLowerCase()
    )

    if (sameName?.length > 0) {
      return true
    } else return false
  }

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: menu?.obj,
      formMode: formMode,
    })

  return (
    <>
      {standardLetterloading && <Loading />}
      {getCompanyLetterTemplateloading && <Loading />}
      {createCompanyLetterTemplateLoading && <Loading />}
      {updateCompanyLetterTemplateLoading && <Loading />}
      {deleteCompanyLetterTemplateLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname: `/${pageMode}/${CompanyID}/company-setting/company-standard-letter`,
          })
        }}
        smTitle={isHomeDefault === 'true' ? 'AccountX' : headerTitle}
        title={user?.companyName}
        // title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'System Admin' },
          { name: 'Document Template' },
          { name: 'Company Letter Template', current: true },
        ]}
      />
      <DynamicSubHeader
        title={curCompany[0]?.Name}
        infoLine={getStandardLetter[0]?.Name}
      />

      <ContentWrapper multiDynamicInfo>
        {getCompanyLetterTemplate?.length === 0 ? (
          <EmptyList
            title="No Company Letter Template"
            subtitle="Please create new Company Letter Template"
          />
        ) : (
          <List className="core-list">
            {getCompanyLetterTemplate?.map((v, index) => (
              <ListItem>
                <ListItemText
                  primary={
                    <div className="xsTitle">
                      <span className="">{v?.Name}</span>
                      {v?.IsDefault === true ? (
                        <span>
                          {' '}
                          <CheckCircleIcon
                            style={{
                              color: 'limegreen',
                              fontSize: 'medium',
                            }}
                          />
                        </span>
                      ) : null}
                    </div>
                  }
                  secondary={
                    <>
                      <span className="desc">{v?.IsReportDesignEditor}</span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e =>
                      handleClick(e, v?.CompanyLetterTemplateID, index, v)
                    }
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </ContentWrapper>

      <CommonDialog
        fullWidth={true}
        open={open}
        onClose={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            setOpen(false)
            reset()
            resetMenu()
          }
        }}
        sections={{
          header: {
            title: 'Company Letter Template',
            rightText: formMode === 'edit' ? 'Edit' : 'New',
          },
          body: () => (
            <>
              <div className="content-container">
                <Controller
                  as={TextField}
                  id="standard-basic"
                  name="Name"
                  label={'Name'}
                  required
                  control={control}
                  fullWidth
                  helperText={
                    checkSameName() === true
                      ? 'Name already exsist.'
                      : errors?.Name
                      ? errors?.Name.message
                      : null
                  }
                  error={
                    checkSameName() === true || errors?.Name ? true : false
                  }
                  ref={register}
                />

                <Controller
                  render={({ value, onChange }) => {
                    const defVal = getLetterTemplate?.filter(
                      x => x?.LetterTemplateID === menu?.obj?.LetterTemplateID
                    )[0]
                    return (
                      <Autocomplete
                        options={
                          getLetterTemplate?.sort((a, b) => {
                            return a?.Name?.localeCompare(b?.Name)
                          }) || []
                        }
                        getOptionLabel={option => `${option?.Name}`}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          setValue(
                            'LetterTemplateID',
                            newValue?.LetterTemplateID
                          )
                        }}
                        renderOption={(props, option) => {
                          return <span>{props?.Name}</span>
                        }}
                        defaultValue={defVal}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={errors?.LetterTemplateID?.message}
                                error={errors?.LetterTemplateID ? true : false}
                                label="Letter Template"
                                style={{ width: '100%' }}
                                margin="dense"
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Letter Template"
                  name="LetterTemplateID"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={register}
                  helperText={errors?.LetterTemplateID?.message}
                  error={errors?.LetterTemplateID ? true : false}
                  defaultValue={menu?.obj?.LetterTemplateID}
                  required
                />

                {/* <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={watch('JointBuyerSignRequired')}
                        onChange={e =>
                          setValue('JointBuyerSignRequired', e.target.checked)
                        }
                        name="JointBuyerSignRequired"
                        color="primary"
                        defaultValue={menu?.obj?.JointBuyerSignRequired}
                      />
                    }
                    label="Joint Buyer Signature"
                  />
                </FormControl>

                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={watch('IsReportDesignEditor')}
                        onChange={e =>
                          setValue('IsReportDesignEditor', e.target.checked)
                        }
                        name="IsReportDesignEditor"
                        color="primary"
                        defaultValue={menu?.obj?.IsReportDesignEditor}
                      />
                    }
                    label="Only allow in Html editor"
                  />
                </FormControl> */}

                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={watch('IsDefault')}
                        onChange={e => setValue('IsDefault', e.target.checked)}
                        name="IsDefault"
                        color="primary"
                        defaultValue={menu?.obj?.IsDefault}
                      />
                    }
                    label="Default"
                  />
                </FormControl>
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    if (hasChanges() === true) {
                      setOpenExitConf(true)
                    } else {
                      setOpen(false)
                      reset()
                      resetMenu()
                    }
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleSubmit(data => onSubmit(data, menu))(),
                  variant: 'contained',
                  color: 'primary',
                  disabled: checkSameName(),
                },
              },
            ],
          },
        }}
      />

      <FloatButton
        onClick={() => {
          setValue('Name', '')
          // setValue('JointBuyerSignRequired', false)
          // setValue('IsReportDesignEditor', false)
          setValue('IsDefault', false)
          setValue('LetterTemplateID', '')
          setOpen(true)
          setFormMode('add')
          resetMenu()
        }}
      />

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          // disabled={handlePermDisabled({
          //   companyID: CompanyID,
          //   permEnum: AcctPermission.AccPayableBatchPaymentUpdate,
          // })}
          onClick={() => {
            history.push({
              pathname: `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${StandardLetterID}/company-letter-template/${menu?.obj?.CompanyLetterTemplateID}/design`,
              state: menu?.obj,
            })
          }}
        >
          <span className="">Design</span>
        </MenuItem>
        <MenuItem
          // disabled={handlePermDisabled({
          //   companyID: CompanyID,
          //   permEnum: AcctPermission.AccPayableBatchPaymentDelete,
          // })}
          onClick={() => {
            setValue('Name', menu?.obj?.Name)
            // setValue(
            //   'JointBuyerSignRequired',
            //   menu?.obj?.JointBuyerSignRequired
            // )
            // setValue('IsReportDesignEditor', menu?.obj?.IsReportDesignEditor)
            setValue('IsDefault', menu?.obj?.IsDefault)
            setValue('LetterTemplateID', menu?.obj?.LetterTemplateID)
            setOpen(true)
            setFormMode('edit')
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          // disabled={handlePermDisabled({
          //   companyID: CompanyID,
          //   permEnum: AcctPermission.AccPayableBatchPaymentDelete,
          // })}
          onClick={() => setDeleteLT(true)}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      <CommonDialog
        fullWidth
        open={deleteLT}
        onClose={() => setDeleteLT(false)}
        sections={{
          header: {
            title: 'Company Letter Template',
            rightText: `Delete`,
          },
          body: () => (
            <div className="content-wrap full">
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: 500,
                }}
              >
                {`Are you sure you want to delete ${menu?.obj?.Name}?`}
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setDeleteLT(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleDelete(menu?.obj)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          setOpen(false)
          setOpenExitConf(false)
          reset()
        }}
      />
    </>
  )
}

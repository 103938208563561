import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetCompanyNameQuery,
  useGetCostCentreLazyQuery,
  useGetEntityCoaQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import '../AuditAdjustment.scss'

interface AuditAdjustmentItemProps {
  MasterCOAID: string
  DocAmt: number
  CostCentreID: string
  Remark: string
}

export const AuditAdjustmentItemForm = (props: any) => {
  const {
    CompanyID,
    AuditAdjustmentID,
    AuditAdjustmentItemID,
    formMode,
    detailMode,
  }: any = useParams()

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  let history = useHistory()

  let location = useLocation()
  const editData = location?.state as any
  const itemEditData: any = JSON.parse(
    localStorage.getItem('auditAdjustmentItem')
  )?.filter(item => item?.AuditAdjustmentItemID === AuditAdjustmentItemID)[0]
  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [docAmt, setDocAmt] = useState(
    amtNumStr(AuditAdjustmentItemID && itemEditData?.DocAmt) ?? 0
  )
  const [openExitConf, setOpenExitConf] = useState(null)
  const [text, setText] = useState(mode === 'add' ? '' : itemEditData?.remark)
  const [record, setRecord] = useState(false)
  const [audioInput, setAudioInput] = useState('')
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)
  const [accountType, setAccountType] = useState('')
  const [voiceRemark, setVoiceRemark] = useState('')

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */
  //Company
  const {
    loading: CompanyLoading,
    error: CompanyErrors,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
    onCompleted: data => {
      loadDefaultCostCentre(data?.getCompany[0]?.DefaultCostCentre)
    },
  })

  //EntityCOA
  const {
    loading: EntityCOALoading,
    error: EntityCOAErros,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },

    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
    onCompleted: ({ getMasterCOA }) => {
      if (getMasterCOA?.length > 0) {
        if (!!itemEditData) {
          const findcoa = getMasterCOA?.find(
            x => x?.MasterCOAID === itemEditData?.MasterCOAID
          )
          setAccountType(findcoa?.AccountType)
        }
      }
    },
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: CostCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  const handleAmtChange = event => {
    setDocAmt(event.value)
  }

  const handleDefaultCostCentre = (accType, coaId) => {
    setAccountType(accType)
    if (
      accType === 'ASSET' ||
      accType === 'LIABILITY' ||
      accType === 'EQUITY'
    ) {
      setValue('CostCentreID', defaultCostCentre?.CostCentreID)
    } else {
      reset({ CostCentreID: null })
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: coaId,
          IsLastNode: true,
        },
      })
    }
  }

  /* -------------------------------------------- */
  /*                   USE EFFECT                 */
  /* -------------------------------------------- */
  useEffect(() => {
    if (AuditAdjustmentItemID && itemEditData?.Remark) {
      setText(itemEditData?.Remark)
      handleSingleRemark(AuditAdjustmentItemID && itemEditData?.Remark)
      setAudioInput(AuditAdjustmentItemID && itemEditData?.Remark)
      setValue('Remark', itemEditData?.Remark)
      fetchCostCentre({
        variables: {
          CompanyID: CompanyID,
          MasterCOAID: itemEditData?.MasterCOAID,
          IsLastNode: true,
        },
      })
    }
  }, [])

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  const AuditAdjustmentItemSchema = yup.object().shape({
    MasterCOAID: CommonYupValidation.requireField(
      'Expense Account is required'
    ),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    DocAmt: CommonYupValidation.requireField('DocAmt is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm<AuditAdjustmentItemProps>({
    defaultValues: {
      MasterCOAID:
        AuditAdjustmentItemID && itemEditData ? itemEditData?.MasterCOAID : '',
      DocAmt: AuditAdjustmentItemID && itemEditData ? itemEditData?.DocAmt : 0,
      CostCentreID:
        AuditAdjustmentItemID && itemEditData ? itemEditData?.CostCentreID : '',
      Remark: AuditAdjustmentItemID && itemEditData ? itemEditData?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(AuditAdjustmentItemSchema),
  })

  //--- Submit Payment Item to LocalStorage
  const onSubmit = data => {
    const prvdetail =
      detailMode === 'add'
        ? JSON.parse(localStorage.getItem('auditAdjustmentItem')) || []
        : JSON.parse(localStorage.getItem('auditAdjustmentItem')).filter(
            x => x?.AuditAdjustmentItemID !== AuditAdjustmentItemID
          )

    localStorage.setItem(
      'auditAdjustmentItem',
      JSON.stringify([
        ...(prvdetail?.length === null || 0 ? null : prvdetail),

        {
          Sequence: itemEditData?.Sequence ?? prvdetail?.length + 1,
          AuditAdjustmentItemID:
            detailMode === 'add' ? uuid() : AuditAdjustmentItemID,
          MasterCOAID:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? data.MasterCOAID
              : itemEditData?.MasterCOAID,
          MasterCOACode:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getEntityCOA.filter(
                  coa => coa?.MasterCOAID === data.MasterCOAID
                )[0]?.MasterCOA?.Code
              : getEntityCOA.filter(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.MasterCOA?.Code,
          MasterCOAName:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getEntityCOA.filter(
                  coa => coa?.MasterCOAID === data.MasterCOAID
                )[0]?.MasterCOA?.Name
              : getEntityCOA.filter(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.MasterCOA?.Name,
          DocAmt:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? parseFloat(amtNumStr(data.DocAmt))
              : itemEditData?.DocAmt,
          CostCentreID: data.CostCentreID,

          CostCentreCode:
            getCostCentre.filter(
              ccc => ccc?.CostCentreID === data.CostCentreID
            )[0]?.Code ?? defaultCostCentre.Code,
          CostCentreName:
            getCostCentre.filter(
              ccc => ccc?.CostCentreID === data.CostCentreID
            )[0]?.Name ?? defaultCostCentre.Name,
          Remark: data.Remark,
        },
      ])
    )

    if (formMode === 'add') {
      history.push({
        pathname: `/general-ledger/${CompanyID}/audit-adjustment/${formMode}`,
        state: { ...editData },
      })
    } else if (formMode === 'edit' || formMode === 'approve-reject')
      history.push({
        pathname: `/general-ledger/${CompanyID}/audit-adjustment/${AuditAdjustmentID}/${formMode}`,
        state: { ...editData, detailMode: 'add' },
      })
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: itemEditData,
      formMode: detailMode,
    })

  return (
    <>
      {CompanyLoading && <Loading />}
      {masterCOALoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            if (formMode === 'add')
              history.push({
                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${formMode}`,
                state: { ...editData },
              })
            else {
              history.push({
                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${AuditAdjustmentID}/${formMode}`,
                state: { ...editData, detailMode: 'add' },
              })
            }
          }
        }}
        smTitle={'General Ledger'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'General Ledger' },
          { name: 'Audit Adjustment', current: true },
        ]}
        rightRouteSegments={[
          { name: detailMode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'Adjustment Detail',
            },
          }}
        >
          {!EntityCOALoading && (
            <Controller
              render={({ value, onChange }) => {
                let editCOA = masterCOAList?.find(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )
                let selectedCOA = masterCOAList?.filter(
                  coa => coa?.MasterCOAID === value
                )[0]
                return (
                  <Autocomplete
                    options={
                      masterCOAList?.sort((a, b) => {
                        return a?.Code.localeCompare(b?.Code)
                      }) || []
                    }
                    getOptionLabel={option =>
                      `${option?.Code} | ${option?.Name}`
                    }
                    fullWidth
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.MasterCOAID)
                      setValue('CostCentreID', null)
                      handleDefaultCostCentre(
                        newValue?.AccountType,
                        newValue?.MasterCOAID
                      )
                    }}
                    disabled={mode === 'approve-reject'}
                    PopperComponent={AccCodeDropdownFullWidth}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">{props?.Code}</span>
                          </div>
                          <div className="desc">{props?.Name}</div>
                        </div>
                      )
                    }}
                    defaultValue={editCOA}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            defaultValue={editCOA}
                            helperText={errors?.MasterCOAID?.message}
                            error={errors?.MasterCOAID ? true : false}
                            label="Account Code *"
                            style={{ width: '100%' }}
                            disabled={mode === 'approve-reject'}
                            InputLabelProps={
                              selectedCOA ? { shrink: true } : null
                            }
                            margin="normal"
                            variant="standard"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Account Code "
              name="MasterCOAID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.MasterCOAID?.message}
              error={errors?.MasterCOAID ? true : false}
              required
              disabled={formMode === 'approve-reject' ? true : false}
              defaultValue={itemEditData?.MasterCOAID}
            />
          )}

          {accountType === 'ASSET' ||
          accountType === 'LIABILITY' ||
          accountType === 'EQUITY' ? (
            <Controller
              render={({ value, onChange }) => {
                return (
                  <TextField
                    value={`${defaultCostCentre?.Code} | ${defaultCostCentre?.Name}`}
                    label="Department *"
                    fullWidth
                    disabled
                  />
                )
              }}
              label="Department *"
              name="CostCentreID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CostCentreID?.message}
              error={errors?.CostCentreID ? true : false}
              required
              disabled={formMode === 'approve-reject' ? true : false}
            />
          ) : (
            !CostCentreLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const editCC = getCostCentre?.filter(
                    cc => cc?.CostCentreID === itemEditData?.CostCentreID
                  )[0]
                  const selectedCC = getCostCentre?.filter(
                    cc => cc?.CostCentreID === value
                  )[0]
                  return (
                    <Autocomplete
                      options={
                        getCostCentre?.sort((a, b) => {
                          return a?.Code.localeCompare(b?.Code)
                        }) || []
                      }
                      getOptionLabel={option =>
                        `${option?.Code} | ${option?.Name}`
                      }
                      fullWidth
                      onChange={(value, newValue: any) => {
                        onChange(newValue?.CostCentreID)
                      }}
                      disabled={mode === 'approve-reject'}
                      PopperComponent={AccCodeDropdownFullWidth}
                      renderOption={(props, option) => {
                        return (
                          <div>
                            <div>
                              <span className="xsTitle">{props?.Code}</span>
                            </div>
                            <div className="desc">{props?.Name}</div>
                          </div>
                        )
                      }}
                      defaultValue={editCC}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.CostCentreID?.message}
                              error={errors?.CostCentreID ? true : false}
                              label="Department *"
                              style={{ width: '100%' }}
                              disabled={mode === 'approve-reject'}
                              InputLabelProps={
                                selectedCC ? { shrink: true } : null
                              }
                              margin="normal"
                            />
                          </div>
                        )
                      }}
                    />
                  )
                }}
                label="Department *"
                name="CostCentreID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="normal"
                ref={register}
                helperText={errors?.CostCentreID?.message}
                error={errors?.CostCentreID ? true : false}
                required
                disabled={formMode === 'approve-reject' ? true : false}
                defaultValue={itemEditData?.CostCentreID}
              />
            )
          )}

          <Controller
            as={<NumberFormat />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="DocAmt"
            label="Amount"
            value={docAmt}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="normal"
            required
            helperText={errors?.DocAmt?.message}
            error={errors?.DocAmt ? true : false}
            ref={register}
            defaultValue={AuditAdjustmentItemID ? itemEditData?.DocAmt : 0}
            disabled={formMode === 'approve-reject' ? true : false}
          />
          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
        </CardContents>

        <ErrorDialog
          errorDia={errorDia}
          setErrorDia={setErrorDia}
          errorMsg={errMsg}
          errorHeaderMsg={'Error!'}
        />
      </ContentWrapper>
      <AccountFooter
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },

            props: {
              type: 'submit',
              disabled: watch('DocAmt') == 0,
            },
          },
        ]}
      />
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          if (formMode === 'add')
            history.push({
              pathname: `/general-ledger/${CompanyID}/audit-adjustment/${formMode}`,
              state: { ...editData },
            })
          else {
            history.push({
              pathname: `/general-ledger/${CompanyID}/audit-adjustment/${AuditAdjustmentID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }
        }}
      />
    </>
  )
}

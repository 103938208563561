import { Divider, IconButton, ListItemSecondaryAction } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import React from 'react'

interface IDropdownBar {
  options: any
  className?: React.CSSProperties
  borderRadius?: string
  setOptionID?: React.Dispatch<string>
  defaultOption?: number
  searchFilter?: any
  isSearchFilter?: boolean
}

export default function DropdownBar(props) {
  const {
    options,
    borderRadius,
    setOptionID,
    defaultOption,
    searchFilter,
    isSearchFilter = true,
  } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(defaultOption ?? 0)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    // event: React.MouseEvent<HTMLElement>,
    optionID: any,
    index: number
  ) => {
    setOptionID(optionID)
    setSelectedIndex(index)
    setAnchorEl(null)
    !!isSearchFilter &&
      localStorage.setItem(
        'searchFilter',
        JSON.stringify({
          name: searchFilter?.name ?? '',
          option: optionID ?? defaultOption,
        })
      )
  }

  React.useEffect(() => {
    setSelectedIndex(defaultOption ?? 0)
  }, [defaultOption])

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className="filter-dropdown">
      <List
        disablePadding
        className="search-header"
        style={{ borderRadius: borderRadius }}
      >
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
        >
          <ListItemText
            disableTypography
            primary={
              <span className="xsTitle">{options[selectedIndex]?.name}</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="arrow"
              onClick={handleClickListItem}
            >
              {/* <KeyboardArrowDownIcon /> */}
              <ArrowDropDownIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map((option, index) => {
          return (
            <div key={index}>
              {option?.divider && <Divider></Divider>}
              <MenuItem
                key={index}
                selected={index === selectedIndex}
                value={option.ID}
                onClick={e => {
                  option.unclickable
                    ? null
                    : handleMenuItemClick(option?.ID, index)
                }}
              >
                {option.name}
              </MenuItem>
            </div>
          )
        })}
      </Menu>
    </div>
  )
}

import React from 'react'
import { CreditorProfileAssignment } from './CreditorProfileAssignment'
import { CreditorProfileDetail } from './CreditorProfileDetail'
import { CreditorProfileForm } from './CreditorProfileForm'
import { CreditorProfileListing } from './CreditorProfileListing'
import { CreditorProfileExcelValidation } from './CreditorProfileExcelValidation'

const creditorProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/creditor-profile/:creditorType',
    },
    component: <CreditorProfileListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/creditor-profile/:creditorType/add',
    },
    component: <CreditorProfileForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/creditor-profile/import/excel-validation',
    },
    component: <CreditorProfileExcelValidation />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/creditor-profile/:creditorType/:CreditorAccountID/edit',
    },
    component: <CreditorProfileForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/creditor-profile/:creditorType/:CreditorAccountID/detail',
    },
    component: <CreditorProfileDetail mode="view" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/general-setting/creditor-profile/:creditorType/:CreditorAccountID/assign',
    },
    component: <CreditorProfileAssignment mode="assign" />,
  },
]

export default creditorProfileRoutes

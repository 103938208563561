import { AttachFile } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Print'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { default as React, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import '../APCreditNote.scss'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import { Grid } from '@material-ui/core'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const APCreditNoteDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList, mode } = props

  return (
    <>
      {mode === 'detail' && (
        <>
          <div className="content-wrap left flex-space">
            <div className="desc" style={{ color: 'grey' }}>
              Date
            </div>
            <div className="mdDesc"> {formatDate(listEl?.DocDate)} </div>
          </div>

          <div className="content-wrap right ">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <span
              style={{ float: 'right' }}
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/credit-note/${listEl?.CreditNoteID}/preview`,
                  state: {
                    ...listEl,
                    // item: listEl?.APCreditNoteItem,
                    mode: 'detail',
                  },
                })
              }}
            >
              <PrintIcon
                style={{
                  fontSize: 'medium',
                  padding: '0px 10px 2px 0px',
                }}
              />
            </span>
            <div className="mdDesc"> {listEl?.RefNo} </div>
          </div>

          <div className="content-wrap full ">
            <div className="desc" style={{ color: 'grey' }}>
              Creditor Name
            </div>
            <div className="mdDesc">
              {' '}
              {listEl?.CreditorAccount?.CompanyName}{' '}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div
              className="mdDesc "
              style={{
                fontWeight: 500,
                whiteSpace: 'normal',
                overflowWrap: 'anywhere',
              }}
            >
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>
        </>
      )}

      {mode === 'expansion' && (
        <>
          {listEl?.CostCentre && (
            <div className="content-wrap full">
              <div className="desc" style={{ color: 'grey' }}>
                Cost Center
              </div>
              <div className="mdDesc"> {listEl?.CostCentre?.Name}</div>
            </div>
          )}

          <Grid container>
            <Grid item xs={10}>
              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Description
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {listEl?.Description}
                </div>
              </div>

              {listEl?.Remark && (
                <div className="content-wrap full">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Remark
                  </div>
                  <div
                    className="xsTitle text-noflow"
                    style={{ fontWeight: 500 }}
                  >
                    {listEl?.Remark}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              {documentListing && documentListing?.length > 0 && (
                <>
                  <div
                    className="content-wrap right"
                    style={{
                      textAlign: 'center',
                      alignSelf: 'end',
                    }}
                  >
                    {documentListing && (
                      <>
                        <span
                          onClick={() =>
                            documentListing?.forEach(doc => {
                              window.open(doc?.fileURL, '_blank')
                            })
                          }
                          style={{
                            position: 'relative',
                            top: '1px',
                          }}
                        >
                          <AttachFile
                            className="mdDesc"
                            style={{
                              fontSize: 12,
                              color: 'orange',
                            }}
                          />
                        </span>
                        <span
                          className="mdDesc"
                          style={{
                            color: 'orange',
                            textDecoration: 'underline',
                          }}
                        >
                          {documentListing?.length}
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}

      {mode === 'expansion' && (
        <>
          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="mdDesc"> {listEl?.RefNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              {'Transaction Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>
        </>
      )}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Attachment
          </div>
          {attachmentDetailView(documentListing)}
        </div>
      )}

      {/* <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div> */}

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}

import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import BankTransfer from 'assets/icons/CB/bank-transfer.svg'
import Payment from 'assets/icons/CB/Payment.svg'
import Receipt from 'assets/icons/CB/receipt.svg'
import BudgetRevision from 'assets/icons/GL/budget-revision.svg'
import OriginalBudget from 'assets/icons/GL/original-budget.svg'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAllApprovalPolicyAssignmentQuery,
  useGetCompanyExecutiveSummaryQuery,
  useGetCompanyNameQuery,
  useGetGeneralSettingExecutiveSummaryQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const ApprovalPolicyAsgmtSubmenu = (props: any) => {
  useEffect(() => {
    console.log('ApprovalPolicyAsgmtSubmenu')
  }, [])
  let location = useLocation()
  const { module: pageMode, policyMode } = props
  const { CompanyID }: any = useParams()
  let history = useHistory()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  const {
    loading: GSSummaryLoading,
    error,
    data: GeneralSettingSummaryData,
  } = useGetGeneralSettingExecutiveSummaryQuery({
    fetchPolicy: 'network-only',
  })

  const GLCategory = [
    'GL_OriginalBudget',
    'GL_BudgetRevision',
    'GL_RecurringJournal',
    'GL_JournalProcessing',
    'GL_AuditAdjustment',
  ]

  const CBCategory = ['CB_Payment', 'CB_Receipt', 'CB_BankTransfer']

  const category =
    pageMode === 'general-ledger'
      ? GLCategory
      : pageMode === 'cash-book'
      ? CBCategory
      : null

  console.log(CBCategory, 'CBCategory')

  const { data: CompanySummaryData } = useGetCompanyExecutiveSummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, Category: category },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    loading: getAllApprovalPolicyAssignmentLoading,
    data: { getAllApprovalPolicyAssignment } = {
      getAllApprovalPolicyAssignment: [],
    },
  } = useGetAllApprovalPolicyAssignmentQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const ApprovalPolicyAsgmDatetCB = {
    PaymentDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'CB_Payment'
    )
      ? getAllApprovalPolicyAssignment?.find(x => x?.Category === 'CB_Payment')
          ?.modTs
      : null,
    ReceiptDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'CB_Receipt'
    )
      ? getAllApprovalPolicyAssignment?.find(x => x?.Category === 'CB_Receipt')
          ?.modTs
      : null,
    BankTransferDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'CB_BankTransfer'
    )
      ? getAllApprovalPolicyAssignment?.find(
          x => x?.Category === 'CB_BankTransfer'
        )?.modTs
      : null,
  }
  const ApprovalPolicyAsgmDatetGL = {
    OriginalBudgetDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'GL_OriginalBudget'
    )
      ? getAllApprovalPolicyAssignment?.find(
          x => x?.Category === 'GL_OriginalBudget'
        )?.modTs
      : null,
    BudgetRevisionDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'GL_BudgetRevision'
    )
      ? getAllApprovalPolicyAssignment?.find(
          x => x?.Category === 'GL_BudgetRevision'
        )?.modTs
      : null,
    RecurringJournalDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'GL_RecurringJournal'
    )
      ? getAllApprovalPolicyAssignment?.find(
          x => x?.Category === 'GL_RecurringJournal'
        )?.modTs
      : null,
    JournalProcessingDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'GL_JournalProcessing'
    )
      ? getAllApprovalPolicyAssignment?.find(
          x => x?.Category === 'GL_JournalProcessing'
        )?.modTs
      : null,
    AuditAdjustmentDate: !!getAllApprovalPolicyAssignment?.find(
      x => x?.Category === 'GL_AuditAdjustment'
    )
      ? getAllApprovalPolicyAssignment?.find(
          x => x?.Category === 'GL_AuditAdjustment'
        )?.modTs
      : null,
  }

  const approvalPolicyAsgmtSubmenuCB = [
    {
      path: `/cash-book/${CompanyID}/company-setting/approval-policy-asgmt-cb/payment`,
      title: `Payment`,
      label1: `Last updated on`,
      // data1: formatDate(ApprovalPolicyAsgmDatetCB?.PaymentDate),
      data1: formatDate(CompanySummaryData?.PaymentStandardApprovalFlowDate),
      icon: Payment,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
    },
    {
      path: `/cash-book/${CompanyID}/company-setting/approval-policy-asgmt-cb/receipt`,
      title: `Receipt`,
      label1: `Last updated on`,
      // data1: formatDate(ApprovalPolicyAsgmDatetCB?.ReceiptDate),
      data1: formatDate(CompanySummaryData?.ReceiptStandardApprovalFlowDate),
      icon: Receipt,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/cash-book/${CompanyID}/company-setting/approval-policy-asgmt-cb/bank-transfer`,
      title: `Bank Transfer`,
      label1: `Last updated on`,
      // data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      data1: formatDate(
        CompanySummaryData?.BankTransferStandardApprovalFlowDate
      ),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
  ]

  const approvalPolicyAsgmtSubmenuGL = [
    {
      path: `/general-ledger/${CompanyID}/company-setting/approval-policy-asgmt-gl/budget/original`,
      title: `Original Budget`,
      label1: `Last updated on`,
      data1: formatDate(
        CompanySummaryData?.OriginalBudgetApprovalPolicyAssignmentDate
      ),
      icon: OriginalBudget,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
    },
    {
      path: `/general-ledger/${CompanyID}/company-setting/approval-policy-asgmt-gl/budget/revision`,
      title: `Budget Revision`,
      label1: `Last updated on`,
      data1: formatDate(
        CompanySummaryData?.BudgetRevisionApprovalPolicyAssignmentDate
      ),
      // data1: formatDate(ApprovalPolicyAsgmDatetGL?.BudgetRevisionDate),
      icon: BudgetRevision,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
    },
    // {
    //   path: `/general-ledger/${CompanyID}/company-setting/approval-policy-asgmt-gl/recurring-journal`,
    //   title: `Recurring Journal`,
    //   label1: `Last updated on`,
    //   data1: formatDate(
    //     CompanySummaryData?.RecurringJournalApprovalPolicyAssignmentDate
    //   ),
    //   // data1: formatDate(ApprovalPolicyAsgmDatetGL?.RecurringJournalDate),
    //   icon: RecurringJournal,
    //   //label2: `Last Created:`,
    //   data2: ``,
    //   data3: ``,
    // },
    // {
    //   path: `/general-ledger/${CompanyID}/company-setting/approval-policy-asgmt-gl/cancel-recurring-journal`,
    //   title: `Cancel Recurring Journal`,
    //   label1: `Last updated on`,
    //   data1: formatDate(
    //     CompanySummaryData?.RecurringJournalApprovalPolicyAssignmentDate
    //   ),
    //   icon: RecurringJournal,
    //   //label2: `Last Created:`,
    //   data2: ``,
    //   data3: ``,
    // },
    // {
    //   path: `/general-ledger/${CompanyID}/company-setting/approval-policy-asgmt-gl/journal-processing`,
    //   title: `Journal Processing`,
    //   label1: `Last updated on`,
    //   data1: formatDate(
    //     CompanySummaryData?.JournalProcessingApprovalPolicyAssignmentDate
    //   ),
    //   icon: JournalProcessing,
    //   //label2: `Last Created:`,
    //   data2: ``,
    //   data3: ``,
    //   //permission:
    // },
    // {
    //   path: `/general-ledger/${CompanyID}/company-setting/approval-policy-asgmt-gl/audit-adjustment`,
    //   title: `Audit Adjustment`,
    //   label1: `Last updated on`,
    //   data1: formatDate(
    //     CompanySummaryData?.AuditAdjustmentApprovalPolicyAssignmentDate
    //   ),
    //   icon: AuditAdjustment,
    //   //label2: `Last Created:`,
    //   data2: ``,
    //   data3: ``,
    //   //permission:
    // },
  ]

  const approvalPolicyAsgmtSubmenuAP = [
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy-asgmt-ap/advance`,
      title: `Advance`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.PaymentDate),
      icon: Payment,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
    },
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy-asgmt-ap/invoice`,
      title: `Invoices`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.ReceiptDate),
      icon: Receipt,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy-asgmt-ap/credit-note`,
      title: `Credit Note`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy-asgmt-ap/debit-note`,
      title: `Debit Note`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy-asgmt-ap/payment`,
      title: `Payment`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-payable/${CompanyID}/company-setting/approval-policy-asgmt-ap/refund`,
      title: `Refund`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
  ]
  const approvalPolicyAsgmtSubmenuAR = [
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/invoice`,
      title: `Invoices`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.PaymentDate),
      icon: Payment,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/credit-note`,
      title: `Credit Note`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/debit-note`,
      title: `Debit Note`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.ReceiptDate),
      icon: Receipt,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/payment`,
      title: `Payment`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/advance`,
      title: `Advance`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/refund`,
      title: `Official Receipt`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/refund`,
      title: `Refund`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/refund`,
      title: `Interest Processing`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
    {
      path: `/account-receivable/${CompanyID}/company-setting/approval-policy-asgmt-ar/refund`,
      title: `Collection Control`,
      label1: `Last updated on`,
      data1: formatDate(ApprovalPolicyAsgmDatetCB?.BankTransferDate),
      icon: BankTransfer,
      //label2: `Last Created:`,
      data2: ``,
      data3: ``,
      //permission:
    },
  ]

  const doCheck = (input: string) => {
    return pageMode === input
  }

  let approvalList = []
  let headerTitle
  let RETURN_ROUTES
  if (doCheck('general-ledger')) {
    approvalList = approvalPolicyAsgmtSubmenuGL
    headerTitle = 'General Ledger'
    RETURN_ROUTES = `/general-ledger/${CompanyID}/company-setting`
  } else if (doCheck('cash-book')) {
    approvalList = approvalPolicyAsgmtSubmenuCB
    headerTitle = 'Cash Book'
    RETURN_ROUTES = `/cash-book/${CompanyID}/company-setting`
  } else if (doCheck('account-payable')) {
    approvalList = approvalPolicyAsgmtSubmenuAP
    headerTitle = 'Accounts Payable'
    RETURN_ROUTES = `/account-payable/${CompanyID}/company-setting`
  } else if (doCheck('account-receivable')) {
    approvalList = approvalPolicyAsgmtSubmenuAR
    headerTitle = 'Accounts Receivable'
    RETURN_ROUTES = `/account-receivable/${CompanyID}/company-setting`
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {GSSummaryLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/${pageMode}/${CompanyID}/company-setting`)
        }
        smTitle={headerTitle}
        title={user?.companyName}
        routeSegments={[
          { name: `Main Menu` },
          { name: `Submenu` },
          { name: 'Standard Approval Flow', current: true },
        ]}
      />
      <DynamicSubHeader
        title={getCompany[0]?.Name} // title text
      />
      <ContentWrapper singleDynamicInfo>
        <List className="core-list">
          {approvalList.map((el, index) => (
            <ListItem key={index} onClick={() => history.push(el.path)}>
              <IconItem image={el.icon} imageType="svg" badgeType="default" />
              <ListItemText
                style={{
                  textAlign: 'right',
                  marginLeft: '5px',
                }}
                primary={<span className="xsTitle">{el.title}</span>}
                secondary={
                  el.label1 ? (
                    <>
                      <span className="desc">
                        <span className="text ">{el.label1}</span>
                        <span className="fw-medium"> {el.data1}.</span>

                        {el.data1 && el.data2 ? <TextSeparator /> : ''}

                        <span
                          className="highlight-text"
                          style={{ marginLeft: '1px' }}
                        >
                          {el.data2}
                        </span>
                        {el.data2 && el.data3 ? <TextSeparator /> : ''}
                        <span
                          className="highlight-text"
                          style={{ marginLeft: '1px' }}
                        >
                          {el.data3}
                        </span>
                      </span>
                    </>
                  ) : null
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <KeyboardArrowRight
                    onClick={() =>
                      history.push({
                        pathname: el.path,
                      })
                    }
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
    </>
  )
}

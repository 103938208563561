export const BankReconExcelForm = [
  {
    header: {
      header: 'DocumentNo',
      width: 17,
    },
  },
  {
    header: {
      header: 'DocumentDate',
      width: 17,
    },
    dataType: 'journalDate',
  },

  {
    header: {
      header: 'Amount',
      width: 15,
    },
    dataType: 'bankReconAmount',
  },

  {
    header: {
      header: 'Remarks',
      width: 40,
    },
  },
]

import PrintIcon from '@material-ui/icons/Print'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import '../APAdvance.scss'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const APAdvanceDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { listEl, documentListing, listStatus, userList, mode } = props

  return (
    <>
      {mode === 'detail' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Date
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(listEl?.DocDate)}
            </div>
          </div>

          <div
            className="content-wrap right"
            // style={{ width: mode === 'expansion' ? '40%' : '50%' }}
          >
            <div className="desc" style={{ fontWeight: 300 }}>
              Reference No.
            </div>
            <span
              style={{ float: 'right' }}
              onClick={() => {
                history.push({
                  pathname: `/account-payable/${CompanyID}/advance/${listEl?.AdvanceID}/preview`,
                  state: {
                    ...listEl,
                    mode: 'detail',
                  },
                })
              }}
            >
              <PrintIcon
                style={{
                  fontSize: 'medium',
                  padding: '0px 10px 2px 0px',
                }}
              />
            </span>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.RefNo}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Creditor Name
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.CreditorAccount?.CompanyName}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Payment Method
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.PaymentMethod?.Name}
            </div>
          </div>

          {listEl?.PaymentMethod?.Name?.includes('Card') && (
            <div className="content-wrap right">
              <div className="desc" style={{ fontWeight: 300 }}>
                Card Type
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.CreditCardType?.Name}
              </div>
            </div>
          )}

          {(listEl?.PaymentMethod?.Name === 'Bank Transfer' ||
            listEl?.PaymentMethod?.Name === 'Cash' ||
            listEl?.PaymentMethod?.Name === 'Contra' ||
            listEl?.PaymentMethod?.Name === 'eWallet' ||
            listEl?.PaymentMethod?.Name === 'QR' ||
            listEl?.PaymentMethod?.Name === 'RENTAS' ||
            listEl?.PaymentMethod?.Name === 'TouchNGo') && (
            <div className="content-wrap right">
              <div className="desc" style={{ fontWeight: 300 }}>
                Bank Account No
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.BankAccount?.AccountNo}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap right">
              <div className="desc" style={{ fontWeight: 300 }}>
                Cheque No.
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.ChequeNo}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap left">
              <div className="desc" style={{ fontWeight: 300 }}>
                Cheque Date
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.ChequeDate)}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap right">
              <div className="desc" style={{ fontWeight: 300 }}>
                Cheque Expiry Date
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.ChequeExpiryDate)}
              </div>
            </div>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Description
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.Description}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Remark
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.Remark}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Amount
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              <img
                src={BlackDollarIcon}
                style={{
                  width: '12px',
                  marginBottom: '-2px',
                  font: 'xxTitle highlight-text',
                }}
              />
              {amtStr(listEl?.Amount)}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ fontWeight: 300 }}>
              Tax
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {`
        ${listEl?.TaxScheme?.Code} 
            (${listEl?.TaxRate}%)
            `}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Tax Amount
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              <img
                src={BlackDollarIcon}
                style={{
                  width: '12px',
                  marginBottom: '-2px',
                  font: 'xxTitle highlight-text',
                }}
              />
              {amtStr(listEl?.TaxAmt)}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ fontWeight: 300 }}>
              Total Amount
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              <img
                src={BlackDollarIcon}
                style={{
                  width: '12px',
                  marginBottom: '-2px',
                  font: 'xxTitle highlight-text',
                }}
              />
              {amtStr(listEl?.DocAmt)}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Attachment
            </div>
            {attachmentDetailView(documentListing)}
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>
        </>
      )}

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}

import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import AppContext from 'containers/App/Store/AppContext'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetLetterTemplateDocument,
  useCreateLetterTemplateMutation,
  useDeleteLetterTemplateMutation,
  useGetLetterTemplateQuery,
  useGetStandardLetterQuery,
  useUpdateLetterTemplateMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export interface LetterTemplateProps {
  Name: string
  JointBuyerSignRequired: boolean
  IsReportDesignEditor: boolean
  IsDefault: boolean
}

export const LetterTemplateListing = (props: any) => {
  const { pageMode, StandardLetterID }: any = useParams()
  let history = useHistory()
  let location: any = useLocation()
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const isHomeDefault = localStorage.getItem('isDefault')
  const [deleteLT, setDeleteLT] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState<boolean>(false)
  const [formMode, setFormMode] = useState('')
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const LetterTemplateFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
  })

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    control,
    setValue,
    setError,
    getValues,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<LetterTemplateProps>({
    defaultValues: {},
    shouldUnregister: false, // unmount input state will be remained
    resolver: yupResolver(LetterTemplateFormSchema),
  })

  const {
    loading: GetStandardLetterloading,
    data: { getStandardLetter } = { getStandardLetter: [] },
  } = useGetStandardLetterQuery({
    fetchPolicy: 'network-only',
    variables: { StandardLetterID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: GetLetterTemplateLoading,
    data: { getLetterTemplate } = { getLetterTemplate: [] },
  } = useGetLetterTemplateQuery({
    fetchPolicy: 'network-only',
    variables: { StandardLetterID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    createLetterTemplate,
    { loading: CreateLetterTemplateLoading },
  ] = useCreateLetterTemplateMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ createLetterTemplate }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      if (createLetterTemplate) {
        setSnackBarMsg('Created Successfully!')
        setOpen(false)
      } else {
        setSnackBarMsg('Create Failed!')
      }
    },
  })

  const [
    updateLetterTemplate,
    { loading: UpdateLetterTemplateLoading },
  ] = useUpdateLetterTemplateMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ updateLetterTemplate }) => {
      if (updateLetterTemplate) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
        setOpen(false)
      } else {
        setSnackBarMsg('Update Failed!')
      }
    },
  })

  const [
    deleteLetterTemplate,
    { loading: DeleteLetterTemplateLoading },
  ] = useDeleteLetterTemplateMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ deleteLetterTemplate }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setDeleteLT(false)
      if (deleteLetterTemplate) {
        setSnackBarMsg('Deleted Successfully!')
        setDeleteLT(false)
      } else {
        setSnackBarMsg('Delete Failed!')
      }
    },
  })

  let headerTitle

  switch (pageMode) {
    case 'general-ledger':
      headerTitle = 'General Ledger'
      break
    case 'cash-book':
      headerTitle = 'Cash Book'
      break
    case 'account-receivable':
      headerTitle = 'Accounts Receivable'
      break
    case 'account-payable':
      headerTitle = 'Accounts Payable'
      break
    case 'inventory-control':
      headerTitle = 'Inventory Control'
      break
    case 'general-purchase':
      headerTitle = 'General Purchase'
      break
  }

  const onSubmit = (data, menu) => {
    if (formMode === 'edit') {
      updateLetterTemplate({
        variables: {
          input: {
            LetterTemplateID: menu?.obj?.LetterTemplateID,
            Name: data?.Name,
            StandardLetterID,
            IsDefault: data?.IsDefault,
          },
        },
        refetchQueries: [
          { query: GetLetterTemplateDocument, variables: { StandardLetterID } },
        ],
      })
    } else if (formMode === 'add') {
      createLetterTemplate({
        variables: {
          input: {
            Name: data?.Name,
            StandardLetterID,
            IsDefault: data?.IsDefault,
          },
        },
        refetchQueries: [
          { query: GetLetterTemplateDocument, variables: { StandardLetterID } },
        ],
      })
    }
  }

  const handleDelete = data => {
    deleteLetterTemplate({
      variables: { input: { LetterTemplateID: data?.LetterTemplateID } },
      refetchQueries: [
        { query: GetLetterTemplateDocument, variables: { StandardLetterID } },
      ],
    })
    setDeleteLT(false)
  }

  const checkSameName = () => {
    const getLT = !menu?.obj
      ? getLetterTemplate
      : getLetterTemplate?.filter(
          x => x?.LetterTemplateID !== menu?.obj?.LetterTemplateID
        )

    const sameName = getLT?.filter(
      el => el?.Name.toLocaleLowerCase() === watch('Name')?.toLocaleLowerCase()
    )

    if (sameName?.length > 0) {
      return true
    } else return false
  }

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: menu?.obj,
      formMode: formMode,
    })

  return (
    <>
      {GetStandardLetterloading && <Loading />}
      {GetLetterTemplateLoading && <Loading />}
      {CreateLetterTemplateLoading && <Loading />}
      {UpdateLetterTemplateLoading && <Loading />}
      {DeleteLetterTemplateLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname: `/${pageMode}/general-setting/standard-letter`,
          })
        }}
        smTitle={headerTitle}
        title={user?.companyName}
        // title={getCompany[0]?.Name}
        routeSegments={
          globalState?.isPathSysAdmin
            ? [
                { name: 'System Admin' },
                { name: 'System Admin' },
                { name: 'Standard Letter' },
                { name: 'Letter Template', current: true },
              ]
            : [
                { name: 'System Admin' },
                { name: 'Standard Letter' },
                { name: 'Letter Template', current: true },
              ]
        }
      />
      <DynamicSubHeader title={getStandardLetter[0]?.Name} />
      <ContentWrapper singleDynamicInfo>
        {getLetterTemplate?.length === 0 ? (
          <EmptyList
            title="No Letter Template"
            subtitle="Please create new Letter Template"
          />
        ) : (
          <List className="core-list">
            {getLetterTemplate?.map((v, index) => (
              <ListItem>
                <ListItemText
                  primary={
                    <div className="xsTitle">
                      <span className="">{v?.Name}</span>
                      {v?.IsDefault === true ? (
                        <span>
                          {' '}
                          <CheckCircleIcon
                            style={{
                              color: 'limegreen',
                              fontSize: 'medium',
                            }}
                          />
                        </span>
                      ) : null}
                    </div>
                  }
                  secondary={
                    <span className="desc">{v?.IsReportDesignEditor}</span>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e => handleClick(e, v?.LetterTemplateID, index, v)}
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </ContentWrapper>
      <CommonDialog
        fullWidth={true}
        open={open}
        onClose={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            setOpen(false)
            reset()
            resetMenu()
          }
        }}
        sections={{
          header: {
            title: 'Letter Template',
            rightText: formMode === 'edit' ? 'Edit' : 'New',
          },
          body: () => (
            <div className="content-container">
              <Controller
                as={TextField}
                id="standard-basic"
                name="Name"
                label={'Name'}
                required
                control={control}
                fullWidth
                helperText={
                  checkSameName() === true
                    ? 'Name already exsist.'
                    : errors?.Name
                    ? errors?.Name.message
                    : null
                }
                error={checkSameName() === true || errors?.Name ? true : false}
                ref={register}
              />

              {/* <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={watch('JointBuyerSignRequired')}
                        onChange={e =>
                          setValue('JointBuyerSignRequired', e.target.checked)
                        }
                        name="JointBuyerSignRequired"
                        color="primary"
                        defaultValue={menu?.obj?.JointBuyerSignRequired}
                      />
                    }
                    label="Joint Buyer Signature"
                  />
                </FormControl>

                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={watch('IsReportDesignEditor')}
                        onChange={e =>
                          setValue('IsReportDesignEditor', e.target.checked)
                        }
                        name="IsReportDesignEditor"
                        color="primary"
                        defaultValue={menu?.obj?.IsReportDesignEditor}
                      />
                    }
                    label="Only allow in Html editor"
                  />
                </FormControl> */}
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watch('IsDefault')}
                      onChange={e => setValue('IsDefault', e.target.checked)}
                      name="IsDefault"
                      color="primary"
                      defaultValue={menu?.obj?.IsDefault}
                    />
                  }
                  label="Default"
                />
              </FormControl>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    if (hasChanges() === true) {
                      setOpenExitConf(true)
                    } else {
                      setOpen(false)
                      reset()
                      resetMenu()
                    }
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleSubmit(data => onSubmit(data, menu))(),
                  variant: 'contained',
                  color: 'primary',
                  disabled: checkSameName(),
                },
              },
            ],
          },
        }}
      />
      <FloatButton
        onClick={() => {
          setValue('Name', '')
          // setValue('JointBuyerSignRequired', false)
          // setValue('IsReportDesignEditor', false)
          setValue('IsDefault', false)
          setOpen(true)
          setFormMode('add')
          resetMenu()
        }}
      />
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          // disabled={handlePermDisabled({
          //   companyID: CompanyID,
          //   permEnum: AcctPermission.AccPayableBatchPaymentUpdate,
          // })}
          onClick={() => {
            history.push({
              pathname: `/${pageMode}/general-setting/standard-letter/${StandardLetterID}/letter-template/${menu?.obj?.LetterTemplateID}/design`,
              state: menu?.obj,
            })
          }}
        >
          <span className="">Design</span>
        </MenuItem>
        <MenuItem
          // disabled={handlePermDisabled({
          //   companyID: CompanyID,
          //   permEnum: AcctPermission.AccPayableBatchPaymentDelete,
          // })}
          onClick={() => {
            setValue('Name', menu?.obj?.Name)
            // setValue(
            //   'JointBuyerSignRequired',
            //   menu?.obj?.JointBuyerSignRequired
            // )
            // setValue('IsReportDesignEditor', menu?.obj?.IsReportDesignEditor)
            setValue('IsDefault', menu?.obj?.IsDefault)
            setOpen(true)
            setFormMode('edit')
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          disabled={menu?.obj?.IsDefault}
          onClick={() => setDeleteLT(true)}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>
      <CommonDialog
        fullWidth
        open={deleteLT}
        onClose={() => setDeleteLT(false)}
        sections={{
          header: {
            title: 'Letter Template',
            rightText: `Delete`,
          },
          body: () => (
            <div className="content-wrap full">
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: 500,
                }}
              >
                {`Are you sure you want to delete ${menu?.obj?.Name}?`}
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setDeleteLT(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleDelete(menu?.obj)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          setOpen(false)
          setOpenExitConf(false)
          reset()
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader2RowInfo from '@ifca-root/react-component/src/components/Header/DynamicSubHeader2RowInfo'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  useGetBankReconciliationItemLazyQuery,
  useGetLedgerLazyQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const BankReconciliationItemDetail = (props: any) => {
  let history = useHistory()
  const { formMode } = props
  let location = useLocation()
  const editData = location?.state as any
  const {
    CompanyID,
    BankAccountID,
    BankReconciliationID,
    BankReconciliationItemID,
    LedgerID,
    backpathmode,
  }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [detailData, setDetailData] = useState(null)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  //QUERY

  const [
    loadBankStatement,
    {
      loading: BankReconciliationItemLoading,
      data: { getBankReconciliationItem } = { getBankReconciliationItem: [] },
    },
  ] = useGetBankReconciliationItemLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getBankReconciliationItem }) => {
      if (getBankReconciliationItem?.length > 0) {
        setDetailData(getBankReconciliationItem[0])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadLedger,
    { loading: ledgerLoading, data: { getLedger } = { getLedger: [] } },
  ] = useGetLedgerLazyQuery({
    fetchPolicy: 'network-only',
    variables: { LedgerID: LedgerID },
    onCompleted: ({ getLedger }) => {
      if (getLedger?.length > 0) {
        setDetailData(getLedger[0])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  useEffect(() => {
    if (formMode === 'bank-statement') {
      loadBankStatement({
        variables: {
          BankReconciliationItemID: BankReconciliationItemID,
        },
      })
    } else {
      loadLedger({
        variables: {
          LedgerID: LedgerID,
        },
      })
    }
  }, [formMode])

  return (
    <>
      {ledgerLoading && <Loading />}
      {BankReconciliationItemLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation/${BankReconciliationID}/${backpathmode}`
          )
        }}
        smTitle="Cash Book"
        title={user?.companyName}
        routeSegments={[
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details', current: true },
        ]}
      />

      <DynamicSubHeader2RowInfo
        title={<span style={{ color: 'orange' }}>{detailData?.DocNo}</span>} // title text
      />
      <ContentWrapper singleDynamicInfo>
        <CardContents>
          {formMode === 'cash-book-ledger' && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Document Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(detailData?.DocDate)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Transaction Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(detailData?.TransactionDate)}
                </div>
              </div>
              <div className="content-wrap left ">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Ref No.
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.RefNo}
                </div>
              </div>
              <div className="content-wrap right ">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Ledger Type
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.LedgerType}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Debit/Credit
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.TrxType}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(detailData?.DocAmt)}
                </div>
              </div>
              {!!detailData?.PayeeName && (
                <div className="content-wrap full">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Pay To
                  </div>
                  <div
                    className="xsTitle text-noflow"
                    style={{ fontWeight: 500 }}
                  >
                    {detailData?.PayeeName}
                  </div>
                </div>
              )}
              {!!detailData?.ReceivedFrom && (
                <div className="content-wrap full">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Received From
                  </div>
                  <div
                    className="xsTitle text-noflow"
                    style={{ fontWeight: 500 }}
                  >
                    {detailData?.ReceivedFrom}
                  </div>
                </div>
              )}
              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Description
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {detailData?.Description ?? detailData?.Remark}
                </div>
              </div>
            </>
          )}
          {formMode === 'bank-statement' && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Document Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(detailData?.DocumentDate)}
                </div>
              </div>
              <div className="content-wrap right ">
                <div
                  className="desc"
                  style={{
                    fontWeight: 300,
                    width: '50%',
                  }}
                >
                  Document No.
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.DocNo}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Description
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {detailData?.Remarks}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(detailData?.DocAmt)}
                </div>
              </div>
            </>
          )}
        </CardContents>
      </ContentWrapper>

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import MenuIcon from '@material-ui/icons/Menu'
import { TreeView } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import {
  CustomTreeItem,
  DotIcon,
  MinusSquare,
  PlusSquare,
} from 'containers/GeneralSettingModule/MasterCOA/RoleTreeStyle'
import {
  useGetBudgetCostCentreQuery,
  useGetCompanyNameQuery,
  useGetCostCentreQuery,
  useGetMasterCoaQuery,
  useGetParentCostCentreForTreeLazyQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

interface GLCostCentreBudgetProps {
  BudgetAmount: number
  CostCentreBudgetAmountArr: any
  Amount: number
}

export const GLCostCentreBudget = (props: any) => {
  let history = useHistory()
  const { CompanyID, MasterCOAID, budgetType }: any = useParams()
  const { mode } = props
  let location = useLocation()
  const masterCOAData: any = location?.state
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [expandArray, setExpandArray] = useState([])
  let CostCentreBudgetItem = JSON.parse(
    localStorage.getItem('CostCentreBudgetItem')
  )
  let PeriodBudgetItem = JSON.parse(localStorage.getItem('PeriodBudgetItem'))
  let BudgetItem = JSON.parse(localStorage.getItem('BudgetItem'))
  const [CCDataWParent, setCCDataWParent] = useState([])
  const [openExitConf, setOpenExitConf] = useState(false)
  const [openProceedConf, setOpenProceedConf] = useState(false)
  const [proceedAction, setProceedAction] = useState<string>('')
  const [coaAccType, setCoaAccType] = useState('')
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<GLCostCentreBudgetProps>({
    defaultValues: {},
    mode: 'onSubmit',
    //resolver: yupResolver(GLBudgetFormSchema),
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: MasterCOALoading,
    error: MasterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      MasterCOAID: MasterCOAID,
    },
  })

  const {
    loading: getCostCentreLoading,
    data: { getCostCentre } = { getCostCentre: [] },
    // refetch: getCostCentreRefetch,
    // error: getCostCentreError,
  } = useGetCostCentreQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: {
      CompanyID: CompanyID,
      MasterCOAID: MasterCOAID,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.getCostCentre.length > 0) {
        CostCentreBudgetItem?.map(x => {
          setValue(
            `CostCentreBudgetAmountArr[${x?.CostCentreID}]`,
            x?.CostCentreBudgetAmount
          )
        })
      }
    },
  })

  const [
    fetchParentNode,
    {
      loading: getParentCostCentreForTreeLoading,
      data: { getParentCostCentreForTree } = { getParentCostCentreForTree: [] },
    },
  ] = useGetParentCostCentreForTreeLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {},
  })

  const {
    loading: BudgetCostCentreLoading,
    error: BudgetCostCentreError,
    data: { getBudgetCostCentre } = { getBudgetCostCentre: [] },
  } = useGetBudgetCostCentreQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onCompleted: ({ getBudgetCostCentre }) => {
      if (getBudgetCostCentre.CostCentreBudgetTree?.length === 0) {
        setOriginalListing([])
      } else {
        setOriginalListing(getBudgetCostCentre.CostCentreBudgetTree)
      }
    },
  })

  // useEffect(() => {
  //   if (
  //     (getCostCentre === undefined || getCostCentre?.length === 0) &&
  //     (getCompany !== undefined || getCompany?.length !== 0)
  //   ) {
  //     setOriginalListing([])
  //   } else {
  //     let initialArr = []
  //     let ArrayWCompany = []
  //     let parent = getCostCentre.filter(v => v.ParentCostCentreID === null)

  //     let level = getCostCentre.map(v => v.Level).sort((a, b) => b - a)[0]
  //     // parent.map((el, index) => {
  //     //   return
  //     initializeLevel(1, getCostCentre, initialArr, level, level)
  //     // })

  //     /// FILTER IF HAVE CHILD////
  //     const childEmpty = initialArr?.filter(x => x?.child?.length === 0)
  //     const AllArray = initialArr
  //       ?.filter(x => x?.child?.length > 0)
  //       .concat(childEmpty)

  //     setOriginalListing(initialArr)
  //     if (getCompany !== undefined || getCompany?.length !== 0)
  //       setExpandArray(ArrayWCompany.concat(getCostCentre))
  //   }
  // }, [getCostCentre, getCompany])

  // const initializeLevel = (level, data, arr, maxLevel, prtID) => {
  //   const dataToPush = data
  //     ?.filter(v => {
  //       if (v.ParentCostCentreID !== null) {
  //         return v.Level === level && v.ParentCostCentreID === prtID
  //       } else {
  //         return v.Level === level
  //       }
  //     })
  //     .map(x => {
  //       return {
  //         Name: x?.Name,
  //         CostCentreID: x?.CostCentreID,
  //         ParentCostCentreID: x?.ParentCostCentreID,
  //         Level: x?.Level,
  //         CostCentreDefName: x?.CostCentreDefinitionDetail?.Name,
  //         IsLastNode: x?.IsLastNode,
  //         CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
  //         data: {
  //           //AuditAdjustmentItem: x?.AuditAdjustmentItem,
  //           FavoriteJournalItem: x?.FavoriteJournalItem,
  //           JournalItem: x?.JournalItem,
  //           //RecurringJournalItem: x?.RecurringJournalItem,
  //           JournalProcessingItem: x?.JournalProcessingItem,
  //           BudgetCostCentreItem: x?.BudgetCostcentreItem,
  //           //RoutineJournalItem: x?.RoutineJournalItem,
  //         },
  //         child: [],
  //       }
  //     })

  //   arr.push(...dataToPush)

  //   if (level < maxLevel) {
  //     arr.map(k => {
  //       initializeLevel(level + 1, data, k.child, maxLevel, k.CostCentreID)
  //     })
  //   }
  // }

  const PassedData = () => {
    let CostCentreBudgetItemData = []
    const filterCostCentreBudgetItem = CostCentreBudgetItem?.filter(
      x => x?.MasterCOAID !== masterCOAData?.ID
    )
    const filterGetCostCentre = getBudgetCostCentre.CostCentreData?.filter(
      x => x?.IsLastNode === true
    )

    if (filterCostCentreBudgetItem !== undefined) {
      CostCentreBudgetItemData.push(...filterCostCentreBudgetItem)
    }

    const currentperiod = filterGetCostCentre?.map(x => {
      return {
        BudgetItemID: BudgetItem?.find(
          el => el?.MasterCOAID === masterCOAData?.ID
        ).BudgetItemID,
        BudgetItemCostCentreID: !!CostCentreBudgetItem?.find(
          el =>
            el?.MasterCOAID === masterCOAData?.ID &&
            el?.CostCentreID === x?.CostCentreID
        )
          ? CostCentreBudgetItem?.find(
              el =>
                el?.MasterCOAID === masterCOAData?.ID &&
                el?.CostCentreID === x?.CostCentreID
            )?.BudgetItemCostCentreID
          : uuidv4(),
        MasterCOAID: masterCOAData?.ID,
        Code: masterCOAData?.code,
        BudgetAmount: masterCOAData?.budgetAmount,
        CostCentreID: x?.CostCentreID,
        CostCentreBudgetAmount:
          getValues(`CostCentreBudgetAmountArr[${x?.CostCentreID}].Amount`) ===
          undefined
            ? CostCentreBudgetItem?.find(
                v =>
                  v?.CostCentreID === x?.CostCentreID &&
                  v?.MasterCOAID === masterCOAData?.ID
              )?.CostCentreBudgetAmount
            : getValues(`CostCentreBudgetAmountArr[${x?.CostCentreID}].Amount`),
        CostCentreCode: x?.Code,
      }
    })

    CostCentreBudgetItemData.push(
      ...currentperiod?.filter(x => !!x?.CostCentreBudgetAmount),
      ...currentperiod?.filter(
        x =>
          !x?.CostCentreBudgetAmount &&
          PeriodBudgetItem?.filter(
            el => el?.BudgetItemCostCentreID === x?.BudgetItemCostCentreID
          )?.length > 0
      )
    )

    return CostCentreBudgetItemData
  }

  const [reRender, seRerender] = useState(false)

  useEffect(() => {
    if (
      !getCostCentreLoading &&
      !getParentCostCentreForTreeLoading &&
      CostCentreBudgetItem?.length > 0 &&
      watch('CostCentreBudgetAmountArr') !== undefined
    ) {
      seRerender(true)
    }
  }, [
    getCostCentreLoading,
    getParentCostCentreForTreeLoading,
    BudgetItem,
    watch,
  ])

  useEffect(() => {
    if (CostCentreBudgetItem?.length > 0) {
      fetchParentNode({
        variables: {
          CostCentreArr: CostCentreBudgetItem?.filter(
            x => x?.MasterCOAID === masterCOAData?.ID
          )?.map(x => x?.CostCentreID),
        },
      })
    }
  }, [])

  useEffect(() => {
    if (getMasterCOA?.length > 0) {
      setCoaAccType(getMasterCOA[0]?.AccountType)
    }
  })

  const CCBudgetAmtChecking = () => {
    const totalChildAmt = getBudgetCostCentre.CostCentreData?.reduce(
      (a, b) =>
        a +
        Number(
          amtNumStr(
            watch(`CostCentreBudgetAmountArr[${b?.CostCentreID}].Amount`)
          )
        ),
      0
    )

    const BudgetAmount =
      masterCOAData?.budgetAmount !== null ? masterCOAData?.budgetAmount : '0'

    if (Number(amtNumStr(BudgetAmount)) >= 0) {
      if (totalChildAmt > Number(amtNumStr(BudgetAmount))) return true
      else return false
    } else {
      if (totalChildAmt < Number(amtNumStr(BudgetAmount))) return true
      else return false
    }
  }

  const handleDisabled = (accountType, costCentre) => {
    if (
      accountType === 'ASSET' ||
      accountType === 'LIABILITY' ||
      accountType === 'EQUITY'
    ) {
      if (costCentre !== getCompany[0]?.DefaultCostCentre?.CostCentreID)
        return true
      else return false
    } else return false
  }

  ////TREE VIEW////

  const renderTree = (nodes, index) => {
    const CostCentreBudgetAmountArr = `CostCentreBudgetAmountArr[${nodes?.CostCentreID}]`

    return (
      <CustomTreeItem
        key={nodes.CostCentreID}
        nodeId={nodes.CostCentreID}
        // unCollapse={
        //   mode === 'add' || mode === 'edit'
        //     ? getParentCostCentreForTree?.map(x => x?.CostCentreID)
        //     : null
        // }
        label={
          <>
            <Box
              p={0}
              pr={0}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: nodes?.IsLastNode === true ? null : '20px',
                marginBottom: nodes?.IsLastNode === true ? null : '20px',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: 'inherit',
                  flexGrow: 1,
                  width: '50px',
                  maxHeight: '50.25px',
                }}
              >
                <div className="xsTitle text-noflow"> {nodes.Name}</div>

                {/* <span
              //</Typography>className="xsTitle flex-space"
              >{`${nodes.code} | ${nodes.name}`}</span> */}
              </Typography>

              {/* {coaAccType !== '' &&
              (coaAccType === 'ASSET' ||
                coaAccType === 'LIABILITY' ||
                coaAccType === 'EQUITY') ? (
                nodes?.IsLastNode === true &&
                handleDisabled(
                  getMasterCOA[0]?.AccountType,
                  nodes?.CostCentreID
                ) === false ? (
                  <>
                    <Typography variant="caption" color="inherit">
                      {mode === 'approve-reject' || mode === 'detail' ? (
                        <div className="xsTitle">
                          {
                            CostCentreBudgetItem?.filter(
                              x => x?.MasterCOAID === masterCOAData?.ID
                            )?.find(
                              x => x?.CostCentreID === nodes?.CostCentreID
                            )?.CostCentreBudgetAmount
                          }
                        </div>
                      ) : (
                        <Controller
                          ref={register()}
                          name={`${CostCentreBudgetAmountArr}.Amount`}
                          control={control}
                          //defaultValue={v.DocAmt || 0}
                          onChangeName="onValueChange"
                          onValueChange={e => {
                            e.value === '' || e.value === '-'
                              ? setValue(
                                  `${CostCentreBudgetAmountArr}.Amount`,
                                  null
                                )
                              : setValue(
                                  `${CostCentreBudgetAmountArr}.Amount`,
                                  e.value
                                )

                            if (
                              BudgetItem?.find(
                                el => el?.MasterCOAID === masterCOAData?.ID
                              ) !== undefined
                            ) {
                              localStorage.setItem(
                                'CostCentreBudgetItem',
                                JSON.stringify(PassedData())
                              )
                            }
                          }}
                          defaultValue={
                            !!CostCentreBudgetItem?.filter(
                              x => x?.MasterCOAID === masterCOAData?.ID
                            )?.find(
                              x => x?.CostCentreID === nodes?.CostCentreID
                            )
                              ? CostCentreBudgetItem?.filter(
                                  x => x?.MasterCOAID === masterCOAData?.ID
                                )?.find(
                                  x => x?.CostCentreID === nodes?.CostCentreID
                                )?.CostCentreBudgetAmount
                              : null
                          }
                          error={
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              '' ||
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              undefined
                              ? null
                              : watch(`${CostCentreBudgetAmountArr}.Amount`) !==
                                null
                              ? CCBudgetAmtChecking()
                              : null
                          }
                          helperText={
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              '' ||
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              undefined ? null : watch(
                                `${CostCentreBudgetAmountArr}.Amount`
                              ) !== null ? (
                              CCBudgetAmtChecking() === true ? (
                                <div
                                  className="desc text-noflow"
                                  style={{ width: '80px' }}
                                >
                                  Cannot exceed budget amount
                                </div>
                              ) : null
                            ) : null
                          }
                          as={
                            <NumberFormat
                              key={nodes.CostCentreID}
                              allowNegative={true}
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale
                              variant="outlined"
                              className="qty-input-new-wid"
                              autoComplete="off"
                              thousandSeparator
                              isNumericString
                              //label="Journal Amount *"

                              margin="dense"
                              InputProps={{
                                style: {
                                  width: '100px',
                                },
                              }}
                            />
                          }
                        />
                      )}
                    </Typography>

                    <Typography variant="caption" color="inherit">
                      {(mode === 'approve-reject' || mode === 'detail') &&
                      !CostCentreBudgetItem?.filter(
                        x => x?.MasterCOAID === masterCOAData?.ID
                      )?.find(x => x?.CostCentreID === nodes?.CostCentreID)
                        ?.CostCentreBudgetAmount ? (
                        <IconButton>
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              color: 'white',
                              //backgroundColor: 'white',
                              //borderRadius: '3px',
                            }}
                          ></div>
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={
                            nodes?.CostCentreID !==
                            getCompany[0]?.DefaultCostCentre?.CostCentreID
                              ? true
                              : false
                          }
                          className="first-btn"
                          onClick={e => {
                            // if (CCBudgetAmtChecking() === false) {
                            // if (
                            //   (watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            //     null ||
                            //     watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            //       undefined ||
                            //     watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            //       '') &&
                            //   (mode === 'add' || mode === 'edit')
                            // ) {
                            //   localStorage.setItem(
                            //     'CostCentreBudgetItem',
                            //     JSON.stringify(PassedData())
                            //   )
                            // } else {
                            if (
                              BudgetItem?.find(
                                el => el?.MasterCOAID === masterCOAData?.ID
                              ) !== undefined
                            ) {
                              localStorage.setItem(
                                'CostCentreBudgetItem',
                                JSON.stringify(PassedData())
                              )

                              // localStorage.setItem(
                              //   'PeriodBudgetItem',
                              //   JSON.stringify(
                              //     updateEmptyPeriodItem(
                              //       PassedData()?.filter(
                              //         x => x?.CostCentreBudgetAmount !== ''
                              //       ),
                              //       PeriodBudgetItem
                              //     )
                              //   )
                              // )
                            }

                            if (
                              watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                                null ||
                              watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                                '' ||
                              watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                                undefined
                            ) {
                              if (
                                mode === 'detail' &&
                                PeriodBudgetItem?.filter(
                                  x =>
                                    x.BudgetItemCostCentreID ===
                                    CostCentreBudgetItem?.filter(
                                      x => x?.MasterCOAID === masterCOAData?.ID
                                    )?.find(
                                      x =>
                                        x?.CostCentreID === nodes?.CostCentreID
                                    )?.BudgetItemCostCentreID
                                ).length > 0
                              ) {
                                history.push({
                                  pathname: `/general-ledger/${CompanyID}/${budgetType}/${masterCOAData?.ID}/${nodes.CostCentreID}/${mode}`,
                                  state: {
                                    MasterCOAData: masterCOAData,
                                    CostCentreData: nodes,
                                    PrevPItem:
                                      BudgetItem?.find(
                                        el =>
                                          el?.MasterCOAID === masterCOAData?.ID
                                      ) !== undefined
                                        ? updateEmptyPeriodItem(
                                            PassedData(),
                                            PeriodBudgetItem
                                          )
                                        : updateEmptyPeriodItem(
                                            [],
                                            PeriodBudgetItem
                                          ),
                                    ParentCostCentreData: getBudgetCostCentre.CostCentreData?.find(
                                      x =>
                                        x.CostCentreID ===
                                        nodes?.ParentCostCentreID
                                    ),
                                    CostCentreBudgetAmount:
                                      mode === 'approve-reject' ||
                                      mode === 'detail'
                                        ? CostCentreBudgetItem?.filter(
                                            x =>
                                              x?.MasterCOAID ===
                                              masterCOAData?.ID
                                          )?.find(
                                            x =>
                                              x?.CostCentreID ===
                                              nodes?.CostCentreID
                                          )?.CostCentreBudgetAmount
                                        : getValues(
                                            `${CostCentreBudgetAmountArr}.Amount`
                                          ),
                                  },
                                })
                              }
                            } else {
                              history.push({
                                pathname: `/general-ledger/${CompanyID}/${budgetType}/${masterCOAData?.ID}/${nodes.CostCentreID}/${mode}`,
                                state: {
                                  MasterCOAData: masterCOAData,
                                  CostCentreData: nodes,
                                  PrevPItem:
                                    BudgetItem?.find(
                                      el =>
                                        el?.MasterCOAID === masterCOAData?.ID
                                    ) !== undefined
                                      ? updateEmptyPeriodItem(
                                          PassedData(),
                                          PeriodBudgetItem
                                        )
                                      : updateEmptyPeriodItem(
                                          [],
                                          PeriodBudgetItem
                                        ),
                                  ParentCostCentreData: getBudgetCostCentre.CostCentreData?.find(
                                    x =>
                                      x.CostCentreID ===
                                      nodes?.ParentCostCentreID
                                  ),
                                  CostCentreBudgetAmount:
                                    mode === 'approve-reject' ||
                                    mode === 'detail'
                                      ? CostCentreBudgetItem?.filter(
                                          x =>
                                            x?.MasterCOAID === masterCOAData?.ID
                                        )?.find(
                                          x =>
                                            x?.CostCentreID ===
                                            nodes?.CostCentreID
                                        )?.CostCentreBudgetAmount
                                      : getValues(
                                          `${CostCentreBudgetAmountArr}.Amount`
                                        ),
                                },
                              })
                            }
                            // }
                            // }
                          }}
                        >
                          <IconItem
                            icon={
                              <MenuIcon
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  color: 'white',
                                  backgroundColor:
                                    watch(
                                      `${CostCentreBudgetAmountArr}.Amount`
                                    ) === null ||
                                    watch(
                                      `${CostCentreBudgetAmountArr}.Amount`
                                    ) === '' ||
                                    watch(
                                      `${CostCentreBudgetAmountArr}.Amount`
                                    ) === undefined
                                      ? mode === 'detail' &&
                                        PeriodBudgetItem?.filter(
                                          x =>
                                            x.BudgetItemCostCentreID ===
                                            CostCentreBudgetItem?.filter(
                                              x =>
                                                x?.MasterCOAID ===
                                                masterCOAData?.ID
                                            )?.find(
                                              x =>
                                                x?.CostCentreID ===
                                                nodes?.CostCentreID
                                            )?.BudgetItemCostCentreID
                                        ).length > 0
                                        ? '#FF9800'
                                        : '#938D8B'
                                      : '#FF9800',
                                  borderRadius: '3px',
                                }}
                              />
                            }
                            //imageType="svg"
                            badgeType="number"
                            badgeNumber={
                              PeriodBudgetItem?.filter(
                                x =>
                                  x.BudgetItemCostCentreID ===
                                  CostCentreBudgetItem?.filter(
                                    x => x?.MasterCOAID === masterCOAData?.ID
                                  )?.find(
                                    x => x?.CostCentreID === nodes?.CostCentreID
                                  )?.BudgetItemCostCentreID
                              ).length > 0 ? (
                                <span
                                  style={{
                                    width: '15px',
                                    height: '12px',
                                    backgroundColor: 'orange',
                                    fontSize: '10px',
                                    color: 'black',
                                    borderRadius: '20px',
                                    paddingTop: '3px',
                                  }}
                                >
                                  {
                                    PeriodBudgetItem?.filter(
                                      x =>
                                        x.BudgetItemCostCentreID ===
                                        CostCentreBudgetItem?.filter(
                                          x =>
                                            x?.MasterCOAID === masterCOAData?.ID
                                        )?.find(
                                          x =>
                                            x?.CostCentreID ===
                                            nodes?.CostCentreID
                                        )?.BudgetItemCostCentreID
                                    ).length
                                  }
                                </span>
                              ) : null
                            }
                          />
                        </IconButton>
                      )}
                    </Typography>
                  </>
                ) : null
              ) : coaAccType !== '' &&
                (coaAccType === 'EXPENSE' || coaAccType === 'REVENUE') ? (
                nodes?.IsLastNode === true &&
                !!getCostCentre?.find(
                  v => v?.CostCentreID === nodes?.CostCentreID
                ) ? (
                  <>
                    <Typography variant="caption" color="inherit">
                      {mode === 'approve-reject' || mode === 'detail' ? (
                        <div className="xsTitle">
                          {
                            CostCentreBudgetItem?.filter(
                              x => x?.MasterCOAID === masterCOAData?.ID
                            )?.find(
                              x => x?.CostCentreID === nodes?.CostCentreID
                            )?.CostCentreBudgetAmount
                          }
                        </div>
                      ) : (
                        <Controller
                          ref={register()}
                          name={`${CostCentreBudgetAmountArr}.Amount`}
                          control={control}
                          //defaultValue={v.DocAmt || 0}
                          onChangeName="onValueChange"
                          onValueChange={e => {
                            e.value === '' || e.value === '-'
                              ? setValue(
                                  `${CostCentreBudgetAmountArr}.Amount`,
                                  null
                                )
                              : setValue(
                                  `${CostCentreBudgetAmountArr}.Amount`,
                                  e.value
                                )

                            if (
                              BudgetItem?.find(
                                el => el?.MasterCOAID === masterCOAData?.ID
                              ) !== undefined
                            ) {
                              localStorage.setItem(
                                'CostCentreBudgetItem',
                                JSON.stringify(PassedData())
                              )
                            }
                          }}
                          defaultValue={
                            !!CostCentreBudgetItem?.filter(
                              x => x?.MasterCOAID === masterCOAData?.ID
                            )?.find(
                              x => x?.CostCentreID === nodes?.CostCentreID
                            )
                              ? CostCentreBudgetItem?.filter(
                                  x => x?.MasterCOAID === masterCOAData?.ID
                                )?.find(
                                  x => x?.CostCentreID === nodes?.CostCentreID
                                )?.CostCentreBudgetAmount
                              : null
                          }
                          error={
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              '' ||
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              undefined
                              ? null
                              : watch(`${CostCentreBudgetAmountArr}.Amount`) !==
                                null
                              ? CCBudgetAmtChecking()
                              : null
                          }
                          helperText={
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              '' ||
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              undefined ? null : watch(
                                `${CostCentreBudgetAmountArr}.Amount`
                              ) !== null ? (
                              CCBudgetAmtChecking() === true ? (
                                <div
                                  className="desc text-noflow"
                                  style={{ width: '80px' }}
                                >
                                  Cannot exceed budget amount
                                </div>
                              ) : null
                            ) : null
                          }
                          as={
                            <NumberFormat
                              key={nodes.CostCentreID}
                              allowNegative={true}
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale
                              variant="outlined"
                              className="qty-input-new-wid"
                              autoComplete="off"
                              thousandSeparator
                              isNumericString
                              //label="Journal Amount *"

                              margin="dense"
                              InputProps={{
                                style: {
                                  width: '100px',
                                },
                              }}
                            />
                          }
                        />
                      )}
                    </Typography>

                    <Typography variant="caption" color="inherit">
                      {(mode === 'approve-reject' || mode === 'detail') &&
                      !CostCentreBudgetItem?.filter(
                        x => x?.MasterCOAID === masterCOAData?.ID
                      )?.find(x => x?.CostCentreID === nodes?.CostCentreID)
                        ?.CostCentreBudgetAmount ? (
                        <IconButton>
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              color: 'white',
                              //backgroundColor: 'white',
                              //borderRadius: '3px',
                            }}
                          ></div>
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={
                            nodes?.CostCentreID !==
                            getCompany[0]?.DefaultCostCentre?.CostCentreID
                              ? true
                              : false
                          }
                          className="first-btn"
                          onClick={e => {
                            // if (CCBudgetAmtChecking() === false) {
                            // if (
                            //   (watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            //     null ||
                            //     watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            //       undefined ||
                            //     watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            //       '') &&
                            //   (mode === 'add' || mode === 'edit')
                            // ) {
                            //   localStorage.setItem(
                            //     'CostCentreBudgetItem',
                            //     JSON.stringify(PassedData())
                            //   )
                            // } else {
                            if (
                              BudgetItem?.find(
                                el => el?.MasterCOAID === masterCOAData?.ID
                              ) !== undefined
                            ) {
                              localStorage.setItem(
                                'CostCentreBudgetItem',
                                JSON.stringify(PassedData())
                              )

                              // localStorage.setItem(
                              //   'PeriodBudgetItem',
                              //   JSON.stringify(
                              //     updateEmptyPeriodItem(
                              //       PassedData()?.filter(
                              //         x => x?.CostCentreBudgetAmount !== ''
                              //       ),
                              //       PeriodBudgetItem
                              //     )
                              //   )
                              // )
                            }

                            if (
                              watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                                null ||
                              watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                                '' ||
                              watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                                undefined
                            ) {
                              if (
                                mode === 'detail' &&
                                PeriodBudgetItem?.filter(
                                  x =>
                                    x.BudgetItemCostCentreID ===
                                    CostCentreBudgetItem?.filter(
                                      x => x?.MasterCOAID === masterCOAData?.ID
                                    )?.find(
                                      x =>
                                        x?.CostCentreID === nodes?.CostCentreID
                                    )?.BudgetItemCostCentreID
                                ).length > 0
                              ) {
                                history.push({
                                  pathname: `/general-ledger/${CompanyID}/${budgetType}/${masterCOAData?.ID}/${nodes.CostCentreID}/${mode}`,
                                  state: {
                                    MasterCOAData: masterCOAData,
                                    CostCentreData: nodes,
                                    PrevPItem:
                                      BudgetItem?.find(
                                        el =>
                                          el?.MasterCOAID === masterCOAData?.ID
                                      ) !== undefined
                                        ? updateEmptyPeriodItem(
                                            PassedData(),
                                            PeriodBudgetItem
                                          )
                                        : updateEmptyPeriodItem(
                                            [],
                                            PeriodBudgetItem
                                          ),
                                    ParentCostCentreData: getBudgetCostCentre.CostCentreData?.find(
                                      x =>
                                        x.CostCentreID ===
                                        nodes?.ParentCostCentreID
                                    ),
                                    CostCentreBudgetAmount:
                                      mode === 'approve-reject' ||
                                      mode === 'detail'
                                        ? CostCentreBudgetItem?.filter(
                                            x =>
                                              x?.MasterCOAID ===
                                              masterCOAData?.ID
                                          )?.find(
                                            x =>
                                              x?.CostCentreID ===
                                              nodes?.CostCentreID
                                          )?.CostCentreBudgetAmount
                                        : getValues(
                                            `${CostCentreBudgetAmountArr}.Amount`
                                          ),
                                  },
                                })
                              }
                            } else {
                              history.push({
                                pathname: `/general-ledger/${CompanyID}/${budgetType}/${masterCOAData?.ID}/${nodes.CostCentreID}/${mode}`,
                                state: {
                                  MasterCOAData: masterCOAData,
                                  CostCentreData: nodes,
                                  PrevPItem:
                                    BudgetItem?.find(
                                      el =>
                                        el?.MasterCOAID === masterCOAData?.ID
                                    ) !== undefined
                                      ? updateEmptyPeriodItem(
                                          PassedData(),
                                          PeriodBudgetItem
                                        )
                                      : updateEmptyPeriodItem(
                                          [],
                                          PeriodBudgetItem
                                        ),
                                  ParentCostCentreData: getBudgetCostCentre.CostCentreData?.find(
                                    x =>
                                      x.CostCentreID ===
                                      nodes?.ParentCostCentreID
                                  ),
                                  CostCentreBudgetAmount:
                                    mode === 'approve-reject' ||
                                    mode === 'detail'
                                      ? CostCentreBudgetItem?.filter(
                                          x =>
                                            x?.MasterCOAID === masterCOAData?.ID
                                        )?.find(
                                          x =>
                                            x?.CostCentreID ===
                                            nodes?.CostCentreID
                                        )?.CostCentreBudgetAmount
                                      : getValues(
                                          `${CostCentreBudgetAmountArr}.Amount`
                                        ),
                                },
                              })
                            }
                            // }
                            // }
                          }}
                        >
                          <IconItem
                            icon={
                              <MenuIcon
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  color: 'white',
                                  backgroundColor:
                                    watch(
                                      `${CostCentreBudgetAmountArr}.Amount`
                                    ) === null ||
                                    watch(
                                      `${CostCentreBudgetAmountArr}.Amount`
                                    ) === '' ||
                                    watch(
                                      `${CostCentreBudgetAmountArr}.Amount`
                                    ) === undefined
                                      ? mode === 'detail' &&
                                        PeriodBudgetItem?.filter(
                                          x =>
                                            x.BudgetItemCostCentreID ===
                                            CostCentreBudgetItem?.filter(
                                              x =>
                                                x?.MasterCOAID ===
                                                masterCOAData?.ID
                                            )?.find(
                                              x =>
                                                x?.CostCentreID ===
                                                nodes?.CostCentreID
                                            )?.BudgetItemCostCentreID
                                        ).length > 0
                                        ? '#FF9800'
                                        : '#938D8B'
                                      : '#FF9800',
                                  borderRadius: '3px',
                                }}
                              />
                            }
                            //imageType="svg"
                            badgeType="number"
                            badgeNumber={
                              PeriodBudgetItem?.filter(
                                x =>
                                  x.BudgetItemCostCentreID ===
                                  CostCentreBudgetItem?.filter(
                                    x => x?.MasterCOAID === masterCOAData?.ID
                                  )?.find(
                                    x => x?.CostCentreID === nodes?.CostCentreID
                                  )?.BudgetItemCostCentreID
                              ).length > 0 ? (
                                <span
                                  style={{
                                    width: '15px',
                                    height: '12px',
                                    backgroundColor: 'orange',
                                    fontSize: '10px',
                                    color: 'black',
                                    borderRadius: '20px',
                                    paddingTop: '3px',
                                  }}
                                >
                                  {
                                    PeriodBudgetItem?.filter(
                                      x =>
                                        x.BudgetItemCostCentreID ===
                                        CostCentreBudgetItem?.filter(
                                          x =>
                                            x?.MasterCOAID === masterCOAData?.ID
                                        )?.find(
                                          x =>
                                            x?.CostCentreID ===
                                            nodes?.CostCentreID
                                        )?.BudgetItemCostCentreID
                                    ).length
                                  }
                                </span>
                              ) : null
                            }
                          />
                        </IconButton>
                      )}
                    </Typography>
                  </>
                ) : null
              ) : null} */}

              {((coaAccType === 'ASSET' ||
                coaAccType === 'LIABILITY' ||
                coaAccType === 'EQUITY') &&
                nodes?.IsLastNode === true &&
                handleDisabled(
                  getMasterCOA[0]?.AccountType,
                  nodes?.CostCentreID
                ) === false) ||
              ((coaAccType === 'EXPENSE' || coaAccType === 'REVENUE') &&
                nodes?.IsLastNode === true &&
                !!getCostCentre?.find(
                  v => v?.CostCentreID === nodes?.CostCentreID
                )) ? (
                <>
                  <Typography variant="caption" color="inherit">
                    {mode === 'approve-reject' || mode === 'detail' ? (
                      <div className="xsTitle">
                        {
                          CostCentreBudgetItem?.filter(
                            x => x?.MasterCOAID === masterCOAData?.ID
                          )?.find(x => x?.CostCentreID === nodes?.CostCentreID)
                            ?.CostCentreBudgetAmount
                        }
                      </div>
                    ) : (
                      <Controller
                        ref={register()}
                        name={`${CostCentreBudgetAmountArr}.Amount`}
                        control={control}
                        //defaultValue={v.DocAmt || 0}
                        onChangeName="onValueChange"
                        onValueChange={e => {
                          e.value === '' || e.value === '-'
                            ? setValue(
                                `${CostCentreBudgetAmountArr}.Amount`,
                                null
                              )
                            : setValue(
                                `${CostCentreBudgetAmountArr}.Amount`,
                                e.value
                              )

                          if (
                            BudgetItem?.find(
                              el => el?.MasterCOAID === masterCOAData?.ID
                            ) !== undefined
                          ) {
                            localStorage.setItem(
                              'CostCentreBudgetItem',
                              JSON.stringify(PassedData())
                            )
                          }
                        }}
                        defaultValue={
                          !!CostCentreBudgetItem?.filter(
                            x => x?.MasterCOAID === masterCOAData?.ID
                          )?.find(x => x?.CostCentreID === nodes?.CostCentreID)
                            ? CostCentreBudgetItem?.filter(
                                x => x?.MasterCOAID === masterCOAData?.ID
                              )?.find(
                                x => x?.CostCentreID === nodes?.CostCentreID
                              )?.CostCentreBudgetAmount
                            : null
                        }
                        error={
                          watch(`${CostCentreBudgetAmountArr}.Amount`) === '' ||
                          watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            undefined
                            ? null
                            : watch(`${CostCentreBudgetAmountArr}.Amount`) !==
                              null
                            ? CCBudgetAmtChecking()
                            : null
                        }
                        helperText={
                          watch(`${CostCentreBudgetAmountArr}.Amount`) === '' ||
                          watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                            undefined ? null : watch(
                              `${CostCentreBudgetAmountArr}.Amount`
                            ) !== null ? (
                            CCBudgetAmtChecking() === true ? (
                              <div
                                className="desc text-noflow"
                                style={{ width: '80px' }}
                              >
                                Cannot exceed budget amount
                              </div>
                            ) : null
                          ) : null
                        }
                        as={
                          <NumberFormat
                            key={nodes.CostCentreID}
                            allowNegative={true}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale
                            variant="outlined"
                            className="qty-input-new-wid"
                            autoComplete="off"
                            thousandSeparator
                            isNumericString
                            //label="Journal Amount *"

                            margin="dense"
                            InputProps={{
                              style: {
                                width: '100px',
                              },
                            }}
                          />
                        }
                      />
                    )}
                  </Typography>

                  <Typography variant="caption" color="inherit">
                    {(mode === 'approve-reject' || mode === 'detail') &&
                    !CostCentreBudgetItem?.filter(
                      x => x?.MasterCOAID === masterCOAData?.ID
                    )?.find(x => x?.CostCentreID === nodes?.CostCentreID)
                      ?.CostCentreBudgetAmount ? (
                      <IconButton>
                        <div
                          style={{
                            width: '20px',
                            height: '20px',
                            color: 'white',
                            //backgroundColor: 'white',
                            //borderRadius: '3px',
                          }}
                        ></div>
                      </IconButton>
                    ) : (
                      <IconButton
                        className="first-btn"
                        onClick={e => {
                          // if (CCBudgetAmtChecking() === false) {
                          // if (
                          //   (watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                          //     null ||
                          //     watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                          //       undefined ||
                          //     watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                          //       '') &&
                          //   (mode === 'add' || mode === 'edit')
                          // ) {
                          //   localStorage.setItem(
                          //     'CostCentreBudgetItem',
                          //     JSON.stringify(PassedData())
                          //   )
                          // } else {
                          if (
                            BudgetItem?.find(
                              el => el?.MasterCOAID === masterCOAData?.ID
                            ) !== undefined
                          ) {
                            localStorage.setItem(
                              'CostCentreBudgetItem',
                              JSON.stringify(PassedData())
                            )

                            // localStorage.setItem(
                            //   'PeriodBudgetItem',
                            //   JSON.stringify(
                            //     updateEmptyPeriodItem(
                            //       PassedData()?.filter(
                            //         x => x?.CostCentreBudgetAmount !== ''
                            //       ),
                            //       PeriodBudgetItem
                            //     )
                            //   )
                            // )
                          }

                          if (
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              null ||
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              '' ||
                            watch(`${CostCentreBudgetAmountArr}.Amount`) ===
                              undefined
                          ) {
                            if (
                              mode === 'detail' &&
                              PeriodBudgetItem?.filter(
                                x =>
                                  x.BudgetItemCostCentreID ===
                                  CostCentreBudgetItem?.filter(
                                    x => x?.MasterCOAID === masterCOAData?.ID
                                  )?.find(
                                    x => x?.CostCentreID === nodes?.CostCentreID
                                  )?.BudgetItemCostCentreID
                              ).length > 0
                            ) {
                              history.push({
                                pathname: `/general-ledger/${CompanyID}/${budgetType}/${masterCOAData?.ID}/${nodes.CostCentreID}/${mode}`,
                                state: {
                                  MasterCOAData: masterCOAData,
                                  CostCentreData: nodes,
                                  PrevPItem:
                                    BudgetItem?.find(
                                      el =>
                                        el?.MasterCOAID === masterCOAData?.ID
                                    ) !== undefined
                                      ? updateEmptyPeriodItem(
                                          PassedData(),
                                          PeriodBudgetItem
                                        )
                                      : updateEmptyPeriodItem(
                                          [],
                                          PeriodBudgetItem
                                        ),
                                  ParentCostCentreData: getBudgetCostCentre.CostCentreData?.find(
                                    x =>
                                      x.CostCentreID ===
                                      nodes?.ParentCostCentreID
                                  ),
                                  CostCentreBudgetAmount:
                                    mode === 'approve-reject' ||
                                    mode === 'detail'
                                      ? CostCentreBudgetItem?.filter(
                                          x =>
                                            x?.MasterCOAID === masterCOAData?.ID
                                        )?.find(
                                          x =>
                                            x?.CostCentreID ===
                                            nodes?.CostCentreID
                                        )?.CostCentreBudgetAmount
                                      : getValues(
                                          `${CostCentreBudgetAmountArr}.Amount`
                                        ),
                                },
                              })
                            }
                          } else {
                            history.push({
                              pathname: `/general-ledger/${CompanyID}/${budgetType}/${masterCOAData?.ID}/${nodes.CostCentreID}/${mode}`,
                              state: {
                                MasterCOAData: masterCOAData,
                                CostCentreData: nodes,
                                PrevPItem:
                                  BudgetItem?.find(
                                    el => el?.MasterCOAID === masterCOAData?.ID
                                  ) !== undefined
                                    ? updateEmptyPeriodItem(
                                        PassedData(),
                                        PeriodBudgetItem
                                      )
                                    : updateEmptyPeriodItem(
                                        [],
                                        PeriodBudgetItem
                                      ),
                                ParentCostCentreData: getBudgetCostCentre.CostCentreData?.find(
                                  x =>
                                    x.CostCentreID === nodes?.ParentCostCentreID
                                ),
                                CostCentreBudgetAmount:
                                  mode === 'approve-reject' || mode === 'detail'
                                    ? CostCentreBudgetItem?.filter(
                                        x =>
                                          x?.MasterCOAID === masterCOAData?.ID
                                      )?.find(
                                        x =>
                                          x?.CostCentreID ===
                                          nodes?.CostCentreID
                                      )?.CostCentreBudgetAmount
                                    : getValues(
                                        `${CostCentreBudgetAmountArr}.Amount`
                                      ),
                              },
                            })
                          }
                          // }
                          // }
                        }}
                      >
                        <IconItem
                          icon={
                            <MenuIcon
                              style={{
                                width: '20px',
                                height: '20px',
                                color: 'white',
                                backgroundColor:
                                  watch(
                                    `${CostCentreBudgetAmountArr}.Amount`
                                  ) === null ||
                                  watch(
                                    `${CostCentreBudgetAmountArr}.Amount`
                                  ) === '' ||
                                  watch(
                                    `${CostCentreBudgetAmountArr}.Amount`
                                  ) === undefined
                                    ? mode === 'detail' &&
                                      PeriodBudgetItem?.filter(
                                        x =>
                                          x.BudgetItemCostCentreID ===
                                          CostCentreBudgetItem?.filter(
                                            x =>
                                              x?.MasterCOAID ===
                                              masterCOAData?.ID
                                          )?.find(
                                            x =>
                                              x?.CostCentreID ===
                                              nodes?.CostCentreID
                                          )?.BudgetItemCostCentreID
                                      ).length > 0
                                      ? '#FF9800'
                                      : '#938D8B'
                                    : '#FF9800',
                                borderRadius: '3px',
                              }}
                            />
                          }
                          //imageType="svg"
                          badgeType="number"
                          badgeNumber={
                            PeriodBudgetItem?.filter(
                              x =>
                                x.BudgetItemCostCentreID ===
                                CostCentreBudgetItem?.filter(
                                  x => x?.MasterCOAID === masterCOAData?.ID
                                )?.find(
                                  x => x?.CostCentreID === nodes?.CostCentreID
                                )?.BudgetItemCostCentreID
                            ).length > 0 ? (
                              <span
                                style={{
                                  width: '15px',
                                  height: '12px',
                                  backgroundColor: 'orange',
                                  fontSize: '10px',
                                  color: 'black',
                                  borderRadius: '20px',
                                  paddingTop: '3px',
                                }}
                              >
                                {
                                  PeriodBudgetItem?.filter(
                                    x =>
                                      x.BudgetItemCostCentreID ===
                                      CostCentreBudgetItem?.filter(
                                        x =>
                                          x?.MasterCOAID === masterCOAData?.ID
                                      )?.find(
                                        x =>
                                          x?.CostCentreID ===
                                          nodes?.CostCentreID
                                      )?.BudgetItemCostCentreID
                                  ).length
                                }
                              </span>
                            ) : null
                          }
                        />
                      </IconButton>
                    )}
                  </Typography>
                </>
              ) : null}
            </Box>
          </>
        }
      >
        {Array.isArray(nodes.child)
          ? nodes.child.map((nodes, index) => renderTree(nodes, index))
          : null}
      </CustomTreeItem>
    )
  }

  // const getCCBudgetItemWithParent = () => {
  //   const CCItemCCID = CostCentreBudgetItem?.map(
  //     x =>
  //       getBudgetCostCentre.CostCentreData?.find(el => el?.CostCentreID === x?.CostCentreID)
  //         ?.ParentCostCentreID
  //   )

  //   let CCDataArr = []

  //   setCCDataWParent(CCItemCCID)

  //   getParentCC(CCDataArr, CCItemCCID)

  //   return CCDataWParent
  // }

  // const getParentCC = (arr, currData) => {
  //   const dataToPush = getBudgetCostCentre.CostCentreData
  //     ?.filter(x => currData.includes(x?.CostCentreID))
  //     ?.map(el => el?.ParentCostCentreID)
  //   setCCDataWParent({ ...CCDataWParent, ...dataToPush })

  //   const CCData = getBudgetCostCentre.CostCentreData?.filter(x =>
  //     currData.includes(x?.CostCentreID)
  //   )

  //   CCData?.filter(
  //     x => x?.ParentCostCentreID !== null || x?.ParentCostCentreID !== ''
  //   )?.map(k => {
  //     getParentCC(arr, [k?.ParentCostCentreID])
  //   })
  // }

  /////////////////////////////

  const checkCCSameAsPrev = () => {
    const prev = JSON.stringify(
      masterCOAData?.prevCCItem
        ?.filter(el => el?.MasterCOAID === masterCOAData?.ID)
        ?.map(x => {
          return {
            MasterCOAID: x?.MasterCOAID,
            CostCentreID: x?.CostCentreID,
            CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          }
        })
    )

    const theCC =
      BudgetItem?.find(el => el?.MasterCOAID === masterCOAData?.ID) !==
      undefined
        ? PassedData()
        : []

    const current = JSON.stringify(
      theCC
        ?.filter(el => el?.MasterCOAID === masterCOAData?.ID)
        ?.map(x => {
          return {
            MasterCOAID: x?.MasterCOAID,
            CostCentreID: x?.CostCentreID,
            CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          }
        })
    )

    if (current === prev) {
      return true
    } else return false
  }

  const checkPeriodSameAsPrev = () => {
    const prev = JSON.stringify(
      masterCOAData?.prevPItem
        ?.filter(el => el?.MasterCOAID === masterCOAData?.ID)
        ?.map(x => {
          return {
            MasterCOAID: x?.MasterCOAID,
            CostCentreID: x?.CostCentreID,
            AccountPeriodID: x?.AccountPeriodID,
            CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
            PeriodBudgetAmount: x?.PeriodBudgetAmount,
          }
        })
    )
    const current = JSON.stringify(
      PeriodBudgetItem?.filter(
        el => el?.MasterCOAID === masterCOAData?.ID
      )?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          AccountPeriodID: x?.AccountPeriodID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          PeriodBudgetAmount: x?.PeriodBudgetAmount,
        }
      })
    )

    if (current === prev) {
      return true
    } else return false
  }

  const updateEmptyPeriodItem = (CCItem, PItem) => {
    const CCItemIDs = CCItem?.map(x => x?.BudgetItemCostCentreID)

    const filteredPItem = PItem?.filter(el =>
      CCItemIDs.includes(el?.BudgetItemCostCentreID)
    )

    return filteredPItem === undefined ? [] : filteredPItem
  }

  const proceedDialogConfirm = () => {
    if (
      BudgetItem?.find(el => el?.MasterCOAID === masterCOAData?.ID) !==
      undefined
    ) {
      localStorage.setItem(
        'CostCentreBudgetItem',
        JSON.stringify(
          PassedData()?.filter(x => x?.CostCentreBudgetAmount !== '')
        )
      )

      localStorage.setItem(
        'PeriodBudgetItem',
        JSON.stringify(
          updateEmptyPeriodItem(
            PassedData()?.filter(x => x?.CostCentreBudgetAmount !== ''),
            PeriodBudgetItem
          )
        )
      )
    }

    const actionData = JSON.parse(proceedAction)
    if (actionData !== '') {
      history.push({
        pathname: actionData?.pathname,
        state: actionData?.state,
      })
    }
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (
            checkCCSameAsPrev() === true
            // &&
            // checkPeriodSameAsPrev() === true
          ) {
            history.push({
              pathname: `/general-ledger/${CompanyID}/${budgetType}/${mode}`,
              state:
                mode === 'add'
                  ? {
                      nextVersion: masterCOAData?.nextVersion,
                      selectedYear: masterCOAData?.selectedYear,
                      accType: masterCOAData?.accType,
                      previousData: {
                        prevBudgetItem: masterCOAData?.prevBudgetItem,
                        prevCCItem: masterCOAData?.prevCCItem,
                        prevPItem: masterCOAData?.prevPItem,
                      },
                    }
                  : {
                      ...masterCOAData?.DefaultBudgetData,
                      accType: masterCOAData?.accType,
                      previousData: {
                        prevBudgetItem: masterCOAData?.prevBudgetItem,
                        prevCCItem: masterCOAData?.prevCCItem,
                        prevPItem: masterCOAData?.prevPItem,
                      },
                    },
            })
            localStorage.setItem(
              'CostCentreBudgetItem',
              JSON.stringify(masterCOAData?.prevCCItem)
            )

            localStorage.setItem(
              'PeriodBudgetItem',
              JSON.stringify(
                updateEmptyPeriodItem(
                  masterCOAData?.prevCCItem,
                  PeriodBudgetItem
                )
              )
            )
          } else {
            setOpenExitConf(true)
          }
        }} //<- back button action
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        //currency={`MYR`}
        routeSegments={[
          { name: 'Home Journal' },
          {
            name: 'Budget Listing',
          },
          {
            name: 'Original Budget',
          },
          {
            name: 'Department Budget',
            current: true,
          },
        ]}
        rightRouteSegments={[
          {
            name:
              mode === 'add'
                ? 'New'
                : // : mode === 'approve-reject'
                  // ? 'Approve/Reject'
                  // : mode === 'edit'
                  // ? 'Edit'
                  null,
            current: true,
          },
        ]}
      />

      <DynamicSubHeader
        title={
          <span className="highlight-text" style={{ fontWeight: 'bold' }}>
            {masterCOAData?.code}
          </span>
        }
        infoLine={
          <span style={{ fontWeight: 'bold' }}>{masterCOAData?.name}</span>
        }
        rightText={
          <IconText
            icon={<EventIcon style={{ width: '14px', color: '#FF9800' }} />}
            children={
              <span style={{ color: '#FF9800' }}>
                {masterCOAData?.selectedYear}
              </span>
            }
          ></IconText>
        }
        rightInfoLine={
          <span style={{ color: '#FF9800' }}>
            <span style={{ fontSize: '13px' }}>
              {masterCOAData?.budgetAmount === undefined ||
              !!!masterCOAData?.budgetAmount
                ? '0.00'
                : amtStr(Number(amtNumStr(masterCOAData?.budgetAmount)))}
            </span>
          </span>
        }
      />
      {getBudgetCostCentre.CostCentreData?.length > 0 || reRender
        ? !getCostCentreLoading &&
          !getParentCostCentreForTreeLoading && (
            <ContentWrapper float multiDynamicInfo>
              <div>
                <CardContents>
                  <TreeView
                    className="tree-view-root"
                    defaultExpanded={
                      mode === 'add' || mode === 'edit' || mode === 'detail'
                        ? getParentCostCentreForTree?.map(x => x?.CostCentreID)
                        : ['root']
                    }
                    //expanded={getBudgetCostCentre.CostCentreData?.map(x => x?.CostCentreID)}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultEndIcon={<DotIcon />}
                    multiSelect
                    // onNodeToggle={handleToggle}
                    // expanded={expanded}
                  >
                    {filteredList.map((el, index) => {
                      return renderTree(el, index)
                    })}
                  </TreeView>
                </CardContents>
              </div>
            </ContentWrapper>
          )
        : !getCostCentreLoading &&
          !getParentCostCentreForTreeLoading && (
            <EmptyList
              title="No Department Definition Assigned"
              subtitle="Assign New Department Definition for this company."
            />
          )}

      {mode === 'approve-reject' || mode === 'detail' ? null : (
        <Footer
          options={[
            {
              name: 'Save',
              onClick: () => {
                if (CCBudgetAmtChecking() === false) {
                  if (
                    PeriodBudgetItem?.filter(
                      x =>
                        !updateEmptyPeriodItem(
                          PassedData()?.filter(
                            x => x?.CostCentreBudgetAmount !== ''
                          ),
                          PeriodBudgetItem
                        )
                          ?.map(el => el?.BudgetItemCostCentreID)
                          .includes(x?.BudgetItemCostCentreID)
                    )?.length > 0
                  ) {
                    setProceedAction(
                      JSON.stringify({
                        pathname: `/general-ledger/${CompanyID}/${budgetType}/${mode}`,
                        state:
                          mode === 'add'
                            ? {
                                selectedYear: masterCOAData?.selectedYear,
                                accType: masterCOAData?.accType,
                                previousData: {
                                  prevBudgetItem: masterCOAData?.prevBudgetItem,
                                  prevCCItem: masterCOAData?.prevCCItem,
                                  prevPItem: masterCOAData?.prevPItem,
                                },
                              }
                            : {
                                ...masterCOAData?.DefaultBudgetData,
                                accType: masterCOAData?.accType,
                                previousData: {
                                  prevBudgetItem: masterCOAData?.prevBudgetItem,
                                  prevCCItem: masterCOAData?.prevCCItem,
                                  prevPItem: masterCOAData?.prevPItem,
                                },
                              },
                      })
                    )
                    setOpenProceedConf(true)
                  } else {
                    if (
                      BudgetItem?.find(
                        el => el?.MasterCOAID === masterCOAData?.ID
                      ) !== undefined
                    ) {
                      localStorage.setItem(
                        'CostCentreBudgetItem',
                        JSON.stringify(
                          PassedData()?.filter(
                            x => x?.CostCentreBudgetAmount !== ''
                          )
                        )
                      )
                      localStorage.setItem(
                        'PeriodBudgetItem',
                        JSON.stringify(
                          updateEmptyPeriodItem(
                            PassedData()?.filter(
                              x => x?.CostCentreBudgetAmount !== ''
                            ),
                            PeriodBudgetItem
                          )
                        )
                      )
                    }
                    history.push({
                      pathname: `/general-ledger/${CompanyID}/${budgetType}/${mode}`,
                      state:
                        mode === 'add'
                          ? {
                              selectedYear: masterCOAData?.selectedYear,
                              accType: masterCOAData?.accType,
                              previousData: {
                                prevBudgetItem: masterCOAData?.prevBudgetItem,
                                prevCCItem: masterCOAData?.prevCCItem,
                                prevPItem: masterCOAData?.prevPItem,
                              },
                            }
                          : {
                              ...masterCOAData?.DefaultBudgetData,
                              accType: masterCOAData?.accType,
                              previousData: {
                                prevBudgetItem: masterCOAData?.prevBudgetItem,
                                prevCCItem: masterCOAData?.prevCCItem,
                                prevPItem: masterCOAData?.prevPItem,
                              },
                            },
                    })
                  }
                }
              },
              // disabled:
              //   getAccountPeriod == undefined || getAccountPeriod.length == 0,
              // visible:
              //   getAccountPeriod == undefined || getAccountPeriod.length == 0,

              color: 'primary',
            },
          ]}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push({
            pathname: `/general-ledger/${CompanyID}/${budgetType}/${mode}`,
            state:
              mode === 'add'
                ? {
                    nextVersion: masterCOAData?.nextVersion,
                    selectedYear: masterCOAData?.selectedYear,
                    accType: masterCOAData?.accType,
                    previousData: {
                      prevBudgetItem: masterCOAData?.prevBudgetItem,
                      prevCCItem: masterCOAData?.prevCCItem,
                      prevPItem: masterCOAData?.prevPItem,
                    },
                  }
                : {
                    ...masterCOAData?.DefaultBudgetData,
                    accType: masterCOAData?.accType,
                    previousData: {
                      prevBudgetItem: masterCOAData?.prevBudgetItem,
                      prevCCItem: masterCOAData?.prevCCItem,
                      prevPItem: masterCOAData?.prevPItem,
                    },
                  },
          })
          localStorage.setItem(
            'CostCentreBudgetItem',
            JSON.stringify(masterCOAData?.prevCCItem)
          )

          localStorage.setItem(
            'PeriodBudgetItem',
            JSON.stringify(
              updateEmptyPeriodItem(masterCOAData?.prevCCItem, PeriodBudgetItem)
            )
          )
        }}
        //hasInfo={hasInfo}
      />

      <CommonDialog
        fullWidth={true}
        open={openProceedConf}
        onClose={() => setOpenProceedConf(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: '#ffb11f' }}
                      >
                        {'Proceed Confirmation'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>
                {
                  'Are you sure you want to Proceed? Leaving Department Budget with empty value will delete all the Period Budget for the respective Department Budget.'
                }
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOpenProceedConf(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    proceedDialogConfirm()
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

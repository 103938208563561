import { AttachFile } from '@material-ui/icons'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { default as React, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import '../RecurringJournal.scss'

interface DetailProps {
  listEl: any
  documentListing?: any
  listStatus: string
  userList: any
  mode?: string
}

export const RecurringDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()

  const { listEl, documentListing, listStatus, userList, mode } = props

  return (
    <>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Reference No
        </div>
        <div className="mdDesc"> {listEl?.RefNo} </div>
      </div>

      <div
        className="content-wrap left"
        style={{ width: mode === 'expansion' ? '40%' : '50%' }}
      >
        <div className="desc" style={{ color: 'grey' }}>
          Journal Type
        </div>
        <div className="mdDesc"> {listEl?.JournalType?.JournalType} </div>
      </div>

      {mode === 'expansion' && documentListing?.length > 0 && (
        <div
          className="content-wrap right"
          style={{ width: '10%', textAlign: 'center', alignSelf: 'end' }}
        >
          {documentListing && (
            <>
              <span
                onClick={() =>
                  documentListing?.forEach(doc => {
                    window.open(doc?.fileURL, '_blank')
                  })
                }
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <AttachFile
                  className="mdDesc"
                  style={{
                    fontSize: 12,
                    color: 'orange',
                  }}
                />
              </span>
              <span
                className="mdDesc"
                style={{ color: 'orange', textDecoration: 'underline' }}
              >
                {documentListing?.length}
              </span>
            </>
          )}
        </div>
      )}
      <div className="content-wrap left">
        <div className="desc " style={{ color: 'grey' }}>
          Start Year
        </div>
        <div className="mdDesc text-noflow"> {listEl?.StartYear} </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Start Period
        </div>
        <div className="mdDesc"> {listEl?.StartPeriod} </div>
      </div>

      <div className="content-wrap left">
        <div className="desc " style={{ color: 'grey' }}>
          No. Of Recurrance
        </div>
        <div className="mdDesc text-noflow"> {listEl?.NoOfRecurrance} </div>
      </div>
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Interval
        </div>
        <div className="mdDesc"> {listEl?.Interval} </div>
      </div>

      <div className="content-wrap full">
        <div className="desc " style={{ color: 'grey' }}>
          Description
        </div>
        <div className="mdDesc text-noflow">
          {' '}
          {listEl?.Description ?? listEl?.Remark}{' '}
        </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}

import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetCreditorAccountCompanyAssignmentDocument,
  RecordStatus,
  useAssignCreditorAccountCompanyMutation,
  useGetCompanyQuery,
  useUnassignCreditorAccountCompanyMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'

interface AvailableCompanyProps {
  userID: String
  roleID: String
  companyID: String
}

export const CreditorAvailableCompanyForm = (props: any) => {
  let history = useHistory()
  const { CreditorAccountID }: any = useParams()
  const [entity, setEntity] = useState<Set<any>>()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { pageMode, company }: any = props
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const { handleSubmit } = useForm<AvailableCompanyProps>({
    defaultValues: {},
    mode: 'all',
  })

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      RecordStatus: RecordStatus.Active,
    },
  })

  const [
    assignCompany,
    { loading: assignCompanyLoading, error: assignCompanyError },
  ] = useAssignCreditorAccountCompanyMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        setEntity(new Set(undefined))
        history.push({
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })
  const [
    unassignCompany,
    { loading: unassignCompanyLoading, error: unassignCompanyError },
  ] = useUnassignCreditorAccountCompanyMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        setEntity(new Set(undefined))
        history.push({
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const handleCreateUpdate =
    pageMode === 'available' ? assignCompany : unassignCompany

  useEffect(() => {
    if (getCompany && getCompany?.length > 0) {
      setOriginalListing(company)
    }
  }, [getCompany, pageMode, company])

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, index: number) => {
    let entityTemp = entity || new Set()
    if (!e.target.checked) {
      filteredList.find(v => {
        if (v.CompanyID === e.target.value) {
          entityTemp.delete(v)
        }
      })
    } else {
      filteredList.find(v => {
        if (v.CompanyID === e.target.value) {
          entityTemp.add(v)
        }
      })
    }
    setEntity(new Set(entityTemp))
  }

  let listCheckedEntity = Array.from(entity || [])
  const companyArray = listCheckedEntity?.map(v => {
    return v.CompanyID
  })

  const assignOption = {
    name: 'Assign',
    onClick: () => {
      handleSubmit(onSubmit)()
    },
    color: 'primary',
  }
  const unassignOption = {
    name: 'Unassign',
    onClick: () => {
      handleSubmit(onSubmit)()
    },
    color: 'primary',
  }

  const submitOption: any[] =
    pageMode === 'available' ? [assignOption] : [unassignOption]
  const onSubmit = data => {
    handleCreateUpdate({
      variables: {
        input: {
          companyID: companyArray,
          CreditorAccountID: CreditorAccountID,
        },
      },
      refetchQueries: [
        {
          query: GetCreditorAccountCompanyAssignmentDocument,
        },
      ],
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      {assignCompanyLoading && <Loading />}
      {unassignCompanyLoading && <Loading />}
      <div className="tab-subheader">
        <SearchHeader
          title="Search here.."
          value={`${filteredList?.length || 0}`}
          search
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Name', 'CompanyRegNo'])
          }}
          onCloseAction={() => handleSearch('', [])}
        />
      </div>

      <ContentWrapper float>
        {filteredList?.length === 0 ? (
          <EmptyList
            title={`No 
            ${pageMode === 'available' ? 'unassigned' : 'assigned'} 
            Companies`}
          />
        ) : (
          <CardContents>
            <List disablePadding>
              {filteredList
                ?.sort((a, b) => {
                  return a.Name.localeCompare(b.Name)
                })
                .map((v, index) => {
                  return (
                    <ListItem disableGutters>
                      <Checkbox
                        color="primary"
                        value={v.CompanyID}
                        onChange={e => {
                          handleCheckBoxChange(e, index)
                        }}
                        checked={
                          !!listCheckedEntity.find(
                            (el: any) => el.CompanyID === v.CompanyID
                          ) || entity?.size === filteredList?.length
                        }
                      />
                      <ListItemText
                        primary={
                          <div className="xsTitle text-noflow">{v.Name}</div>
                        }
                        secondary={
                          <>
                            {v.children?.length > 0 && (
                              <span className="desc text-no-flow">
                                {v?.selected ? v?.selected : 0}
                                <span style={{ margin: '0px 4px' }}>/</span>
                                {v.children?.length} Accessible
                              </span>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  )
                })}
            </List>
          </CardContents>
        )}
      </ContentWrapper>
      <Footer options={[...submitOption]} />
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import Fuse from 'fuse.js'
import {
  useGetApBatchPaymentbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BatchPaymentEntryList } from './APBatchPaymentList/APBatchPaymentEntryList'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const APBatchPaymentMain = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  /* -------------------------------------------- */
  /*                     ENUM                     */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Paid', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadBatchPayment,
    {
      loading: APBatchPaymentbyStatusLoading,
      data: { getAPBatchPaymentbyStatus } = {
        getAPBatchPaymentbyStatus: [],
      },
    },
  ] = useGetApBatchPaymentbyStatusLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPBatchPaymentbyStatus }) => {
      // setOriginalListing(getAPBatchPaymentbyStatus)
      setOriginalListing(getAPBatchPaymentbyStatus, v => {
        if (listStatus === 'COMPLETED')
          v['docDateDateFormatted'] = formatDate(v.approvedTs)
        if (listStatus === 'SUBMIT')
          v['docDateDateFormatted'] = formatDate(v.submittedTs)
        else v['docDateDateFormatted'] = formatDate(v.createdTs)
      })
    },
  })

  const {
    loading: userLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  useEffect(() => {
    loadBatchPayment({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
      },
    })
  }, [listStatus])

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  useEffect(() => {
    if (getSearch?.name && originalList) {
      const keys = ['docDateDateFormatted', 'Description', amtNumStr('DocAmt')]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch?.name)
      const val = result?.map(x => x.item)
      // if (val.length !== 0) {
      handleSearch(getSearch?.name + '', keys)
      // }
    }
  }, [getSearch?.name, originalList])

  return (
    <>
      {userLoading && <Loading />}
      {APBatchPaymentbyStatusLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-payable/${CompanyID}`)
          localStorage.removeItem('searchFilter')
          localStorage.removeItem('searchDropdown')
        }}
        smTitle={'Accounts Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'AP' },
          { name: 'AP' },
          { name: 'Batch Payment', current: true },
        ]}
      />

      <div className={`search-filter with-dropdown-filter`}>
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'docDateDateFormatted',
              'Description',
              amtNumStr('DocAmt'),
            ])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
            // localStorage.setItem('searchFilter', e?.target?.value)
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      <BatchPaymentEntryList
        filteredList={filteredList}
        listLoading={APBatchPaymentbyStatusLoading}
        listStatus={listStatus}
        userList={getUsersByAccountAndSoftware}
        fetchMore={loadBatchPayment}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

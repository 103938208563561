import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Button, Grid, Switch, TextField } from '@material-ui/core'
import { LockRounded } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AccountType,
  GetParentMasterCoaDocument,
  useAccountCodeCheckingLazyQuery,
  useCreateMasterCoaMutation,
  useGetEntityCoaLazyQuery,
  useGetMasterCoaQuery,
  useUpdateMasterCoaMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'

interface MasterCOAProps {
  ParentMasterCOAID: String
  Code: string
  AccountCode: string
  Name: string
  AccountType: string
  IsControl: boolean
  IsLastNode: boolean
}

export const MasterCOAForm = (props: any) => {
  const { mode } = props
  let history = useHistory()
  let location = useLocation()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [accountType, setAccountType] = useState<any>()
  const [masterID, setMasterID] = useState<any>()
  const [duplicateDialog, setDuplicateDialog] = useState(false)
  const [allowDuplicate, setAllowDuplicate] = useState(false)
  const [duplicateName, setDuplicateName] = useState('')
  const { accType }: any = useParams()
  const editData: any = location?.state
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [DefaultParentCode, setDefaultParentCode] = useState<any>(
    editData?.parentCode
  )
  const [check, setCheck] = useState<boolean>(
    editData?.IsControl === true ? true : false
  )
  const [openExitConf, setOpenExitConf] = useState(null)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [
    checkAccCode,
    {
      loading: AccountCodeCheckingLoading,
      data: { accountCodeChecking } = { accountCodeChecking: [] },
    },
  ] = useAccountCodeCheckingLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: MasterCoaLoading,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { AccountType: accountType, orderByAsc: 'Code' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  const {
    loading: FetchMastercoaLoading,
    data: { getMasterCOA: fetchMastercoa } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    checkAssignEntity,
    {
      loading: EntityCoaLoading,
      data: { getEntityCOA } = { getEntityCOA: [] },
    },
  ] = useGetEntityCoaLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const MasterCOAFormSchema = yup.object().shape({
    Code: yup
      .string()
      .required('Quantity is required')
      .length(
        accountCodeChecking?.CodeLength,
        `Code must be ${accountCodeChecking?.CodeLength} characters.`
      ),
    Name: CommonYupValidation.requireField(`Name is required`),
  })

  const handleProceed = x => {
    handleSubmit(onSubmit)()
    setAllowDuplicate(x)
    setDuplicateDialog(false)
  }

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<MasterCOAProps>({
    defaultValues: {
      ParentMasterCOAID: mode === 'edit' ? editData?.parentID : null,
      Code:
        mode === 'edit'
          ? editData?.code.substring(editData?.parentCode?.length)
          : '',
      Name: mode === 'edit' ? editData?.name : '',
      IsControl: mode === 'edit' ? editData?.data?.IsControl : null,
      IsLastNode: mode === 'edit' ? editData?.data?.IsLastNode : null,
      AccountType: mode === 'edit' ? editData?.data?.AccountType : null,
    },
    mode: 'onSubmit',
    resolver: yupResolver(MasterCOAFormSchema),
  })

  const hasChanges = () => {
    const hasInfo = watch('Name') !== '' && watch('Name') !== undefined

    let prevData = { Name: editData?.name }
    let currentData = { Name: watch('Name') }

    if (mode === 'edit') {
      if (JSON.stringify(prevData) === JSON.stringify(currentData)) return false
      else return true
    } else {
      if (hasInfo === true) return true
      else return false
    }
  }

  const [
    createMasterCOA,
    { loading: CreateLoading, data: createdMasterCOAData },
  ] = useCreateMasterCoaMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/general-setting/master-coa/${accType}`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    updateMasterCOA,
    { loading: UpdateLoading, data: updatedMasterCOAData },
  ] = useUpdateMasterCoaMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/general-setting/master-coa/${accType}`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  useEffect(() => {
    if (mode === 'edit') {
      setValue('ParentMasterCOAID', editData?.parentID)
      checkAccCode({ variables: { MasterCOAID: editData?.parentID } })
      checkAssignEntity({ variables: { MasterCOAID: editData?.ID } })
    } else checkAccCode()
  }, [mode])

  useEffect(() => {
    if (accType === 'asset') setAccountType(AccountType.Asset)
    else if (accType === 'liability') setAccountType(AccountType.Liability)
    else if (accType === 'equity') setAccountType(AccountType.Equity)
    else if (accType === 'revenue') setAccountType(AccountType.Revenue)
    else if (accType === 'expense') setAccountType(AccountType.Expense)
  }, [accType])

  const findDefaultParentCode = parentID => {
    const ParentCode = getMasterCOA?.find(x => x?.MasterCOAID === parentID)
      ?.Code
    setDefaultParentCode(ParentCode)
  }

  const handleDuplicate = data => {
    const duplicateName =
      mode === 'add'
        ? getMasterCOA?.find(x => x?.Name.includes(data?.Name))
        : getMasterCOA?.find(
            x => x?.Name.includes(data?.Name) && x?.MasterCOAID !== editData?.ID
          )
    if (duplicateName) {
      setDuplicateDialog(true)
      setDuplicateName(data?.Name)
    } else {
      handleSubmit(onSubmit)()
    }

    if (allowDuplicate) {
      handleSubmit(onSubmit)()
    } else return
  }

  const onSubmit = data => {
    const parentCode = getMasterCOA?.find(x => x?.MasterCOAID === masterID)
      ?.Code

    if (mode === 'add') {
      createMasterCOA({
        variables: {
          input: {
            Code:
              parentCode === undefined
                ? `${data?.Code}`
                : `${parentCode}${data?.Code}`,
            Name: data?.Name,
            ParentMasterCOAID: data?.ParentMasterCOAID,
            IsControl: check,
            IsLastNode:
              accountCodeChecking?.AllowControl === true ? true : false,
            AccountType: accountType,
            Level: accountCodeChecking?.Level,
          },
        },
        refetchQueries: [{ query: GetParentMasterCoaDocument }],
      })
    } else if (mode === 'edit') {
      updateMasterCOA({
        variables: {
          input: {
            MasterCOAID: editData?.ID,
            Code:
              DefaultParentCode === undefined
                ? `${data?.Code}`
                : `${DefaultParentCode}${data?.Code}`,
            Name: data?.Name,
            ParentMasterCOAID: data?.ParentMasterCOAID,
            IsControl: check,
            IsLastNode:
              accountCodeChecking?.AllowControl === true ? true : false,
            AccountType: accountType,
            Level: accountCodeChecking?.Level ?? editData?.level,
          },
        },
        refetchQueries: [{ query: GetParentMasterCoaDocument }],
      })
    }
  }

  const AccountTypeLabel = type => {
    switch (type) {
      case 'asset':
        return 'Asset'
      case 'liability':
        return 'Liability'
      case 'equity':
        return 'Equity'
      case 'revenue':
        return 'Revenue'
      case 'expense':
        return 'Expense'
      default:
        return ''
    }
  }

  const parentCode = getMasterCOA?.find(x => x?.MasterCOAID === masterID)?.Code

  const watchValue = watch(['Code'])

  const accCodeValue =
    mode === 'edit'
      ? DefaultParentCode === undefined
        ? watchValue.Code === null
          ? ''
          : `${watchValue.Code}`
        : watchValue.Code === null
        ? `${DefaultParentCode}`
        : `${DefaultParentCode}${watchValue.Code}`
      : parentCode === undefined
      ? watchValue.Code === null
        ? ''
        : `${watchValue.Code}`
      : watchValue.Code === null
      ? `${parentCode}`
      : `${parentCode}${watchValue.Code}`

  useEffect(() => {
    setValue(`accountCode`, accCodeValue)
  }, [accCodeValue])

  return (
    <>
      {CreateLoading && <Loading />}
      {UpdateLoading && <Loading />}
      {MasterCoaLoading && <Loading />}
      {EntityCoaLoading && <Loading />}
      {FetchMastercoaLoading && <Loading />}
      {AccountCodeCheckingLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          hasChanges() === true
            ? setOpenExitConf(true)
            : history.push(
                `/general-ledger/general-setting/master-coa/${accType}`
              )
        }}
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'Master COA', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <DynamicSubHeader title={AccountTypeLabel(accType)} />
      <ContentWrapper float singleDynamicInfo>
        {!MasterCoaLoading && (
          <CardContents>
            <Controller
              style={{ paddingRight: '5px' }}
              control={control}
              name="ParentMasterCOAID"
              ref={register()}
              defaultValue={editData?.parentID}
              onChange={({ value, newValue }) => {
                return newValue?.MasterCOAID
              }}
              className="left"
              render={({ onChange, onBlur, value }) => {
                const defVal = getMasterCOA?.filter(
                  x => x?.MasterCOAID === editData?.parentID
                )[0]
                return (
                  <Autocomplete
                    className="mat-new"
                    defaultValue={defVal}
                    options={
                      getMasterCOA?.filter(
                        x =>
                          x?.IsLastNode === false && x?.Code !== editData?.code
                      ) || []
                    }
                    disabled={
                      editData?.haveChild === true || getEntityCOA?.length > 0
                    }
                    getOptionLabel={option =>
                      `${option?.Code} | ${option?.Name}`
                    }
                    fullWidth
                    renderOption={option => (
                      <div>
                        {option?.Code} | {option?.Name}
                      </div>
                    )}
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.MasterCOAID)
                      checkAccCode({
                        variables: { MasterCOAID: newValue?.MasterCOAID },
                      })
                      setMasterID(newValue?.MasterCOAID)
                      setValue('ParentMasterCOAID', newValue?.MasterCOAID)
                      findDefaultParentCode(newValue?.MasterCOAID)
                    }}
                    PopperComponent={AccCodeDropdownFullWidth}
                    renderInput={(params: any) => {
                      return (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label="Parent Code"
                          defaultValue={editData?.ParentCode}
                        />
                      )
                    }}
                  />
                )
              }}
            />
            <Grid container>
              <Grid xs={6}>
                <Controller
                  as={TextField}
                  name="Code"
                  label="Code"
                  required
                  control={control}
                  fullWidth
                  variant="outlined"
                  helperText={
                    errors?.Code
                      ? `Code must be ${accountCodeChecking?.CodeLength} characters.`
                      : null
                  }
                  error={errors?.Code ? true : false}
                  ref={register}
                  disabled={
                    editData?.haveChild === true || getEntityCOA?.length > 0
                  }
                />
              </Grid>
              <Grid xs={1} />
              <Grid xs={5}>
                <TextField
                  name="accountCode"
                  label="Account Code"
                  value={accCodeValue}
                  ref={register}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  helperText={
                    mode === 'add'
                      ? fetchMastercoa?.filter(x => x?.Code === accCodeValue)
                          ?.length > 0 &&
                        getValues('Code') !== '' &&
                        getValues('Code') !== null
                        ? `The Code already exist`
                        : null
                      : accCodeValue !== editData?.code &&
                        fetchMastercoa?.filter(x => x?.Code === accCodeValue)
                          ?.length > 0 &&
                        getValues('Code') !== '' &&
                        getValues('Code') !== null
                      ? `The Code already exist`
                      : null
                  }
                  error={
                    mode == 'add'
                      ? fetchMastercoa?.filter(x => x?.Code === accCodeValue)
                          ?.length > 0 &&
                        getValues('Code') !== '' &&
                        getValues('Code') !== null
                        ? true
                        : false
                      : accCodeValue !== editData?.code &&
                        fetchMastercoa?.filter(x => x?.Code === accCodeValue)
                          ?.length > 0 &&
                        getValues('Code') !== '' &&
                        getValues('Code') !== null
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>
            <Controller
              as={TextField}
              name="Name"
              label="Name"
              required
              control={control}
              fullWidth
              ref={register}
              helperText={errors?.Name ? `Name is required` : null}
              error={errors?.Name ? true : false}
              defaultValue={editData?.Name}
              //disabled={editData?.IsControl === true ? true : false}
            />
            {accountCodeChecking &&
            accountCodeChecking?.AllowControl === true ? (
              <div
                className="content-wrap full"
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Button>
                  <LockRounded fontSize="small" />
                  <span className="flex-space">Control Account</span>
                </Button>

                <Switch
                  checked={check}
                  onChange={e => {
                    setCheck(e.target.checked)
                  }}
                  name="IsControl"
                  color="primary"
                  // disabled={
                  //   !accountCodeChecking ||
                  //   accountCodeChecking?.AllowControl !== true ||
                  //   editData?.haveChild === true ||
                  //   getEntityCOA?.length > 0
                  // }
                />
              </div>
            ) : null}
          </CardContents>
        )}
      </ContentWrapper>
      <AccountFooter
        copyright
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(handleDuplicate)()
            },
            disabled:
              mode === 'add'
                ? fetchMastercoa?.filter(x => x?.Code === accCodeValue)
                    ?.length > 0
                : accCodeValue !== editData?.code &&
                  getMasterCOA?.filter(x => x?.Code === accCodeValue)?.length >
                    0,
          },
        ]}
      />
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/general-setting/master-coa/${accType}`)
        }}
      />
      <CommonDialog
        fullWidth={true}
        open={duplicateDialog}
        onClose={() => setDuplicateDialog(false)}
        sections={{
          header: {
            title: 'Warning',
            infoLine: 'Existing Master COA Name',
          },
          body: () => (
            <div className="content-wrap full">
              <div className="desc">
                {`"${duplicateName}" already exists. Proceed?`}
              </div>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setDuplicateDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Proceed',
                props: {
                  onClick: () => {
                    handleProceed(true)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import React, { lazy } from 'react'

const ApprovalPolicySubmenu = lazy(() =>
  import('./ApprovalPolicySubmenu').then(module => ({
    default: module.ApprovalPolicySubmenu,
  }))
)

const ApprovalPolicyListing = lazy(() =>
  import('./ApprovalPolicyListing').then(module => ({
    default: module.ApprovalPolicyListing,
  }))
)

const ApprovalPolicyRoleForm = lazy(() =>
  import('./ApprovalPolicyRoleForm').then(module => ({
    default: module.ApprovalPolicyRoleForm,
  }))
)

// const ApprovalPolicyRoleForm = lazy(() =>
//   import('./ApprovalPolicyRoleForm').then(module => ({
//     default: module.ApprovalPolicyRoleForm,
//   }))
// )

const approvalPolicyRoutes = [
  //GENERAL LEDGER
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/approval-policy-gl',
      // path: '/general-ledger/general-setting/approval-policy',
    },
    component: <ApprovalPolicySubmenu module="general-ledger" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/general-ledger/general-setting/approval-policy-gl/:docType',
  //   },
  //   component: <ApprovalPolicyListing module="general-ledger" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/:ApprovalPolicyID/edit',
  //   },
  //   component: <ApprovalPolicyRoleForm mode="edit" module="general-ledger" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/general-ledger/general-setting/approval-policy-gl/:docType/add',
  //   },
  //   component: <ApprovalPolicyRoleForm mode="add" module="general-ledger" />,
  // },
  {
    props: {
      exact: true,
      path: '/general-ledger/general-setting/approval-policy-gl/:docType',
    },
    component: <ApprovalPolicyRoleForm module="general-ledger" />,
  },
  // -- budget stuff
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/original',
  //   },
  //   component: (
  //     <ApprovalPolicyListing module="general-ledger" budget="original" />
  //   ),
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/revision',
  //   },
  //   component: (
  //     <ApprovalPolicyListing module="general-ledger" budget="revision" />
  //   ),
  // },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/general-setting/approval-policy-gl/:docType/original',
    },
    component: (
      <ApprovalPolicyRoleForm module="general-ledger" budget="original" />
    ),
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/original/add',
  //   },
  //   component: (
  //     <ApprovalPolicyRoleForm
  //       mode="add"
  //       module="general-ledger"
  //       budget="original"
  //     />
  //   ),
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/original/:ApprovalPolicyID/edit',
  //   },
  //   component: (
  //     <ApprovalPolicyRoleForm
  //       mode="edit"
  //       module="general-ledger"
  //       budget="original"
  //     />
  //   ),
  // },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/general-setting/approval-policy-gl/:docType/revision',
    },
    component: (
      <ApprovalPolicyRoleForm module="general-ledger" budget="revision" />
    ),
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/revision/add',
  //   },
  //   component: (
  //     <ApprovalPolicyRoleForm
  //       mode="add"
  //       module="general-ledger"
  //       budget="revision"
  //     />
  //   ),
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/general-ledger/general-setting/approval-policy-gl/:docType/revision/:ApprovalPolicyID/edit',
  //   },
  //   component: (
  //     <ApprovalPolicyRoleForm
  //       mode="edit"
  //       module="general-ledger"
  //       budget="revision"
  //     />
  //   ),
  // },

  //CASH BOOK
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/approval-policy-cb',
    },
    component: <ApprovalPolicySubmenu module="cash-book" />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/approval-policy-cb/:docType',
    },
    component: <ApprovalPolicyRoleForm module="cash-book" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/cash-book/general-setting/approval-policy-cb/:docType/:ApprovalPolicyID/edit',
  //   },
  //   component: <ApprovalPolicyRoleForm mode="edit" module="cash-book" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/cash-book/general-setting/approval-policy-cb/:docType/add',
  //   },
  //   component: <ApprovalPolicyRoleForm mode="add" module="cash-book" />,
  // },

  //AR
  {
    props: {
      exact: true,
      path: '/account-receivable/general-setting/approval-policy-ar',
    },
    component: <ApprovalPolicySubmenu module="account-receivable" />,
  },
  {
    props: {
      exact: true,
      path: '/account-receivable/general-setting/approval-policy-ar/:docType',
    },
    component: <ApprovalPolicyRoleForm module="account-receivable" />,
  },

  //AP
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/approval-policy-ap',
    },
    component: <ApprovalPolicySubmenu module="account-payable" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/approval-policy-ap/:docType',
    },
    component: <ApprovalPolicyRoleForm module="account-payable" />,
  },
]

export default approvalPolicyRoutes

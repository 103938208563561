import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { useMediaQuery } from '@material-ui/core'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  DocumentListingDocument,
  GetFavoriteReceiptDocument,
  GetReceiptDocument,
  useDocumentListingQuery,
  useGetBankAccountQuery,
  useGetReceiptQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
  useUpdateReceiptMutation,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { default as React, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { ReceiptDetailContent } from './ReceiptDetailContent'
import { ReceiptDetailItemContent } from './ReceiptItemDetailContent'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const ReceiptDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, ReceiptID, BankAccountID }: any = useParams()
  const { mode } = props
  let location = useLocation()
  const editData = location?.state as any
  const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption()
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [audioInput, setAudioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [text, setText] = useState(mode === 'add' ? '' : editData?.remark)
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    loading: JournalLoading,
    data: { getReceipt } = { getReceipt: [] },
  } = useGetReceiptQuery({
    variables: { ReceiptID: ReceiptID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })
  // Query for Bank Account

  const {
    loading: bankAccountLoading,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: { BankAccountID: BankAccountID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: getReceipt?.[0]?.ReceiptID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [updateReceipt, { loading: updateLoading }] = useUpdateReceiptMutation({
    onError: error => {
      console.log(error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      mode === 'workdesk'
        ? setTimeout(() => {
            history.push({
              pathname: `/workdesk/cash-book`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
        : setTimeout(() => {
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
    },
  })

  const {
    files: fileDoc,
    handleUploadChange: handleUploadChangeDoc,
    previewFiles: previewFilesDoc,
    remove: removeFile,
    handleEditUpload: handleEditUploadDoc,
  } = useUploadDocument()

  //---Favorite Receipt API---//

  const favRefetchQuery = [
    {
      query: GetFavoriteReceiptDocument,
      variables: {
        CompanyID,
        UserID: user?.ID,
      },
    },
  ]

  const RecordStatusText = pStatus => {
    switch (pStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  const ReceiptItem = getReceipt[0]?.ReceiptItem

  const onSubmit = (data, status) => {
    updateReceipt({
      variables: {
        input: {
          ReceiptID: getReceipt[0]?.ReceiptID,
          CompanyID: CompanyID,
          DocDate: getReceipt[0]?.DocDate,
          RefNo: getReceipt[0]?.RefNo,
          Description: getReceipt[0]?.Description,
          ApprovalStatus: status,
          Attachment: fileDoc,
          PaymentMethodID: getReceipt[0]?.PaymentMethodID,
          CreditCardID: getReceipt[0]?.CreditCardID,
          ChequeNo: getReceipt[0]?.ChequeNo,
          ChequeDate: getReceipt[0]?.ChequeDate,
          DocAmt: getReceipt[0]?.DocAmt,
          RejectionRemark: data?.RejectionRemark,
        },
        itemInput: ReceiptItem?.map(y => {
          return {
            MasterCOAID: y?.MasterCOAID,
            Amount: y?.Amount,
            TaxSchemeID: y?.TaxSchemeID,
            TaxRate: y?.TaxRate,
            TaxAmt: y?.TaxAmt,
            DocAmt: y?.DocAmt,
            CostCentreID: y?.CostCentreID,
            Remark: y?.Remark,
          }
        }),
      },
      refetchQueries: [
        {
          query: GetReceiptDocument,
        },
        {
          query: DocumentListingDocument,
          variables: {
            refTable: 'S_Attachment',
            refID: ReceiptID,
          },
        },
      ],
    })
  }

  const rejectFooterOption = {
    name: 'Reject',
    onClick: () => {
      {
        setOpenRejectDialog(true)
        // handleSubmit(data => onSubmit(ApprovalStatus.Rejected, 'reject'))()
      }
    },
    color: 'secondary',

    props: {
      type: 'submit',
    },
  }

  const approveFooterOption = {
    name: 'Approve',
    onClick: () => {
      handleSubmit(data => onSubmit(ApprovalStatus.Approved, 'APPROVED'))()
    },
    color: 'primary',

    props: {
      type: 'submit',
    },
  }
  const footerOptions: any[] = [rejectFooterOption, approveFooterOption]

  //To calculate Total Amount of Payment
  let totalTaxAmt = getReceipt[0]?.ReceiptItem?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  return (
    <>
      {updateLoading && <Loading />}
      {JournalLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          mode === 'workdesk'
            ? history.push(`/workdesk/cash-book`)
            : history.push(
                `/cash-book/${CompanyID}/submenu/${BankAccountID}/Receipt`
              )
        }}
        smTitle="Cash Book"
        title={user?.companyName}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Receipt' },
          { name: 'Receipt', current: true },
        ]}
        rightRouteSegments={[{ name: 'Approved', current: true }]}
      />

      <DynamicSubHeader
        title={
          <span>
            {/* {formatDate(getReceipt[0]?.createdTs)}&nbsp;&nbsp;&nbsp; */}
            <span style={{ fontWeight: 'bold' }}>
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </span>
        }
        // rightText={
        //   <>
        //     <span>
        //       {/* <img
        //       src={BlueDollarIcon}
        //       style={{
        //         width: '12px',
        //         marginBottom: '-2px',
        //         font: 'xxTitle highlight-text',
        //       }}
        //     /> */}
        //       &nbsp;
        //       {/* {amtStr(getReceipt[0]?.DocAmt)} */}
        //       {getBankAccount[0]?.AccountNo}
        //     </span>
        //   </>
        // }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
        // rightInfoLine={
        //   <span className="desc flex-space">
        //     <img
        //       src={ApprovalLog}
        //       style={{
        //         width: '12px',
        //         marginBottom: '-2px',
        //         marginRight: '3px',
        //       }}
        //       onClick={e => {
        //         setWorkFlow(true)
        //         handleClick(e, getReceipt[0]?.WorkFlow)
        //       }}
        //     />
        //     <span
        //       className="desc flex-space"
        //       style={{
        //         color: handleStatusColor(getReceipt[0]?.ApprovalStatus),
        //         fontWeight: 'bold',
        //         marginTop: '-10px',
        //       }}
        //     >
        //       {RecordStatusText(getReceipt[0]?.ApprovalStatus)}
        //     </span>
        //   </span>
        // }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <ReceiptDetailContent
            listEl={getReceipt[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
            bankAccount={getBankAccount[0]}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Receipt Detail',
            },
          }}
        >
          <ReceiptDetailItemContent listItem={getReceipt[0]?.ReceiptItem} />
        </CardContents>
      </ContentWrapper>

      <RejectDialog
        data={getReceipt[0]}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        audioInput={audioInput}
        record={record}
        setRecord={setRecord}
        setValue={setValue}
        errors={errors}
        register={register}
        control={control}
        clearErrors={clearErrors}
        setText={setText}
        handleSingleRemark={handleSingleRemark}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />

      {mode === 'workdesk' ? <Footer options={[...footerOptions]} /> : ''}
      <ApprovalLogDialog
        data={getReceipt[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Receipt'}
      />

      {/* <TotalAmountFooter docAmt={getReceipt[0]?.DocAmt} detail={true} /> */}

      <TooltipAmountFooter
        data={editData}
        module={`CB-receipt-approved`}
        detail={true}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

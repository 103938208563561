import templateRoutes from 'components/Template/RoutesTemplate'
import accessSecurityRoutes from 'containers/AccessSecurityModule/AccessSecurityRoutes'
import APRoutes from 'containers/APModule/APRoutes'
import ARRoutes from 'containers/ARModule/ARRoutes'
import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes'
import cashBookRoutes from 'containers/CashBookModule/CashBookRoutes'
import companyRoutes from 'containers/CompanyModule/CompanyRoutes'
import digitalReportRoutes from 'containers/DigitalReportModule/DigitalReportRoutes'
import generalLedgerRoutes from 'containers/GeneralLedgerModule/GeneralLedgerRoutes'
import generalPurchasesRoutes from 'containers/GeneralPurchaseModule/GeneralPurchaseRoutes'
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes'
import homeRoutes from 'containers/HomeModule/HomeRoutes'
import inventoryControlRoute from 'containers/InventoryControlModule/InventoryControlRoute'
import React from 'react'
import { Redirect } from 'react-router-dom'

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

// errorRoute followed by redirectRoute always at the bottom//
const routes = [
  ...authenticationRoutes,
  ...homeRoutes,
  ...accessSecurityRoutes,
  ...generalSettingRoutes,
  ...generalPurchasesRoutes,
  ...cashBookRoutes,
  ...generalLedgerRoutes,
  ...companyRoutes,
  ...digitalReportRoutes,
  // ...ARAPRoutes,
  ...APRoutes,
  ...ARRoutes,
  ...inventoryControlRoute,
  ...templateRoutes,
  ...redirectRoute,
  ...errorRoute,
]

export default routes

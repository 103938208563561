import {
  AcctPermission,
  useGetArAdvancebyStatusLazyQuery,
  useGetArCreditNotebyStatusLazyQuery,
  useGetArDebitNotebyStatusLazyQuery,
  useGetArInvoicebyStatusLazyQuery,
  useGetArOfficialReceiptbyStatusLazyQuery,
  useGetArRefundbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'

export const useARListingData: any = ({
  accountType,
  arSubmenu,
  CompanyID,
  setOriginalListing,
  setErrorDia,
  setErrMsg,
}) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let listStatus = getSearch?.option ?? 'ACTIVE'

  //----------AR Queries----------//
  const {
    loading: getUsersLoading,
    error: getUsersError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArAdvance,
    {
      loading: ArAdvancebyStatusLoading,
      called: ArAdvanceCalled,
      data: { getARAdvancebyStatus } = {
        getARAdvancebyStatus: [],
      },
    },
  ] = useGetArAdvancebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARAdvancebyStatus }) => {
      if (getARAdvancebyStatus?.length > 0) {
        setOriginalListing(getARAdvancebyStatus)
      } else {
        setOriginalListing([])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArInvoice,
    {
      loading: ArInvoicebyStatusLoading,
      called: ArInvoiceCalled,
      data: { getARInvoicebyStatus } = {
        getARInvoicebyStatus: [],
      },
    },
  ] = useGetArInvoicebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARInvoicebyStatus }) => {
      if (getARInvoicebyStatus?.length > 0) {
        setOriginalListing(getARInvoicebyStatus)
      } else {
        setOriginalListing([])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArRefund,
    {
      loading: ArRefundbyStatusLoading,
      called: ArRefundCalled,
      data: { getARRefundbyStatus } = {
        getARRefundbyStatus: [],
      },
    },
  ] = useGetArRefundbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARRefundbyStatus }) => {
      if (getARRefundbyStatus?.length > 0) {
        setOriginalListing(getARRefundbyStatus)
      } else {
        setOriginalListing([])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })
  const [
    loadArCreditNote,
    {
      loading: ArCreditNotebyStatusLoading,
      called: ArCreditNoteCalled,
      data: { getARCreditNotebyStatus } = {
        getARCreditNotebyStatus: [],
      },
    },
  ] = useGetArCreditNotebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARCreditNotebyStatus }) => {
      if (getARCreditNotebyStatus?.length > 0) {
        setOriginalListing(getARCreditNotebyStatus)
      } else {
        setOriginalListing([])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArDebitNote,
    {
      loading: ArDebitNotebyStatusLoading,
      called: ArDebitNoteCalled,
      data: { getARDebitNotebyStatus } = {
        getARDebitNotebyStatus: [],
      },
    },
  ] = useGetArDebitNotebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getARDebitNotebyStatus }) => {
      if (getARDebitNotebyStatus?.length > 0) {
        setOriginalListing(getARDebitNotebyStatus)
      } else {
        setOriginalListing([])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  const [
    loadArReceipt,
    {
      loading: ArReceiptbyStatusLoading,
      called: ArReceiptCalled,
      data: { getAROfficialReceiptbyStatus } = {
        getAROfficialReceiptbyStatus: [],
      },
    },
  ] = useGetArOfficialReceiptbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAROfficialReceiptbyStatus }) => {
      if (getAROfficialReceiptbyStatus?.length > 0) {
        setOriginalListing(getAROfficialReceiptbyStatus)
      } else {
        setOriginalListing([])
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrorDia(true)
    },
  })

  let loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList = getUsersByAccountAndSoftware

  switch (arSubmenu) {
    case 'advance':
      loading = ArAdvancebyStatusLoading
      called = ArAdvanceCalled
      listData = getARAdvancebyStatus
      subMenuName = 'Advance'
      primaryKey = 'AdvanceID'
      createPermissionCheck = AcctPermission.AccReceivableAdvancesCreate
      updatePermissionCheck = AcctPermission.AccReceivableAdvancesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableAdvancesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableAdvancesApproveReject
      break
    case 'invoice':
      loading = ArInvoicebyStatusLoading
      called = ArInvoiceCalled
      listData = getARInvoicebyStatus
      subMenuName = 'Invoice'
      primaryKey = 'InvoiceID'
      createPermissionCheck = AcctPermission.AccReceivableInvoicesCreate
      updatePermissionCheck = AcctPermission.AccReceivableInvoicesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableInvoicesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableInvoicesApproveReject
      break
    case 'refund':
      loading = ArRefundbyStatusLoading
      called = ArRefundCalled
      listData = getARRefundbyStatus
      subMenuName = 'Refund'
      primaryKey = 'RefundID'
      createPermissionCheck = AcctPermission.AccReceivableRefundCreate
      updatePermissionCheck = AcctPermission.AccReceivableRefundUpdate
      deletePermissionCheck = AcctPermission.AccReceivableRefundDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableRefundApproveReject
      break
    case 'debit-note':
      loading = ArDebitNotebyStatusLoading
      called = ArDebitNoteCalled
      listData = getARDebitNotebyStatus
      subMenuName = 'DebitNote'
      primaryKey = 'DebitNoteID'
      createPermissionCheck = AcctPermission.AccReceivableDebitNotesCreate
      updatePermissionCheck = AcctPermission.AccReceivableDebitNotesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableDebitNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableDebitNotesApproveReject
      break
    case 'credit-note':
      loading = ArCreditNotebyStatusLoading
      called = ArCreditNoteCalled
      listData = getARCreditNotebyStatus
      subMenuName = 'CreditNote'
      primaryKey = 'CreditNoteID'
      createPermissionCheck = AcctPermission.AccReceivableCreditNotesCreate
      updatePermissionCheck = AcctPermission.AccReceivableCreditNotesUpdate
      deletePermissionCheck = AcctPermission.AccReceivableCreditNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableCreditNotesApproveReject
      break
    case 'official-receipt':
      loading = ArReceiptbyStatusLoading
      called = ArReceiptCalled
      listData = getAROfficialReceiptbyStatus
      subMenuName = 'Receipts'
      primaryKey = 'ReceiptsID'
      createPermissionCheck = AcctPermission.AccReceivableReceiptsCreate
      updatePermissionCheck = AcctPermission.AccReceivableReceiptsUpdate
      deletePermissionCheck = AcctPermission.AccReceivableReceiptsDelete
      approveRejectPermissionCheck =
        AcctPermission.AccReceivableReceiptsApproveReject
      break
  }

  const loadData = () => {
    switch (arSubmenu) {
      case 'advance':
        loadArAdvance({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'invoice':
        loadArInvoice({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'refund':
        loadArRefund({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'debit-note':
        loadArDebitNote({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'credit-note':
        loadArCreditNote({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'official-receipt':
        loadArReceipt({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
    }
  }

  useEffect(() => {
    loadData()
  }, [arSubmenu, listStatus])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    userList,
    loadData,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
  }
}

import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { Grid, TextField } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { useGetAccountPeriodQuery } from 'generated/graphql'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

interface GLPeriodBudgetProps {
  BudgetAmount: number
  PeriodBudgetAmountArr: any
  Amount: number
}

export const GLPeriodBudget = (props: any) => {
  let history = useHistory()
  const { CompanyID, MasterCOAID, budgetType }: any = useParams()
  const { mode } = props
  let location = useLocation()
  const displayData: any = location?.state
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let PeriodBudgetItem = JSON.parse(localStorage.getItem('PeriodBudgetItem'))
  let CostCentreBudgetItem = JSON.parse(
    localStorage.getItem('CostCentreBudgetItem')
  )
  const [openExitConf, setOpenExitConf] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<GLPeriodBudgetProps>({
    defaultValues: {
      PeriodBudgetAmountArr: {},
    },
    mode: 'onSubmit',
  })

  const {
    loading: AccountPeriodLoading,
    error: AccountPeriodError,
    data: { getAccountPeriod: getAccountPeriod } = { getAccountPeriod: [] },
  } = useGetAccountPeriodQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      FYear: displayData?.MasterCOAData?.selectedYear,
      orderByAsc: 'FPeriod',
    },
  })

  const percentageCheck = () => {
    if (watch(`PeriodBudgetAmountArr`) !== undefined) {
      const TotalAmountCalculated = getAccountPeriod
        ?.map(x => {
          return {
            AmountPercentage:
              displayData?.CostCentreBudgetAmount === null ||
              displayData?.CostCentreBudgetAmount === undefined ||
              displayData?.CostCentreBudgetAmount === ''
                ? 1
                : Number(
                    amtNumStr(
                      watch(
                        `PeriodBudgetAmountArr[${x?.AccountPeriodID}].Amount`
                      )
                    )
                  ),
          }
        })
        ?.reduce(
          (total, currentValue) =>
            (total = total + currentValue.AmountPercentage),
          0
        )
        .toFixed(2)

      const costCentreAmount = parseFloat(
        displayData?.CostCentreBudgetAmount.replace(/,/g, '')
      )

      if (parseFloat(TotalAmountCalculated) === costCentreAmount) {
        return 0
      } else if (parseFloat(TotalAmountCalculated) < costCentreAmount) {
        return 1
      } else if (parseFloat(TotalAmountCalculated) > costCentreAmount) {
        return 2
      }
    }
  }

  const TotalAmt = () => {
    if (watch(`PeriodBudgetAmountArr`) !== undefined) {
      const TotalAmountCalculated = getAccountPeriod
        ?.map(x => {
          return {
            AmountPercentage:
              displayData?.CostCentreBudgetAmount === null ||
              displayData?.CostCentreBudgetAmount === undefined ||
              displayData?.CostCentreBudgetAmount === ''
                ? 1
                : Number(
                    amtNumStr(
                      watch(
                        `PeriodBudgetAmountArr[${x?.AccountPeriodID}].Amount`
                      )
                    )
                  ),
          }
        })
        ?.reduce(
          (total, currentValue) =>
            (total = total + currentValue.AmountPercentage),
          0
        )
        .toFixed(2)

      return TotalAmountCalculated
    }
  }

  const PassedData = () => {
    let PeriodBudgetItemData = []
    const currentPagePIDs = PeriodBudgetItem?.filter(
      x =>
        x?.MasterCOAID === displayData?.MasterCOAData?.ID &&
        x?.CostCentreID === displayData?.CostCentreData?.CostCentreID
    )?.map(el => el?.BudgetItemPeriodID)

    const filterPeriodBudgetItem = PeriodBudgetItem?.filter(
      x => !currentPagePIDs.includes(x?.BudgetItemPeriodID)
    )

    if (filterPeriodBudgetItem !== undefined) {
      PeriodBudgetItemData.push(...filterPeriodBudgetItem)
    }

    PeriodBudgetItemData.push(
      ...getAccountPeriod?.map(x => {
        return {
          BudgetItemID: CostCentreBudgetItem?.find(
            el =>
              el?.MasterCOAID === displayData?.MasterCOAData?.ID &&
              el?.CostCentreID === displayData?.CostCentreData?.CostCentreID
          )?.BudgetItemID,
          BudgetItemCostCentreID: CostCentreBudgetItem?.find(
            el =>
              el?.MasterCOAID === displayData?.MasterCOAData?.ID &&
              el?.CostCentreID === displayData?.CostCentreData?.CostCentreID
          )?.BudgetItemCostCentreID,
          BudgetItemPeriodID: !!PeriodBudgetItem?.find(
            el =>
              el?.MasterCOAID === displayData?.MasterCOAData?.ID &&
              el?.CostCentreID === displayData?.CostCentreData?.CostCentreID &&
              el?.AccountPeriodID === x?.AccountPeriodID
          )
            ? PeriodBudgetItem?.find(
                el =>
                  el?.MasterCOAID === displayData?.MasterCOAData?.ID &&
                  el?.CostCentreID ===
                    displayData?.CostCentreData?.CostCentreID &&
                  el?.AccountPeriodID === x?.AccountPeriodID
              )?.BudgetItemPeriodID
            : uuidv4(),
          MasterCOAID: displayData?.MasterCOAData?.ID,
          Code: displayData?.MasterCOAData?.code,
          BudgetAmount: displayData?.MasterCOAData?.budgetAmount,
          CostCentreID: displayData?.CostCentreData?.CostCentreID,
          CostCentreName: displayData?.CostCentreData?.Name,
          CostCentreBudgetAmount: displayData?.CostCentreBudgetAmount,
          AccountPeriodID: x?.AccountPeriodID,
          PeriodBudgetAmount:
            getValues(`PeriodBudgetAmountArr[${x?.AccountPeriodID}].Amount`) ===
            undefined
              ? PeriodBudgetItem?.find(
                  v =>
                    v?.AccountPeriodID === x?.AccountPeriodID &&
                    v?.MasterCOAID === displayData?.MasterCOAData?.ID &&
                    v?.CostCentreID ===
                      displayData?.CostCentreData?.CostCentreID
                )?.PeriodBudgetAmount
              : getValues(
                  `PeriodBudgetAmountArr[${x?.AccountPeriodID}].Amount`
                ),
          Period: x?.FPeriod,
        }
      })
    )
    return PeriodBudgetItemData?.filter(x => !!x?.PeriodBudgetAmount)
  }

  const lastFillItem = () => {
    const allItem = getAccountPeriod?.map(x => {
      return {
        AccountPeriodID: x?.AccountPeriodID,
        Amount: watch(`PeriodBudgetAmountArr[${x?.AccountPeriodID}].Amount`),
      }
    })

    const unfillItemID = allItem
      ?.filter(
        v => v?.Amount === null || v?.Amount === '' || v?.Amount === '0.00'
      )
      ?.map(x => x?.AccountPeriodID)

    const fillItem = allItem?.filter(
      el => !unfillItemID.includes(el?.AccountPeriodID)
    )

    const lastItemFillID = fillItem[fillItem?.length - 1]?.AccountPeriodID
    return lastItemFillID
  }

  const checkPeriodSameAsPrev = () => {
    const prev = JSON.stringify(
      displayData?.PrevPItem?.filter(
        el =>
          el?.MasterCOAID === displayData?.MasterCOAData?.ID &&
          el?.CostCentreID === displayData?.CostCentreData?.CostCentreID
      )?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          AccountPeriodID: x?.AccountPeriodID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          PeriodBudgetAmount: x?.PeriodBudgetAmount,
        }
      })
    )
    const current = JSON.stringify(
      PeriodBudgetItem?.filter(
        el =>
          el?.MasterCOAID === displayData?.MasterCOAData?.ID &&
          el?.CostCentreID === displayData?.CostCentreData?.CostCentreID
      )?.map(x => {
        return {
          MasterCOAID: x?.MasterCOAID,
          CostCentreID: x?.CostCentreID,
          AccountPeriodID: x?.AccountPeriodID,
          CostCentreBudgetAmount: x?.CostCentreBudgetAmount,
          PeriodBudgetAmount: x?.PeriodBudgetAmount,
        }
      })
    )

    if (current === prev) {
      return true
    } else return false
  }

  return (
    <>
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (checkPeriodSameAsPrev() === true) {
            history.push({
              pathname: `/general-ledger/${CompanyID}/${budgetType}/${displayData?.MasterCOAData?.ID}/${mode}`,
              state: displayData?.MasterCOAData,
            })

            localStorage.setItem(
              'PeriodBudgetItem',
              JSON.stringify(displayData?.PrevPItem)
            )
          } else {
            setOpenExitConf(true)
          }
        }}
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Home Journal' },
          {
            name: 'Original Budget',
          },
          {
            name: 'Department Budget',
          },
          {
            name: 'Period Budget',
            current: true,
          },
        ]}
        rightRouteSegments={[
          {
            name: mode === 'add' ? 'New' : null,
            current: true,
          },
        ]}
      />

      <DynamicSubHeader
        title={
          <span className="highlight-text" style={{ fontWeight: 'bold' }}>
            {displayData?.MasterCOAData?.code} |{' '}
            {displayData?.ParentCostCentreData?.Name}
          </span>
        }
        infoLine={
          <span style={{ fontWeight: 'bold' }}>
            {displayData?.CostCentreData?.Name}
          </span>
        }
        rightText={
          <IconText
            icon={<EventIcon style={{ width: '14px', color: '#FF9800' }} />}
            children={
              <span style={{ color: '#FF9800' }}>
                {displayData?.MasterCOAData?.selectedYear}
              </span>
            }
          ></IconText>
        }
        rightInfoLine={
          <span style={{ color: '#FF9800' }}>
            <span style={{ fontSize: '13px' }}>
              {displayData?.CostCentreBudgetAmount === null ||
              displayData?.CostCentreBudgetAmount === undefined
                ? '0.00'
                : amtStr(
                    Number(amtNumStr(displayData?.CostCentreBudgetAmount))
                  )}
            </span>
          </span>
        }
      />
      {getAccountPeriod?.length === 0
        ? !AccountPeriodLoading && (
            <EmptyList
              title="No Account Period created"
              subtitle="Create New Account Period for this company."
            />
          )
        : !AccountPeriodLoading && (
            <ContentWrapper multiDynamicInfo style={{ paddingBottom: '130px' }}>
              {getAccountPeriod?.map((el, index) => {
                const PeriodBudgetAmountArr = `PeriodBudgetAmountArr[${el?.AccountPeriodID}]`
                if (
                  !watch(`PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`)
                ) {
                  register(
                    `PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`
                  )
                  setValue(
                    `PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`,
                    !!PeriodBudgetItem?.filter(
                      x =>
                        x?.MasterCOAID === displayData?.MasterCOAData?.ID &&
                        x?.CostCentreID ===
                          displayData?.CostCentreData?.CostCentreID
                    )?.find(x => x?.AccountPeriodID === el?.AccountPeriodID)
                      ? PeriodBudgetItem?.filter(
                          x =>
                            x?.MasterCOAID === displayData?.MasterCOAData?.ID &&
                            x?.CostCentreID ===
                              displayData?.CostCentreData?.CostCentreID
                        )?.find(x => x?.AccountPeriodID === el?.AccountPeriodID)
                          ?.PeriodBudgetAmount
                      : null
                  )
                }
                return (
                  <div>
                    <CardContents>
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          style={{
                            alignSelf: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#FF9800',
                              fontSize: 'small',
                              color: 'black',
                              borderRadius: '20px',
                              paddingTop: '1px',
                            }}
                          >
                            {index + 1}
                          </div>
                        </Grid>
                        <Grid item xs={9} style={{ paddingRight: '10px' }}>
                          <span>
                            <Controller
                              ref={register()}
                              name={`${PeriodBudgetAmountArr}.Amount`}
                              control={control}
                              onChangeName="onValueChange"
                              onValueChange={e => {
                                e.value === '' || e.value === '-'
                                  ? setValue(
                                      `${PeriodBudgetAmountArr}.Amount`,
                                      null
                                    )
                                  : setValue(
                                      `${PeriodBudgetAmountArr}.Amount`,
                                      e.value
                                    )
                                localStorage.setItem(
                                  'PeriodBudgetItem',
                                  JSON.stringify(PassedData())
                                )
                              }}
                              defaultValue={
                                !!PeriodBudgetItem?.filter(
                                  x =>
                                    x?.MasterCOAID ===
                                      displayData?.MasterCOAData?.ID &&
                                    x?.CostCentreID ===
                                      displayData?.CostCentreData?.CostCentreID
                                )?.find(
                                  x =>
                                    x?.AccountPeriodID === el?.AccountPeriodID
                                )
                                  ? PeriodBudgetItem?.filter(
                                      x =>
                                        x?.MasterCOAID ===
                                          displayData?.MasterCOAData?.ID &&
                                        x?.CostCentreID ===
                                          displayData?.CostCentreData
                                            ?.CostCentreID
                                    )?.find(
                                      x =>
                                        x?.AccountPeriodID ===
                                        el?.AccountPeriodID
                                    )?.PeriodBudgetAmount
                                  : null
                              }
                              helperText={
                                watch(`${PeriodBudgetAmountArr}.Amount`) ===
                                  null ||
                                watch(`${PeriodBudgetAmountArr}.Amount`) ===
                                  '' ||
                                watch(`${PeriodBudgetAmountArr}.Amount`) ===
                                  undefined
                                  ? null
                                  : lastFillItem() === el?.AccountPeriodID
                                  ? percentageCheck() === 2
                                    ? `Total amt more than budgeted`
                                    : percentageCheck() === 1
                                    ? `Total amt less than budgeted`
                                    : null
                                  : null
                              }
                              error={
                                watch(`${PeriodBudgetAmountArr}.Amount`) ===
                                  null ||
                                watch(`${PeriodBudgetAmountArr}.Amount`) ===
                                  '' ||
                                watch(`${PeriodBudgetAmountArr}.Amount`) ===
                                  undefined
                                  ? null
                                  : lastFillItem() === el?.AccountPeriodID
                                  ? percentageCheck()
                                  : null
                              }
                              disabled={
                                mode === 'approve-reject' || mode === 'detail'
                              }
                              as={
                                <NumberFormat
                                  allowNegative={true}
                                  customInput={TextField}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  variant="outlined"
                                  className="qty-input-new-wid"
                                  autoComplete="off"
                                  thousandSeparator
                                  isNumericString
                                  margin="dense"
                                  width="full"
                                  style={{ textAlignLast: 'end' }}
                                />
                              }
                            />
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'small',
                            color: 'grey',
                          }}
                        >
                          <span>
                            {(
                              (displayData?.CostCentreBudgetAmount === null ||
                              displayData?.CostCentreBudgetAmount ===
                                undefined ||
                              displayData?.CostCentreBudgetAmount === ''
                                ? watch(
                                    `PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`
                                  ) === null ||
                                  watch(
                                    `PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`
                                  ) === ''
                                  ? 0
                                  : Number(
                                      watch(
                                        `PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`
                                      )
                                    )
                                : Number(
                                    amtNumStr(
                                      watch(
                                        `PeriodBudgetAmountArr[${el?.AccountPeriodID}].Amount`
                                      )
                                    )
                                  ) /
                                  Number(
                                    amtNumStr(
                                      displayData?.CostCentreBudgetAmount
                                    )
                                  )) * 100
                            ).toFixed(2)}
                            %
                          </span>
                        </Grid>
                      </Grid>
                    </CardContents>
                  </div>
                )
              })}
            </ContentWrapper>
          )}
      {mode === 'approve-reject' || mode === 'detail' ? null : (
        <Footer
          options={[
            {
              name: 'Save',
              onClick: () => {
                if (percentageCheck() === 0) {
                  history.push({
                    pathname: `/general-ledger/${CompanyID}/${budgetType}/${displayData?.MasterCOAData?.ID}/${mode}`,
                    state: displayData?.MasterCOAData,
                  })
                }
              },
              color: 'primary',
            },
          ]}
        />
      )}
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push({
            pathname: `/general-ledger/${CompanyID}/${budgetType}/${displayData?.MasterCOAData?.ID}/${mode}`,
            state: displayData?.MasterCOAData,
          })

          localStorage.setItem(
            'PeriodBudgetItem',
            JSON.stringify(displayData?.PrevPItem)
          )
        }}
      />

      <TotalAmountFooter docAmt={TotalAmt()} taxInfo={false} />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetCreditorAccountCompanyAssignmentQuery,
  useGetCreditorTypeQuery,
} from 'generated/graphql'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import React, { useState } from 'react'

interface APAdvanceLedgerParamsProps {
  StartDocDate: Date
  EndDocDate: Date
  StartTrxnDate: Date
  EndTrxnDate: Date
  CreditorAccountID: string
  CreditorTypeID: string
}

export const APAdvanceLedgerParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({})
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<APAdvanceLedgerParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS

  const {
    loading: CreditorTypeLoading,
    error: CreditorTypeErrors,
    data: { getCreditorType } = { getCreditorType: [] },
  } = useGetCreditorTypeQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: CreditorAccountLoading,
    error: CreditorAccountError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentQuery({
    fetchPolicy: 'network-only',

    variables: {
      CompanyID: CompanyID,
    },
  })

  let getCreditorAccount = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  const [selectedCreditAccount, setSelectedCreditAccount]: any = useState(
    new Set()
  )

  const handleCheckboxCreditorAccountChange = (event: any) => {
    let creditorAccount = selectedCreditAccount

    if (event?.target?.checked) {
      creditorAccount.add(event?.target?.value)
    } else {
      creditorAccount.delete(event?.target?.value)
    }
    setSelectedCreditAccount(new Set(creditorAccount))
  }

  const [selectedType, setSelectedType] = useState(new Set())

  const handleCheckboxCreditorTypeChange = (event: any) => {
    let creditorType = selectedType
    if (event?.target?.checked) {
      creditorType.add(event?.target?.value)
    } else {
      creditorType.delete(event?.target?.value)
    }
    setSelectedType(new Set(creditorType))
  }

  const onSubmit = data => {
    history.push({
      pathname: `/account-payable/${CompanyID}/digital-report/ap-advance-ledger/generated`,
      state: {
        StartDocDate: data.StartDocDate ? new Date(data.StartDocDate) : null,
        EndDocDate: data.EndDocDate ? new Date(data.EndDocDate) : null,
        StartTrxnDate: data.StartTrxnDate ? new Date(data.StartTrxnDate) : null,
        EndTrxnDate: data.EndTrxnDate ? new Date(data.EndTrxnDate) : null,
        CreditorTypeID: selectedType ? Array.from(selectedType) : undefined,
        CreditorAccountID: selectedCreditAccount
          ? Array.from(selectedCreditAccount)
          : undefined,
      },
    })
  }

  return (
    <>
      {CreditorAccountLoading && <Loading />}
      {CreditorTypeLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/account-payable/${CompanyID}/digital-report`)
        }
        smTitle={'Accounts Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Advance Ledger', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartDocDate"
              label="Doc Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDocDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDocDate ?? new Date()}
              helperText={errors?.StartDocDate?.message}
              error={errors?.StartDocDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndDocDate"
              label="Doc End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('EndDocDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDocDate ?? new Date()}
              helperText={errors?.EndDocDate?.message}
              error={errors?.EndDocDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartTrxnDate"
              label="Txn Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartTrxnDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              defaultValue={editData?.StartTrxnDate ?? null}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              helperText={errors?.StartTrxnDate?.message}
              error={errors?.StartTrxnDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndTrxnDate"
              label="Txn End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('EndTrxnDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              defaultValue={editData?.EndTrxnDate ?? null}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              helperText={errors?.EndTrxnDate?.message}
              error={errors?.EndTrxnDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getCreditorAccount || []}
                  getOptionLabel={option => option?.CompanyName}
                  fullWidth
                  disableCloseOnSelect
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.CreditorAccountID)
                    })

                    setSelectedCreditAccount(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CreditorAccountID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxCreditorAccountChange(event)
                        }}
                      />
                      {option?.CompanyName}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Creditor Account"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CreditorAccountID"
            label="Creditor Account"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  options={getCreditorType || []}
                  getOptionLabel={option =>
                    `${option?.CreditorType}=>${option?.Description}`
                  }
                  fullWidth
                  disableCloseOnSelect
                  onChange={(value, newValue: any) => {
                    const selecteted = new Set()

                    newValue?.map(x => {
                      selecteted.add(x?.CreditorTypeID)
                    })

                    setSelectedType(new Set(selecteted))
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CreditorTypeID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxCreditorTypeChange(event)
                        }}
                      />
                      {`${option?.CreditorType}=>${option?.Description}`}
                    </React.Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Creditor Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CreditorTypeID"
            label="Creditor Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}

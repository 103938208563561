import React, { useContext, useState } from 'react'
import theme from '@ifca-root/react-component/src/assets/theme'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useGetTaxSchemeQuery,
  useGetCheckingValidationDebtorAccountQuery,
  useCreateDebtorAccountImportMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { DebtorAccountTemplateForm } from './DebtorAccountTemplateForm'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const DebtorAccountExcelValidation = (props: any) => {
  const { mode } = props
  let location = useLocation()
  const passedData: any = location?.state
  const excelData = passedData.excelData
  const fileName = passedData.fileTitle
  let history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const { accType }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [validatedData, setValidatedData] = useState<any>({
    ErrorData: [],
    ValidData: [],
  })
  const [openDialog, setOpenDialog] = useState(false)
  const [errorNumberingStructure, setErrorNumberingStructure] = useState(false)

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: CheckingValidationDebtorAccountLoading,
    data: { getCheckingValidationDebtorAccount } = {
      getCheckingValidationDebtorAccount: [],
    },
  } = useGetCheckingValidationDebtorAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      DebtorAccountImportInput: passedData.excelData,
      CompanyID: CompanyID,
    },
    onCompleted: ({ getCheckingValidationDebtorAccount }) => {
      let ErrorData: any = []
      let ValidData: any = []

      getCheckingValidationDebtorAccount?.map(x => {
        if (x['ErrorList']?.length > 0)
          ErrorData.push({
            DebtorType: x['DebtorType'],
            DebtorName: x['DebtorName'],
            CompanyRegNo: x['CompanyRegNo'],
            MobileNo: x['MobileNo'],
            OfficeNo: x['OfficeNo'],
            Email: x['Email'],
            CreditTerm: x['CreditTerm'],
            Tax: x['Tax'],
            GSTRegNo: x['GSTRegNo'],
            GSTStartDate: x['GSTStartDate'],
            Remark: x['Remark'],
            IsVIP: x['IsVIP'],
            IsCompany: x['IsCompany'],
            Address: x['Address'],
            Country: x['Country'],
            State: x['State'],
            City: x['City'],
            PostCode: x['PostCode'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
        else
          ValidData.push({
            DebtorType: x['DebtorType'],
            DebtorName: x['DebtorName'],
            CompanyRegNo: x['CompanyRegNo'],
            MobileNo: x['MobileNo'],
            OfficeNo: x['OfficeNo'],
            Email: x['Email'],
            CreditTerm: x['CreditTerm'],
            Tax: x['Tax'],
            GSTRegNo: x['GSTRegNo'],
            GSTStartDate: x['GSTStartDate'],
            Remark: x['Remark'],
            IsVIP: x['IsVIP'],
            IsCompany: x['IsCompany'],
            Address: x['Address'],
            Country: x['Country'],
            State: x['State'],
            City: x['City'],
            PostCode: x['PostCode'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
      })

      setValidatedData({ ErrorData: ErrorData, ValidData: ValidData })
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    createDebtorAccountImport,
    { loading: createDebtorLoading, error: createDebtorAccountImportError },
  ] = useCreateDebtorAccountImportMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      history.push({
        pathname: `/account-receivable/${CompanyID}/debtor-account/`,
        state: {
          success: true,
          msgMode: 'create',
        },
      })
    },
  })

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const {
    loading: taxLoading,
    error: taxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: { AccTaxClass: 'INPUT', orderByAsc: 'Code' },
    onCompleted: () => {},
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const createDebtorAccountExcel = data => {
    let stringifyData = JSON.stringify(data)

    createDebtorAccountImport({
      variables: { TemplateStr: stringifyData, CompanyID: CompanyID },
    })
  }

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      {taxLoading && <Loading />}
      {createDebtorLoading && <Loading />}
      {CheckingValidationDebtorAccountLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/account-receivable/${CompanyID}/debtor-account/`)
        }
        smTitle={'Debtor Account'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Debtor Account', current: true },
        ]}
      />

      <DynamicSubHeader
        style={{
          marginTop: '-2px',
        }}
        title={'Debtor Account Excel Upload'}
        rightText={<span className="c-orange">Validation</span>}
      />
      <List className="search-header fixed-search-header with-dynamic">
        <ListItem>
          <ListItemText
            primary={
              <>
                <span className="xsTitle flex-space">{fileName}</span>
              </>
            }
          />
        </ListItem>
      </List>

      <ContentWrapper
        style={{ marginTop: isDesktop ? '140px' : '75px' }}
        float
        footer
      >
        {/* Problem List */}
        {validatedData.ErrorData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Problem{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ErrorData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ErrorData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <div>
                            <span className="xsTitle flex-space ">
                              {el.DebtorName ?? '[No Debtor Name]'}{' '}
                            </span>
                          </div>

                          <div>
                            <span className="desc">{el.DebtorType ?? ''}</span>
                          </div>

                          <div>
                            <span className="desc">
                              {el.CompanyRegNo ?? ''}
                            </span>
                          </div>

                          <div>
                            <span className="desc">Is VIP: {el.IsVIP}</span>
                          </div>

                          <div>
                            <span className="desc">
                              Is Company: {el.IsCompany}
                            </span>
                          </div>

                          <div>
                            <span className="desc">
                              Mobile No: {el.MobileNo ?? ''}
                            </span>
                          </div>

                          <div>
                            <span className="desc">
                              Office No: {el.OfficeNo ?? ''}
                            </span>
                          </div>

                          {el?.Email && (
                            <div>
                              <span className="desc">{el.Email ?? ''}</span>
                            </div>
                          )}

                          {el?.GSTRegNo && (
                            <div>
                              <span className="desc">{el.GSTRegNo ?? ''}</span>
                            </div>
                          )}

                          {el?.GSTStartDate && (
                            <div>
                              <span className="desc">
                                {el.GSTStartDate ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.Remark && (
                            <div>
                              <span className="desc">{el.Remark ?? ''}</span>
                            </div>
                          )}

                          {el?.CreditTerm && (
                            <div>
                              <span className="desc">
                                Credit Terms: {el.CreditTerm ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.Tax && (
                            <div>
                              <span className="desc">Tax: {el.Tax ?? ''}</span>
                            </div>
                          )}

                          <div>
                            <span className="desc">
                              {el.Address}, {el.PostCode}, {el.City}, {el.State}
                              , {el.Country}
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    secondary={
                      <>
                        <div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            Row No: {el.RowNo}
                          </div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            {el.ErrorList?.map((x, index) => {
                              if (index == el.ErrorList?.length - 1)
                                return `${x}`
                              else return `${x}, `
                            })}
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {/* Validation List */}
        {validatedData.ValidData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Validated{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ValidData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ValidData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <div>
                            <span className="xsTitle flex-space ">
                              {el.DebtorName ?? '[No Debtor Name]'}{' '}
                            </span>
                          </div>

                          <div>
                            <span className="desc">{el.DebtorType ?? ''}</span>
                          </div>

                          <div>
                            <span className="desc">
                              {el.CompanyRegNo ?? ''}
                            </span>
                          </div>

                          <div>
                            <span className="desc">Is VIP: {el.IsVIP}</span>
                          </div>

                          <div>
                            <span className="desc">
                              Is Company: {el.IsCompany}
                            </span>
                          </div>

                          <div>
                            <span className="desc">
                              Mobile No: {el.MobileNo ?? ''}
                            </span>
                          </div>

                          <div>
                            <span className="desc">
                              Office No: {el.OfficeNo ?? ''}
                            </span>
                          </div>

                          {el?.Email && (
                            <div>
                              <span className="desc">{el.Email ?? ''}</span>
                            </div>
                          )}

                          {el?.GSTRegNo && (
                            <div>
                              <span className="desc">{el.GSTRegNo ?? ''}</span>
                            </div>
                          )}

                          {el?.GSTStartDate && (
                            <div>
                              <span className="desc">
                                {el.GSTStartDate ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.Remark && (
                            <div>
                              <span className="desc">{el.Remark ?? ''}</span>
                            </div>
                          )}

                          {el?.CreditTerm && (
                            <div>
                              <span className="desc">
                                Credit Terms: {el.CreditTerm ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.Tax && (
                            <div>
                              <span className="desc">Tax: {el.Tax ?? ''}</span>
                            </div>
                          )}

                          <div>
                            <span className="desc">
                              {el.Address}, {el.PostCode}, {el.City}, {el.State}
                              , {el.Country}
                            </span>
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </ContentWrapper>

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/account-receivable/${CompanyID}/debtor-account/excel-validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  DebtorType:
                    x?.DebtorType === null ? null : `${x?.DebtorType}`,
                  CompanyRegNo:
                    x?.CompanyRegNo === null ? null : `${x?.CompanyRegNo}`,
                  DebtorName:
                    x?.DebtorName === null ? null : `${x?.DebtorName}`,
                  MobileNo: x?.MobileNo === null ? null : `${x?.MobileNo}`,
                  OfficeNo: x?.OfficeNo === null ? null : `${x?.OfficeNo}`,
                  Email: x?.Email === null ? null : x?.Email?.text,
                  CreditTerm:
                    x?.CreditTerm === null ? null : `${x?.CreditTerm}`,
                  Tax: x?.Tax === null ? null : `${x?.Tax}`,
                  IsVIP: x?.IsVIP === null ? null : `${x?.IsVIP}`,
                  IsCompany: x?.IsCompany === null ? null : `${x?.IsCompany}`,
                  GSTRegNo: x?.GSTRegNo === null ? null : `${x?.GSTRegNo}`,
                  GSTStartDate:
                    x?.GSTStartDate === null ? null : `${x?.GSTStartDate}`,
                  Remark: x?.Remark === null ? null : `${x?.Remark}`,
                  Address: x?.Address === null ? null : `${x?.Address}`,
                  Country: x?.Country === null ? null : `${x?.Country}`,
                  State: x?.State === null ? null : `${x?.State}`,
                  City: x?.City === null ? null : `${x?.City}`,
                  PostCode: x?.PostCode === null ? null : `${x?.PostCode}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
          resetFileUpload()
          setFileTitle('')
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        downloadButton={true}
        onDownloadTemplate={() => {
          onDownloadTemplate({
            name: `Debtor Account Spreadsheet`,
            workSheetName: `Debtor Account List`,
            headerSetup: DebtorAccountTemplateForm,
            isHeaderLocked: true,
            defaultHeaderFillStyle: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            },
            defaultHeaderBorderStyle: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            extraListData: [
              {
                data: ['Yes', 'No'],
                worksheetName: 'IsVIP',
                dataType: 'IsVIP',
              },
              {
                data: ['Yes', 'No'],
                worksheetName: 'IsCompany',
                dataType: 'IsCompany',
              },
              {
                data: getTaxScheme?.map(x => x?.Code),
                worksheetName: 'Tax',
                dataType: 'Tax',
              },
            ],
          })
        }}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />

      <Footer
        options={
          validatedData.ErrorData?.length === 0 &&
          validatedData.ValidData?.length > 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true)
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    setOpenFileUpload(true)
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Upload Confirmation
                  </span>
                </div>
              </div>
            ),
          },
          body: () => (
            <div>
              <span>{'Are you sure to upload?'}</span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    createDebtorAccountExcel(getCheckingValidationDebtorAccount)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <CommonDialog
        fullWidth={true}
        open={errorNumberingStructure}
        onClose={() => {
          setErrorNumberingStructure(false)
        }}
        sections={{
          header: {
            dynamic: (
              // <div className="">
              //   <div className="dialog-dynamic-content">
              //     <span
              //       className="title c-orange flex-space"
              //       style={{
              //         fontSize: '13px',
              //         fontWeight: 'bold',
              //       }}
              //     >
              //       Error!
              //     </span>
              //   </div>
              // </div>

              <>
                <span className="xsTitle flex-space" style={{ color: 'red' }}>
                  Error!
                </span>
              </>
            ),
          },
          body: () => (
            <div>
              <span>
                {'Document Numbering for External Journal is not created.'}
              </span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setErrorNumberingStructure(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import {
  useGetJournalProcessingLazyQuery,
  useGetAuditAdjustmentLazyQuery,
  useGetRecurringJournalLazyQuery,
} from 'generated/graphql'
import { useEffect, useState } from 'react'

export const useGLQueries: any = ({
  accountType,
  docType,
  CompanyID,
  TransactionID,
}) => {
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  //----------GL Queries----------//
  const [
    fetchJournalProcessing,
    {
      loading: JournalProcessingLoading,
      called: JournalProcessingCalled,
      //error: JournalProcessingError,
      data: { getJournalProcessing } = { getJournalProcessing: [] },
    },
  ] = useGetJournalProcessingLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const [
    fetchRecurringJournal,
    {
      loading: RecurringJournalLoading,
      called: RecurringJournalCalled,
      // error: RecurringJournalError,
      data: { getRecurringJournal } = { getRecurringJournal: [] },
    },
  ] = useGetRecurringJournalLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const [
    fetchAuditAdjustment,
    {
      loading: AuditAdjustmentLoading,
      called: AuditAdjustmentCalled,
      // error: AuditAdjustmentError,
      data: { getAuditAdjustment } = { getAuditAdjustment: [] },
    },
  ] = useGetAuditAdjustmentLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  let loading, called, listData, subMenuName, primaryKey

  switch (docType) {
    case 'journal-processing':
      loading = JournalProcessingLoading
      called = JournalProcessingCalled
      listData = getJournalProcessing
      subMenuName = 'Journal Entries'
      primaryKey = 'JournalProcessingID'
      break

    case 'recurring-journal':
      loading = RecurringJournalLoading
      called = RecurringJournalCalled
      listData = getRecurringJournal
      subMenuName = 'Recurring Journal Entries'
      primaryKey = 'RecurringJournalID'
      break

    case 'audit-adjustment':
      loading = AuditAdjustmentLoading
      called = AuditAdjustmentCalled
      listData = getAuditAdjustment
      subMenuName = 'Audit Adjustment Entries'
      primaryKey = 'AuditAdjustmentID'
      break
  }

  useEffect(() => {
    switch (docType) {
      case 'journal-processing':
        fetchJournalProcessing({
          variables: { JournalProcessingID: TransactionID, CompanyID },
        })
        break

      case 'recurring-journal':
        fetchRecurringJournal({
          variables: { RecurringJournalID: TransactionID, CompanyID },
        })
        break

      case 'audit-adjustment':
        fetchAuditAdjustment({
          variables: { AuditAdjustmentID: TransactionID, CompanyID },
        })
        break
    }
  }, [docType])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    errMessage,
    errDialog,
    setErrDialog,
  }
}

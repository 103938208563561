import React, { lazy } from 'react'

const ICGeneralDetailContent = lazy(() =>
  import('../ICDetail/GeneralDetailContent').then(module => ({
    default: module.ICGeneralDetailContent,
  }))
)

export const icGeneralDetailRoutes = [
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-issue/:StockIssueID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-issue"
        routeSegments="Stock Issue"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-transfer/:StockTransferID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-transfer"
        routeSegments="Stock Transfer"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-adjustment/:StockAdjustmentID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-adjustment"
        routeSegments="Stock Qty Adjustment"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-receipt/:StockReceiptID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-receipt"
        routeSegments="Stock Receipt"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-receipt/:WarehouseID/:StockReceiptID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-receipt"
        routeSegments="Stock Receipt"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-cost-adjustment/:StockCostAdjustmentID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-cost-adjustment"
        routeSegments="Stock Cost Adjustment"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/inventory-control/:WarehouseID/stock-requisition/:StockRequisitionID/detail`,
    },
    component: (
      <ICGeneralDetailContent
        icSubmenu="stock-requisition"
        routeSegments="Stock Requisition"
      />
    ),
  },
]

export default icGeneralDetailRoutes

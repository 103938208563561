import doc from 'assets/icons/file.svg';
import pdf from 'assets/icons/pdf.svg';
import video from 'assets/images/video.png';
import React from 'react';

export const attachmentDetailView = (DocList: any, diffClass?: boolean) => {
  // to download file with correct file name
  const downloadFile = async (filename, fileurl) => {
    let response = await fetch(fileurl);
    let data = await response.blob();
    let file = new File([data], filename);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = `${filename}`;
    link.click();
  };
  return (
    <>
      <div className="preview-wrapper">
        {DocList?.length > 0 ? (
          DocList?.map(x => (
            <a href={x?.fileURL} target="_blank">
              {x?.mediaType.includes('image') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={{
                      width: '93px',
                      whiteSpace: 'initial',
                    }}
                  >
                    {x?.fileName.length > 10
                      ? x?.fileName.substring(0, 10) +
                        '.' +
                        x?.fileName.split('.').pop()
                      : x?.fileName}
                  </div>
                  <img
                    src={x?.fileURL}
                    className={'img-preview'}
                    loading="lazy"
                  />
                </>
              ) : x?.mediaType.includes('pdf') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={{
                      width: '93px',
                      whiteSpace: 'initial',
                    }}
                  >
                    {x?.fileName}
                    {/* {x?.fileName.length > 11
                    ? x?.fileName.substring(0, 11) +
                      '.' +
                      x?.fileName.split('.').pop()
                    : x?.fileName} */}
                  </div>
                  <img src={pdf} className="img-preview" loading="lazy" />
                </>
              ) : x?.mediaType.includes('video') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={{
                      width: '93px',
                      whiteSpace: 'initial',
                    }}
                  >
                    {x?.fileName.length > 11
                      ? x?.fileName.substring(0, 11) +
                        '.' +
                        x?.fileName.split('.').pop()
                      : x?.fileName}
                  </div>
                  <img src={video} className="img-preview" loading="lazy" />
                </>
              ) : x?.mediaType.includes('audio') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={{
                      width: '93px',
                      whiteSpace: 'initial',
                    }}
                  >
                    {x?.fileName}
                  </div>
                  <audio controls>
                    <source src={x?.fileURL} type="audio/webm" />
                  </audio>
                </>
              ) : (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={{
                      width: '93px',
                      whiteSpace: 'initial',
                    }}
                  >
                    {x?.fileName.length > 11
                      ? // x?.fileName.substring(0, 10) +
                        //   '...' +
                        //   x?.fileName.split('.').pop()
                        x?.fileName
                          .split('.')
                          .shift()
                          .substring(0, 11) + '...'
                      : x?.fileName}
                  </div>
                  <img
                    src={doc}
                    className="img-preview"
                    loading="lazy"
                    width="50"
                    height="50"
                  />
                </>
              )}
            </a>
          ))
        ) : (
          <div className="mdDesc">N/A</div>
        )}
      </div>
    </>
  );
};

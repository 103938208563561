import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { useMediaQuery } from '@material-ui/core'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetArOfficialReceiptbyStatusDocument,
  useDocumentListingQuery,
  useGetArOfficialReceiptQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostArOfficialReceiptMutation,
  UserSelection,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { AROfficialReceiptDetailContent } from './AROfficialReceiptDetailContent'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const AROfficialReceiptDetail = props => {
  useEffect(() => {
    console.log('AROfficialReceiptDetail')
  }, [])
  let history = useHistory()
  const { CompanyID, ReceiptID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu, handleClick: handleClick2 }: any = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  let location = useLocation()
  const editData = location?.state as any
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()

  const {
    loading: AROfficialReceiptLoading,
    error: AROfficialReceiptError,
    data: { getAROfficialReceipt } = {
      getAROfficialReceipt: [],
    },
  } = useGetArOfficialReceiptQuery({
    fetchPolicy: 'network-only',
    variables: {
      ReceiptID: ReceiptID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: ReceiptID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const statusText = jStatus => {
    switch (jStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  const [
    postAROfficialReceipt,
    {
      loading: postAdvanceLoading,
      called: postAdvanceCalled,
      error: postAdvanceError,
    },
  ] = usePostArOfficialReceiptMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/official-receipts`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postAROfficialReceipt({
      variables: {
        ReceiptIDs: ReceiptID,
        CompanyID,
        RefTable: 'AR_OfficialReceipt',
      },
      refetchQueries: [
        {
          query: GetArOfficialReceiptbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Completed, ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const allocationTotal = getAROfficialReceipt[0]?.Allocation?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.AllocationAmt,
    0
  )
  return (
    <>
      {postAdvanceLoading && <Loading />}
      {AROfficialReceiptLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Accounts Receivable'}
        title={`${user?.companyName}`}
        routeSegments={[
          { name: 'Accounts Receivable' },
          { name: 'AP Submenu' },
          { name: 'Official Receipt Posting', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getAROfficialReceipt[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getAROfficialReceipt[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {getAROfficialReceipt[0]?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={OrangeDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className="desc flex-space c-orange">
              {amtStr(getAROfficialReceipt[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <AROfficialReceiptDetailContent
            listEl={getAROfficialReceipt[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        {getAROfficialReceipt[0]?.Allocation?.length > 0 && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                  rightTitle: amtStr(allocationTotal),
                  customFontSizeClass: 'xsTitle',
                  dollarIcon: (
                    <img
                      src={OrangeDollarIcon}
                      style={{
                        width: '15px',
                        height: '15px',
                        marginBottom: '-2px',
                        marginRight: '3px',
                        marginLeft: '3px',
                      }}
                    />
                  ),
                },
              }}
            >
              <DetailAllocationTableContent
                listItem={getAROfficialReceipt[0]?.Allocation}
              />
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={getAROfficialReceipt[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Official Receipt'}
      />

      {getAROfficialReceipt[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

import React, { lazy } from 'react'

export const SCHEDULE = 'scheduled-purchase'
export const COMPANY_ID = ':CompanyID'
export const GENERAL_PURCHASE = 'general-purchase'
export const GP_SCHEDULE_PATH = `/${GENERAL_PURCHASE}/${COMPANY_ID}/${SCHEDULE}`

const ScheduledPurchasesListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchasesListing'
  ).then(module => ({
    default: module.ScheduledPurchasesListing,
  }))
)

const ScheduledPurchasesForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/SchedulePurchasesForm'
  ).then(module => ({
    default: module.ScheduledPurchasesForm,
  }))
)

const ScheduledPurchasesItemForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchasesItemForm'
  ).then(module => ({
    default: module.ScheduledPurchasesItemForm,
  }))
)

const ScheduledPurchasesPreparePOForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchasesPreparePOForm'
  ).then(module => ({
    default: module.ScheduledPurchasesPreparePOForm,
  }))
)

const ScheduledPurchasesVerifyRequest = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchasesVerifyRequest'
  ).then(module => ({
    default: module.ScheduledPurchasesVerifyRequest,
  }))
)

const ScheduledPurchaseListingReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchaseReport'
  ).then(module => ({
    default: module.ScheduledPurchaseListingReport,
  }))
)
const ScheduledPurchasesPurchaseRequest = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchasesPurchaseRequest'
  ).then(module => ({
    default: module.ScheduledPurchasesPurchaseRequest,
  }))
)

const ScheduledPurchaseUserAccessListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/ScheduledPurchases/ScheduledPurchaseUserAssignment/ScheduledPurchaseUserAccessListing'
  ).then(module => ({
    default: module.ScheduledPurchaseUserAccessListing,
  }))
)

const scheduledPurchasesRoutes = [
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Assign User - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/assign-user`,
    },
    component: <ScheduledPurchaseUserAccessListing />,
  },

  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Listing - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}`,
    },
    component: <ScheduledPurchasesListing />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Listing Report - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/scheduled-purchase-listing`,
    },
    component: <ScheduledPurchaseListingReport />,
  },
  // // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Purchase Request - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/purchase-request`,
    },
    component: <ScheduledPurchasesPurchaseRequest mode="view" />,
  },
  // // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Verify Request - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/verify-request`,
    },
    component: <ScheduledPurchasesVerifyRequest mode="view" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Prepare PO - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/prepare-po`,
    },
    component: <ScheduledPurchasesPreparePOForm mode="view" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'add'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'edit'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:formMode/sp-item/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem Edit & Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode/sp-item/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:formMode/sp-item/:ScheduledPurchasesItemID/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Edit && SPItem Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //

  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode/sp-item/:ScheduledPurchasesItemID/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Edit && SPItem has been edited - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'edit'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem has been edited - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'add'} />,
  },
]

export default scheduledPurchasesRoutes

import React from 'react'
import { APSubmenu } from '../APSubmenu'
import { CreditorAccountDetail } from './CreditorAccountDetail'
import { CreditorAccountForm } from './CreditorAccountForm'
import { CreditorAccountListing } from './CreditorAccountListing'
import { CreditorAccountTrxListing } from './CreditorAccountTrxListing'

export const creditorAccountRoutes = [
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-account`,
    },
    component: <CreditorAccountListing />,
  },

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/detail`,
    },
    component: <CreditorAccountDetail />,
  },

  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/edit',
    },
    component: <CreditorAccountForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-account/creditor-profile/add',
    },
    component: <CreditorAccountForm mode="add" />,
  },

  /* -------------------------------------------- */
  /*               CREDITOR SUBMENU               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID',
    },
    component: <APSubmenu />,
  },

  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/invoice',
    },
    component: <CreditorAccountTrxListing docType="invoice" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/credit-note',
    },
    component: <CreditorAccountTrxListing docType="credit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/creditor-credit-note',
    },
    component: <CreditorAccountTrxListing docType="creditor-credit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/debit-note',
    },
    component: <CreditorAccountTrxListing docType="debit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/payment',
    },
    component: <CreditorAccountTrxListing docType="payment" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/refund',
    },
    component: <CreditorAccountTrxListing docType="refund" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-account/creditor-profile/:CreditorAccountID/advance',
    },
    component: <CreditorAccountTrxListing docType="advance" />,
  },
]

export default creditorAccountRoutes

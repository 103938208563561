import { AttachFile } from '@material-ui/icons'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useHistory, useParams } from 'react-router'
import { default as React, useState } from 'react'
import PrintIcon from '@material-ui/icons/Visibility'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useDocumentListingQuery } from 'generated/graphql'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'

interface DetailProps {
  listEl: any
  documentListing?: any
  listStatus: string
  mode?: string
  userList: any
  bankAccount: any
}

export const BankTransferDetailContent = (props: DetailProps) => {
  const {
    listEl,
    documentListing,
    listStatus,
    userList,
    mode,
    bankAccount,
  } = props

  let history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: listEl?.BankTransferID,
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const [openDoc, setOpenDoc] = useState<boolean>(false)
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption()

  return (
    <>
      {documentLoading && <Loading />}
      {mode === 'detail' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Document Date
            </div>

            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {' '}
              {formatDate(listEl?.TransferDate)}{' '}
            </div>
          </div>

          <div className="content-wrap right">
            <span>
              <div className="desc">
                <span style={{ color: '#96938e' }}> Transaction Date </span>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    history.push({
                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-transfer/${listEl?.BankTransferID}/detail/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <PrintIcon
                    style={{
                      fontSize: 'medium',
                      padding: '0px 10px 2px 0px',
                    }}
                  />
                </span>
                {documentListing && documentListing?.length > 0 && (
                  <>
                    <span
                      style={{ float: 'right', color: '#000' }}
                      onClick={() => {
                        setOpenDoc(true)
                      }}
                    >
                      <AttachFile
                        className="mdDesc"
                        style={{
                          paddingTop: '3px',
                          fontSize: 12,
                          color: 'orange',
                        }}
                      />

                      <span
                        className="mdDesc"
                        style={{
                          color: 'orange',
                          textDecoration: 'underline',
                          paddingRight: '5px',
                        }}
                      >
                        {documentListing?.length}
                      </span>
                    </span>
                  </>
                )}
                <div className="desc" style={{ marginTop: '2px' }}>
                  {formatDate(listEl?.TransactionDate)}
                </div>
              </div>
            </span>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Document Number
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.DocNo}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.RefNo}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Payment Method
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.PaymentMethod?.Name}
            </div>
          </div>

          {bankAccount?.BankProfile.Name ===
          listEl?.ToBankAccountDetail?.BankName ? (
            <div className="content-wrap left">
              <div className="desc" style={{ color: 'grey' }}>
                Received From
              </div>
              <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
                {listEl?.FromBankAccountDetail?.BankName}
                <br></br>
                {listEl?.FromBankAccountDetail?.AccountNo}
              </div>
            </div>
          ) : (
            <div className="content-wrap left">
              <div className="desc" style={{ color: 'grey' }}>
                Transfer To
              </div>
              <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
                {listEl?.ToBankAccountDetail?.BankName}
                <br></br>
                {listEl?.ToBankAccountDetail?.AccountNo}
              </div>
            </div>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Amount
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {amtStr(listEl?.Amount)}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.Description}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID === listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Approved Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Approved By
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID === listEl?.approvedBy)[0]
                  ?.name
              }
            </div>
          </div>
        </>
      )}

      {mode === 'expansion' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Transaction Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap right">
            <span>
              <div className="desc">
                <span className="desc" style={{ color: 'grey' }}>
                  Reference No.
                </span>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    history.push({
                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-transfer/${listEl?.BankTransferID}/detail/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <PrintIcon
                    style={{
                      fontSize: 'medium',
                      padding: '0px 10px 2px 0px',
                    }}
                  />
                </span>
                {documentListing && documentListing?.length > 0 && (
                  <>
                    <span
                      style={{ float: 'right', color: '#000' }}
                      onClick={() => {
                        setOpenDoc(true)
                      }}
                    >
                      <AttachFile
                        className="mdDesc"
                        style={{
                          paddingTop: '3px',
                          fontSize: 12,
                          color: 'orange',
                        }}
                      />

                      <span
                        className="mdDesc"
                        style={{
                          float: 'right',
                          color: 'orange',
                          textDecoration: 'underline',
                          paddingRight: '5px',
                        }}
                      >
                        {documentListing?.length}
                      </span>
                    </span>
                  </>
                )}
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {listEl?.RefNo}
                </div>
              </div>
            </span>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Transfer To
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.ToBankAccountDetail?.BankName}
              <br></br>
              {listEl?.ToBankAccountDetail?.AccountNo}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID === listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>

          {listStatus === 'COMPLETED' && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Approved Date'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.approvedTs)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Approved By'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {
                    userList?.filter(user => user.ID === listEl?.approvedBy)[0]
                      ?.name
                  }
                </div>
              </div>
            </>
          )}
        </>
      )}

      <AttachmentDialog
        title="Bank Transfer"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        attachmentDetailView={attachmentDetailView}
        DocumentListing={DocumentListing}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}

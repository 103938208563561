import React, { lazy } from 'react'
import { ConversionRateListing } from './ConversionRateListing'
import { UploadValidationListing } from './UploadValidationListing'

const UOMListing = lazy(() =>
  import('./UOMListing').then(module => ({
    default: module.UOMListing,
  }))
)

// const UOMExchangeListing = lazy(() =>
//   import(
//     '@ifca-root//UOMExchange/UOMRateListing'
//   ).then(module => ({
//     default: module.UOMRateListing,
//   })),
// );

const uomRoutes = [
  {
    props: { exact: true, path: '/account-payable/general-setting/uom' },
    component: <UOMListing page="account-payable" />,
  },
  {
    props: { exact: true, path: '/inventory-control/general-setting/uom' },
    component: <UOMListing page="inventory-control" />,
  },
  {
    props: { exact: true, path: '/general-purchase/general-setting/uom' },
    component: <UOMListing page="general-purchase" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/general-setting/uom-conversion-rate',
    },
    component: <ConversionRateListing />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/general-settings/uom/validation',
    },
    component: <UploadValidationListing />,
  },
]

export default uomRoutes

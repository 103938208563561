import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import React from 'react';

export function DeleteDialog(props) {
  const { ID, openDialog, setOpenDialog, title, menu, handleDelete, action } =
    props;

  return (
    <CommonDialog
      fullWidth
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      sections={{
        header: {
          title: `${action}`,
          infoLine: menu?.RefNo ?? menu?.Remarks,
        },
        body: () => (
          <div className="content-wrap full">
            <span
              style={{
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: 500,
              }}
            >
              {`Are you sure you want to ${action} this ${title} item ?`}
            </span>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setOpenDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: () => handleDelete(ID ?? menu?.ID),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  );
}

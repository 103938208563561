import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const APCreditorControlDetailReport = () => {
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: CompanyID,
    },
    {
      name: 'creditoraccountid',
      value: editData?.CreditorAccountID,
    },
    {
      name: 'docstartdate',
      value: new Date(editData?.DocStartDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'docenddate',
      value: new Date(editData?.DocEndDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'trxstartdate',
      value: editData?.TrxStartDate
        ? formatDashDate(new Date(editData?.TrxStartDate).toISOString())
        : null,
    },
    {
      name: 'trxenddate',
      value: editData?.TrxEndDate
        ? formatDashDate(new Date(editData?.TrxEndDate).toISOString())
        : null,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() =>
          history.push(`/account-payable/${CompanyID}/digital-report`)
        }
        mainBtn="close"
        smTitle="Accounts Payable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Creditor Control Detail', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('CreditorControlDetailReport', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}

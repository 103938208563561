import { AttachFile } from '@material-ui/icons'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { default as React, useEffect } from 'react'
import '../APRefund.scss'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const APRefundDetailContent = (props: DetailProps) => {
  const { listEl, documentListing, listStatus, userList, mode } = props

  return (
    <>
      {/* {mode === 'expansion' && documentListing?.length > 0 && (
        <div
          className="content-wrap right"
          style={{ width: '10%', textAlign: 'center', alignSelf: 'end' }}
        >
          {documentListing && (
            <>
              <span
                onClick={() =>
                  documentListing?.forEach(doc => {
                    window.open(doc?.fileURL, '_blank')
                  })
                }
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <AttachFile
                  className="mdDesc"
                  style={{
                    fontSize: 12,
                    color: 'orange',
                  }}
                />
              </span>
              <span
                className="mdDesc"
                style={{ color: 'orange', textDecoration: 'underline' }}
              >
                {documentListing?.length}
              </span>
            </>
          )}
        </div>
      )} */}

      <div className="content-wrap left">
        <div className="desc" style={{ fontWeight: 300 }}>
          Refund Method
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {listEl?.PaymentMethod?.Name}
        </div>
      </div>

      <div className="content-wrap right" style={{ width: '40%' }}>
        <div className="desc" style={{ fontWeight: 300 }}>
          Reference No.
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {listEl?.RefNo}
        </div>
      </div>

      {mode === 'expansion' && documentListing?.length > 0 && (
        <div
          className="content-wrap right"
          style={{ width: '10%', textAlign: 'center', alignSelf: 'end' }}
        >
          {documentListing && (
            <>
              <span
                onClick={() =>
                  documentListing?.forEach(doc => {
                    window.open(doc?.fileURL, '_blank')
                  })
                }
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <AttachFile
                  className="mdDesc"
                  style={{
                    fontSize: 12,
                    color: 'orange',
                  }}
                />
              </span>
              <span
                className="mdDesc"
                style={{ color: 'orange', textDecoration: 'underline' }}
              >
                {documentListing?.length}
              </span>
            </>
          )}
        </div>
      )}

      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Bank Account No.
        </div>
        <div
          className="xsTitle"
          style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}
        >
          {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Address?.city})`}
        </div>
      </div>

      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Creditor Name
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {listEl?.CreditorAccount?.CompanyName}
        </div>
      </div>

      {/* <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Bank Account No
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Address?.city})`}
        </div>
      </div> */}

      {listEl?.PaymentMethod?.Name?.includes('Card') && (
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Card Type
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {listEl?.CreditCardType?.Name}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Cheque No.
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {listEl?.ChequeNo}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <div className="content-wrap left">
          <div className="desc" style={{ fontWeight: 300 }}>
            Cheque Date
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {formatDate(listEl?.ChequeDate)}
          </div>
        </div>
      )}

      {listEl?.PaymentMethod?.Name === 'Cheque' && (
        <div className="content-wrap right">
          <div className="desc" style={{ fontWeight: 300 }}>
            Cheque Expiry Date
          </div>
          <div className="xsTitle" style={{ fontWeight: 500 }}>
            {formatDate(listEl?.ChequeExpiryDate)}
          </div>
        </div>
      )}

      <div className="content-wrap full">
        <div className="desc" style={{ fontWeight: 300 }}>
          Description
        </div>
        <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
          {listEl?.Description ?? listEl?.Remark}
        </div>
      </div>

      {/* <div className="content-wrap left">
        <div className="desc" style={{ fontWeight: 300 }}>
          Amount
        </div>
        <div className="xsTitle" style={{ fontWeight: 500 }}>
          <img
            src={BlackDollarIcon}
            style={{
              width: '12px',
              marginBottom: '-2px',
              font: 'xxTitle highlight-text',
            }}
          />
          {amtStr(listEl?.Amount)}
        </div>
      </div> */}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Attachment
          </div>
          {attachmentDetailView(documentListing)}
        </div>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}

import { AttachFile } from '@material-ui/icons';
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView';
import { default as React, useState } from 'react';
import PrintIcon from '@material-ui/icons/Visibility';
import { useHistory, useParams } from 'react-router';
import '../GRTN.scss';
import { formatDate } from '../../../../helpers/StringNumberFunction/formatDate';
import { addressFormat } from '../../../../helpers/AddressHandler/AddressFormat';
import { useDocumentListingQuery } from 'generated/graphql';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import { AttachmentDialog } from '../../../../components/Dialog/AttachmentDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { ErrorDialog } from 'components/Dialog/ErrorDialog';

interface DetailProps {
  listEl: any;
  listStatus: string;
  userList: any;
  documentListing?: any;
  mode?: string;
}

export const GRTNDetailContent = (props: DetailProps) => {
  let history = useHistory();
  const { CompanyID }: any = useParams();

  const { listEl, documentListing, listStatus, userList, mode } = props;
  const [errMessage, setErrMessage] = useState(null);
  const [errDialog, setErrDialog] = useState(false);

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: listEl?.GRTNHeaderID },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  const [openDoc, setOpenDoc] = useState<boolean>(false);
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption();

  return (
    <>
      {documentLoading && <Loading />}
      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          Document Date
        </div>
        <div className="mdDesc"> {formatDate(listEl?.DocDate)} </div>
      </div>

      <div className="content-wrap right">
        <span
          style={{ float: 'right' }}
          onClick={() => {
            history.push({
              pathname: `/general-purchase/${CompanyID}/good-return-note/${listEl?.GRTNHeaderID}/detail/preview`,
              state: {
                ...listEl,
                mode: 'detail',
              },
            });
          }}
        >
          <PrintIcon
            style={{
              fontSize: 'medium',
              padding: '0px 10px 2px 6px',
            }}
          />
        </span>
        {DocumentListing && DocumentListing?.length > 0 && (
          <>
            <span
              className="mdDesc"
              style={{
                float: 'right',
                color: 'orange',
                textDecoration: 'underline',
              }}
            >
              {DocumentListing?.length}
            </span>
            <span
              style={{ float: 'right', color: '#000' }}
              onClick={e => {
                handleClick(e, listEl?.GRTNHeaderID, 0, listEl);
                setOpenDoc(true);
              }}
            >
              <AttachFile
                className="mdDesc"
                style={{
                  fontSize: 12,
                  color: 'orange',
                }}
              />
            </span>
          </>
        )}
      </div>

      <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          Transaction Date
        </div>
        <div className="mdDesc">{formatDate(listEl?.TransactionDate)}</div>
      </div>
      <div className="content-wrap">
        <div className="desc" style={{ color: 'grey' }}>
          Document No.
        </div>
        <div className="mdDesc">{listEl?.DocNo}</div>
      </div>
      <div className="content-wrap">
        <div className="desc" style={{ color: 'grey' }}>
          Do No.
        </div>
        <div className="mdDesc">{listEl?.RefNo}</div>
      </div>
      <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          Supplier
        </div>
        <div className="mdDesc">{listEl?.Supplier?.CompanyName}</div>
      </div>
      <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          Deliver To
        </div>
        <div className="mdDesc text-noflow">
          {listEl?.DeliveryLocation?.Name} -{' '}
          {addressFormat(listEl?.DeliveryLocation?.Address)}
        </div>
      </div>

      <div className="content-wrap full ">
        <div className="desc " style={{ color: 'grey' }}>
          Description
        </div>
        <div className="mdDesc text-noflow">
          {' '}
          {listEl?.Description ?? listEl?.Remark}{' '}
        </div>
      </div>

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Attachment
          </div>
          {attachmentDetailView(documentListing)}
        </div>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}

      <AttachmentDialog
        title="GRTN"
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        attachmentDetailView={attachmentDetailView}
        DocumentListing={DocumentListing}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  );
};

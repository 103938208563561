import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { useMediaQuery } from '@material-ui/core'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetApCreditNotebyStatusDocument,
  RecordStatus,
  useDocumentListingQuery,
  useGetApCreditNoteQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostApCreditNoteMutation,
  UserSelection,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { APCreditNoteDetailContent } from './APCreditNoteDetailContent'
import React, { useContext, useState } from 'react'

export const APCreditNoteDetail = props => {
  let history = useHistory()
  const { CompanyID, CreditNoteID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu, handleClick: handleClick2 }: any = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  let location = useLocation()
  const editData = location?.state as any
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()

  const {
    loading: APCreditNoteLoading,
    error: APCreditNoteError,
    data: { getAPCreditNote } = {
      getAPCreditNote: [],
    },
  } = useGetApCreditNoteQuery({
    fetchPolicy: 'network-only',
    variables: {
      CreditNoteID: CreditNoteID,
    },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: CreditNoteID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const [
    postAPCreditNote,
    {
      loading: postCreditNoteLoading,
      called: postCreditNoteCalled,
      error: postCreditNoteError,
    },
  ] = usePostApCreditNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/${CompanyID}/credit-note-posting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postAPCreditNote({
      variables: {
        CreditNoteIDs: CreditNoteID,
        CompanyID,
        RefTable: 'AP_CreditNote',
      },
      refetchQueries: [
        {
          query: GetApCreditNotebyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Completed, ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const allocationTotal = getAPCreditNote[0]?.Allocation?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.AllocationAmt,
    0
  )

  return (
    <>
      {postCreditNoteLoading && <Loading />}
      {APCreditNoteLoading && <Loading />}
      {/* {TaxLoading && <Loading />} */}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Accounts Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: 'AP Submenu' },
          { name: 'AP Submenu' },
          { name: 'Credit Note', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getAPCreditNote[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getAPCreditNote[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', width: '270px' }}
            >
              {getAPCreditNote[0]?.CreditorAccount?.CompanyName}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={BlackDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className="desc flex-space">
              {amtStr(getAPCreditNote[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <APCreditNoteDetailContent
            listEl={getAPCreditNote[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Allocation(s)',
              rightTitle: amtStr(allocationTotal),
              customFontSizeClass: 'xsTitle',
              dollarIcon: (
                <img
                  src={OrangeDollarIcon}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginBottom: '-2px',
                    marginRight: '3px',
                    marginLeft: '3px',
                  }}
                />
              ),
            },
          }}
        >
          <DetailAllocationTableContent
            listItem={getAPCreditNote[0]?.Allocation}
          />
        </CardContents>
      </ContentWrapper>
      <ApprovalLogDialog
        data={getAPCreditNote[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Advance'}
      />

      {getAPCreditNote[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}
    </>
  )
}

//import { BankAccountListing } from 'containers/CompanyModule/BankAccount/BankAccountListing'
import apDigitalReportRoutes from 'containers/APModule/APDigitalReport/APDigitalReportRoutes'
import cbDigitalReportRoutes from 'containers/CashBookModule/CBDigitalReportModule/CBDigitalReportRoutes'
import glDigitalReportRoutes from 'containers/GeneralLedgerModule/GLDigitalReportModule/GLDigitalReportRoutes'

const digitalReportRoutes = [
  ...glDigitalReportRoutes,
  ...cbDigitalReportRoutes,
  ...apDigitalReportRoutes,
]

export default digitalReportRoutes

import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import { CopyrightFooter } from 'components/Footer/Copyright'
import {
  useCreateExcelMasterCoaMutation,
  useGetCheckingValidationCoaQuery,
  useGetCoaStructureQuery,
  useGetCompanyNameQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'

export const MasterCOAExcelValidation = (props: any) => {
  const { mode } = props
  let location = useLocation()
  const passedData: any = location?.state
  const excelData = passedData.excelData
  const fileName = passedData.fileTitle
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { accType }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openDialog, setOpenDialog] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    loading: checkingValidationCOALoading,
    error: checkingValidationCOAError,
    data: { getCheckingValidationCOA } = {
      getCheckingValidationCOA: [],
    },
  } = useGetCheckingValidationCoaQuery({
    fetchPolicy: 'network-only',
    variables: {
      MasterCOAInput: passedData.excelData,
    },
    onCompleted: ({ getCheckingValidationCOA }) => {},
  })

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const [
    createExcelMasterCOA,
    {
      loading: createExcelLoading,
      error: createExcelError,
      data: createExcelMasterCOAData,
    },
  ] = useCreateExcelMasterCoaMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      history.push(`/general-ledger/general-setting/master-coa/${accType}`)
    },
  })

  const createMCOExcel = data => {
    createExcelMasterCOA({
      variables: {
        MasterCOAExcelInput: data,
      },
    })
  }

  return (
    <>
      {checkingValidationCOALoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/general-ledger/general-setting/master-coa/${accType}`)
        }
        smTitle={'General Ledger'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Master COA', current: true },
        ]}
      />

      <DynamicSubHeader
        style={{
          marginTop: '-2px',
        }}
        title={'Master COA Excel Upload'}
        rightText={<span className="c-orange">Validation</span>}
      />
      <List className="search-header fixed-search-header with-dynamic">
        <ListItem>
          <ListItemText
            primary={
              <>
                <span className="xsTitle flex-space">{fileName}</span>
              </>
            }
          />
        </ListItem>
      </List>

      <ContentWrapper style={{ marginTop: '75px' }} float footer>
        {/* Problem List */}
        {getCheckingValidationCOA['ErrorData']?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Problem{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {getCheckingValidationCOA['ErrorData']?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {getCheckingValidationCOA['ErrorData']?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div className="xsTitle text-noflow">
                          <div className="text ">
                            Code: {el.Code}{' '}
                            {el.ParentCode
                              ? `| ParentCode: ${el.ParentCode}`
                              : null}
                          </div>
                          <div className="fw-medium">Name: {el.Name}</div>
                          <div className="fw-medium">Level: {el.Level}</div>
                          <div className="fw-medium">
                            Account Type: {el.AccountType}
                          </div>
                          <div className="fw-medium">
                            IsControl: {`${el.IsControl}`} | IsLastNode:{' '}
                            {`${el.IsLastNode}`}
                          </div>
                          <div
                            className="fw-medium text-noflow"
                            style={{ color: 'red' }}
                          >
                            Row No: {el.RowNo}
                          </div>
                          <div
                            className="fw-medium text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            {el.ErrorList?.map((x, index) => {
                              if (index == el.ErrorList?.length - 1)
                                return `${x}`
                              else return `${x}, `
                            })}
                          </div>
                          {/* {el.ErrorList?.map((x, index) => {
                            if (index == el.ErrorList?.length - 1)
                              return (
                                <div
                                  className="fw-medium"
                                  style={{ color: 'red' }}
                                >
                                  {x}
                                </div>
                              )
                            else
                              return (
                                <div
                                  className="fw-medium"
                                  style={{ color: 'red' }}
                                >
                                  {x},{''}
                                </div>
                              )
                          })} */}
                        </div>
                      </>
                    }
                    // secondary={
                    //   <>
                    //     <span className="desc">
                    //       <div className="text ">{el.Code}</div>
                    //       <div className="fw-medium"> {el.ParentCode}</div>
                    //       <div className="fw-medium"> {el.Name}</div>
                    //       <div className="fw-medium"> {el.Level}</div>
                    //       <div className="fw-medium"> {el.AccountType}</div>
                    //       <div className="fw-medium"> {el.IsControl}</div>
                    //       <div className="fw-medium"> {el.IsLastNode}</div>
                    //     </span>
                    //   </>
                    // }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {/* Validation List */}
        {getCheckingValidationCOA['ValidatedData']?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Validated{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {
                                getCheckingValidationCOA['ValidatedData']
                                  ?.length
                              }
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {getCheckingValidationCOA['ValidatedData']?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div className="xsTitle text-noflow">
                          <div className="text ">
                            Code: {el.Code}{' '}
                            {el.ParentCode
                              ? `| ParentCode: ${el.ParentCode}`
                              : null}
                          </div>
                          <div className="fw-medium">Name: {el.Name}</div>
                          <div className="fw-medium">Level: {el.Level}</div>
                          <div className="fw-medium">
                            Account Type: {el.AccountType}
                          </div>
                          <div className="fw-medium">
                            IsControl:{' '}
                            {el?.IsControl === true ? 'true' : 'false'} |
                            IsLastNode:{' '}
                            {el?.IsLastNode === true ? 'true' : 'false'}
                          </div>
                        </div>
                      </>
                    }
                    // secondary={
                    //   <>
                    //     <span className="desc">
                    //       <div className="text ">{el.Code}</div>
                    //       <div className="fw-medium"> {el.ParentCode}</div>
                    //       <div className="fw-medium"> {el.Name}</div>
                    //       <div className="fw-medium"> {el.Level}</div>
                    //       <div className="fw-medium"> {el.AccountType}</div>
                    //       <div className="fw-medium"> {el.IsControl}</div>
                    //       <div className="fw-medium"> {el.IsLastNode}</div>
                    //     </span>
                    //   </>
                    // }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </ContentWrapper>

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/general-ledger/general-setting/master-coa/${accType}/validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  Code: x?.Code === null ? null : `${x?.Code}`,
                  ParentCode:
                    x?.ParentCode === null ? null : `${x?.ParentCode}`,
                  Name: x?.Name === null ? null : `${x?.Name}`,
                  Level: x?.Level === null ? null : Number(x?.Level),
                  AccountType:
                    x?.AccountType === null ? null : `${x?.AccountType}`,
                  IsControl:
                    x?.IsControl === 'true' ||
                    x?.IsControl === 'True' ||
                    x?.IsControl === 'TRUE'
                      ? true
                      : x?.IsControl === 'false' || x?.IsControl === 'FALSE'
                      ? false
                      : x?.IsControl,
                  IsLastNode:
                    x?.IsLastNode === 'true' ||
                    x?.IsLastNode === 'True' ||
                    x?.IsLastNode === 'TRUE'
                      ? true
                      : x?.IsLastNode === 'false' || x?.IsLastNode === 'FALSE'
                      ? false
                      : x?.IsLastNode,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
          resetFileUpload()
          setFileTitle('')
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        onDownloadTemplate={() => {}}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />
      <Footer
        options={
          getCheckingValidationCOA['ErrorData']?.length === 0 &&
          getCheckingValidationCOA['ValidatedData']?.length > 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true)
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    setOpenFileUpload(true)
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Upload Confirmation
                  </span>
                </div>
              </div>
            ),
          },
          body: () => (
            <div>
              <span>{'Are you sure to upload?'}</span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    createMCOExcel(excelData)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}

import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { AttachFile } from '@material-ui/icons'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect } from 'react'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import PrintIcon from '@material-ui/icons/Print'
import { useHistory, useParams } from 'react-router'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const ARCreditNoteDetailContent = (props: DetailProps) => {
  const { listEl, documentListing, listStatus, userList, mode } = props
  let history = useHistory()
  const { CompanyID }: any = useParams()
  return (
    <>
      {mode === 'expansion' && (
        <div className="content-wrap left flex-space">
          <div className="desc" style={{ color: 'grey' }}>
            Revenue / Income Account
          </div>
          <div className="mdDesc">{listEl?.BillItem?.Name}</div>
        </div>
      )}

      {mode === 'expansion' && documentListing?.length > 0 && (
        <div
          className="content-wrap right"
          style={{ width: '10%', textAlign: 'center', alignSelf: 'end' }}
        >
          {documentListing && (
            <>
              <span
                onClick={() =>
                  documentListing?.forEach(doc => {
                    window.open(doc?.fileURL, '_blank')
                  })
                }
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <AttachFile
                  className="mdDesc"
                  style={{
                    fontSize: 12,
                    color: 'orange',
                  }}
                />
              </span>
              <span
                className="mdDesc"
                style={{ color: 'orange', textDecoration: 'underline' }}
              >
                {documentListing?.length}
              </span>
            </>
          )}
        </div>
      )}

      {mode === 'expansion' && (
        <>
          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Cost Center
            </div>
            <div className="mdDesc"> {listEl?.CostCentre?.Name}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Tax
            </div>
            <div className="mdDesc">
              {' '}
              {listEl?.BillItem?.TaxScheme?.Code}/
              {`${listEl?.BillItem?.TaxRate}%`}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Tax Amount
            </div>
            <div className="mdDesc">
              {' '}
              <IconText
                icon={
                  <img
                    src={BlackDollarIcon}
                    style={{
                      width: '12px',
                      marginTop: '-1px',
                    }}
                  />
                }
              >
                <span className="mdDesc">&nbsp; {amtStr(listEl?.TaxAmt)}</span>
              </IconText>
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc">{listEl?.Description}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Remark
            </div>
            <div className="mdDesc">{listEl?.Remark}</div>
          </div>
        </>
      )}

      {mode === 'detail' && (
        <div className="flex-space">
          <div className="desc" style={{ color: 'grey' }}>
            Date
          </div>
          <span
            style={{ float: 'right' }}
            onClick={() => {
              history.push({
                pathname: `/account-receivable/${CompanyID}/credit-note/${listEl?.CreditNoteID}/preview`,
                state: {
                  ...listEl,
                  allocation: listEl?.Allocation,
                  mainCreditOrDebit: 'Debit',
                },
              })
            }}
          >
            <PrintIcon
              style={{
                fontSize: 'medium',
                padding: '0px 10px 2px 0px',
              }}
            />
          </span>
          <div className="mdDesc"> {formatDate(listEl?.DocDate)}</div>
        </div>
      )}

      {mode === 'detail' && (
        <>
          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Debtor Name
            </div>
            <div className="mdDesc"> {listEl?.DebtorAccount?.DebtorName} </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Revenue / Income Account
            </div>
            <div className="mdDesc">
              {' '}
              {/* {listEl?.MasterCOA?.Code} | {listEl?.MasterCOA?.Name}{' '} */}
              {listEl?.BillItem?.Name}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Cost Center
            </div>
            <div className="mdDesc"> {listEl?.CostCentre?.Name} </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Tax
            </div>
            <div className="mdDesc">
              {' '}
              {listEl?.TaxScheme?.Code}/{`${listEl?.TaxRate}.00%`}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Tax Amount
            </div>
            <div className="mdDesc">
              {' '}
              <IconText
                icon={
                  <img
                    src={BlackDollarIcon}
                    style={{
                      width: '12px',
                      marginTop: '-1px',
                    }}
                  />
                }
              >
                <span className="mdDesc">&nbsp; {amtStr(listEl?.TaxAmt)}</span>
              </IconText>
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc">{listEl?.Description}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Remark
            </div>
            <div className="mdDesc">{listEl?.Remark}</div>
          </div>
        </>
      )}

      {mode === 'detail' && (
        <div className="content-wrap full">
          <div className="desc" style={{ color: 'grey' }}>
            Attachment
          </div>
          {attachmentDetailView(documentListing)}
        </div>
      )}

      {/* {mode === 'expansion' && documentListing?.length > 0 && (
        <div
          className="content-wrap right"
          style={{ width: '10%', textAlign: 'center', alignSelf: 'end' }}
        >
          {documentListing && (
            <>
              <span
                onClick={() =>
                  documentListing?.forEach(doc => {
                    window.open(doc?.fileURL, '_blank')
                  })
                }
                style={{
                  position: 'relative',
                  top: '1px',
                }}
              >
                <AttachFile
                  className="mdDesc"
                  style={{
                    fontSize: 12,
                    color: 'blue',
                  }}
                />
              </span>
              <span
                className="mdDesc"
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                {documentListing?.length}
              </span>
            </>
          )}
        </div>
      )} */}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow">
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow">
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow">
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow">
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}

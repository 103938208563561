import doc from 'assets/icons/file.svg'
import pdf from 'assets/icons/pdf.svg'
import video from 'assets/images/video.png'
import React from 'react'

export const attachmentDetailView = (DocList: any, diffClass?: boolean) => {
  // to download file with correct file name
  const downloadFile = async (filename, fileurl) => {
    let response = await fetch(fileurl)
    let data = await response.blob()
    let file = new File([data], filename)
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(file)
    link.download = `${filename}`
    link.click()
  }

  return (
    <>
      <div className="preview-wrapper">
        {DocList?.length > 0 ? (
          DocList?.map(x => (
            <a href={x?.fileURL} target="_blank">
              {x?.mediaType.includes('image') ? (
                <>
                  {/* COMMENT CODE IS THE ORIGINAL CODE */}
                  {/* <div className="xxTitle primary-click-text">
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : (x?.name ?? x?.fileName)}
                  </div> */}
                  <img
                    src={x?.fileURL}
                    className={'img-preview'}
                    loading="lazy"
                  />

                  {/* CHANGES REQUESTED IN JIRA ACX-409/463 */}

                  <div
                    className="desc primary-click-text"
                    style={{ fontWeight: 500 }}
                  >
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : x?.name ?? x?.fileName}
                  </div>
                </>
              ) : x?.mediaType.includes('pdf') ? (
                <>
                  {/* COMMENT CODE IS THE ORIGINAL CODE */}
                  {/* <div className="xxTitle primary-click-text">
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : (x?.name ?? x?.fileName)}
                  </div> */}

                  <img src={pdf} className="img-preview" loading="lazy" />

                  {/* CHANGES REQUESTED IN JIRA ACX-409 */}

                  <div
                    className="desc primary-click-text"
                    style={{ fontWeight: 500 }}
                  >
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : x?.name ?? x?.fileName}
                  </div>
                </>
              ) : x?.mediaType.includes('video') ? (
                <>
                  {/* COMMENT CODE IS THE ORIGINAL CODE */}
                  {/* <div className="xxTitle primary-click-text">
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : (x?.name ?? x?.fileName)}
                  </div> */}
                  <img src={video} className="img-preview" loading="lazy" />

                  {/* CHANGES REQUESTED IN JIRA ACX-409/463 */}

                  <div
                    className="desc primary-click-text"
                    style={{ fontWeight: 500 }}
                  >
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : x?.name ?? x?.fileName}
                  </div>
                </>
              ) : x?.mediaType.includes('audio') ? (
                <>
                  {/* <div className="xxTitle primary-click-text">
                    {(x?.name ?? x?.fileName)}
                  </div> */}
                  <audio controls>
                    <source src={x?.fileURL} type="audio/webm" />
                  </audio>

                  <div
                    className="desc primary-click-text"
                    style={{ fontWeight: 500 }}
                  >
                    {x?.name ?? x?.fileName}
                  </div>
                </>
              ) : (
                <>
                  {/* COMMENT CODE IS THE ORIGINAL CODE */}
                  {/* <div className="xxTitle primary-click-text">
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName)
                          ?.split('.')
                          .shift()
                          .substring(0, 10) + '...'
                      : (x?.name ?? x?.fileName)}
                  </div> */}

                  <img
                    src={doc}
                    className="img-preview"
                    loading="lazy"
                    width="50"
                    height="50"
                  />

                  {/* CHANGES REQUESTED IN JIRA ACX-409/463 */}
                  <div
                    className="desc primary-click-text"
                    style={{ fontWeight: 500 }}
                  >
                    {(x?.name ?? x?.fileName).length > 10
                      ? (x?.name ?? x?.fileName).substring(0, 10) +
                        '.' +
                        (x?.name ?? x?.fileName)?.split('.').pop()
                      : x?.name ?? x?.fileName}
                  </div>
                </>
              )}
            </a>
          ))
        ) : (
          <div className="desc">N/A</div>
        )}
      </div>
    </>
  )
}

import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

export const ARDigitalReportSubmenu = (props: any) => {
  useEffect(() => {
    console.log('ARDigitalReportSubmenu')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { handlePermHidden } = usePermissionChecker()

  // ACCOUNTX API CALLS

  const submenuList = [
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-debtor-ledger-detail-by-doc-date/parameters`,
      title: `Debtor Ledger Detail By Doc Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-statement-of-account/parameters`,
      title: `Statement Of Account`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-debtor-aging-summary-trx-date/parameters`,
      title: `Debtor Aging Summary by Trx Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-debtor-aging-summary-doc-date/parameters`,
      title: `Debtor Aging Summary by Document Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-debtor-aging-detail-trx-date/parameters`,
      title: `Debtor Aging Detail by Trx Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-debtor-control-summary/parameters`,
      title: `Debtor Control Summary`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/account-receivable/${CompanyID}/digital-report/ar-debtor-control-detail/parameters`,
      title: `Debtor Control Detail`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
  ]

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-receivable/${CompanyID}`)}
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            // if (
            //   handlePermHidden({
            //     // companyID: CompanyID,
            //     permEnum: el?.permission,
            //   })
            // )
            return (
              <ListItem key={index} onClick={() => history.push(el?.path)}>
                <ListItemText
                  style={{
                    textAlign: 'right',
                    marginLeft: '5px',
                  }}
                  primary={<span className="xsTitle">{el?.title}</span>}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname: el.path,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
